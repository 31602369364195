// src/components/agent_builder_basic/AgentSummaryPanel.tsx

import React from 'react';
import { Typography, List, ListItem, ListItemText, Chip, Paper } from '@mui/material';
import { AgentData } from '@/types/AgentTypes';

interface AgentSummaryPanelProps {
  agentData: AgentData;
}

const AgentSummaryPanel: React.FC<AgentSummaryPanelProps> = ({ agentData }) => {
  const skills = agentData.skills || [];
  const personality = agentData.personality || {};

  return (
    <Paper elevation={3} sx={{ p: 2, overflowY: 'auto', maxHeight: '100%' }}>
      <Typography variant="h6" gutterBottom>
        Agent Summary
      </Typography>
      <List dense>
        <ListItem>
          <ListItemText primary="Name" secondary={agentData.name || 'Not set'} />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Role"
            secondary={agentData.role || 'Not set'}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Voice"
            secondary={agentData.voiceId || 'Not selected'}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Skills"
            secondary={
              <>
                {skills.length > 0 ? (
                  skills.map((skill, index) => (
                    <Chip
                      key={index}
                      label={skill.name}
                      size="small"
                      style={{ margin: '2px' }}
                    />
                  ))
                ) : (
                  <Chip
                    label="No skills set"
                    size="small"
                    style={{ margin: '2px' }}
                  />
                )}
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Personality Traits"
            secondary={
              <>
                {Object.entries(personality).length > 0 ? (
                  Object.entries(personality).map(
                    ([trait, value], index) => (
                      <Chip
                        key={index}
                        label={`${trait}: ${value}`}
                        size="small"
                        style={{ margin: '2px' }}
                      />
                    )
                  )
                ) : (
                  <Chip
                    label="No traits set"
                    size="small"
                    style={{ margin: '2px' }}
                  />
                )}
              </>
            }
          />
        </ListItem>
      </List>
    </Paper>
  );
};

export default AgentSummaryPanel;