// src/api/admin.ts

import api from './api';
import { handleError } from '../utils/errors';

export interface Log {
  log_type: 'login' | 'activity';
  id: number;
  user_id: number;
  details: string;
  timestamp: string;
}

// Fetch admin logs
export const fetchAdminLogs = async (): Promise<Log[]> => {
  try {
    const response = await api.get<{ data: Log[] }>('/api/admin/logs');
    return response.data.data; // Access the nested data array
  } catch (error) {
    handleError(error, 'Error fetching admin logs');
  }
};
