// src/components/agent_builder_basic/UserPlanDisplay.tsx

import React from 'react';
import { Box, Typography, Button, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

interface UserPlanDisplayProps {
  planName: string;
  planFeatures: string[];
  expiryDate: string;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));

const UserPlanDisplay: React.FC<UserPlanDisplayProps> = ({
  planName,
  planFeatures,
  expiryDate,
}) => {
  const formattedExpiryDate = new Date(expiryDate).toLocaleDateString();

  return (
    <StyledPaper>
      <Typography variant="h6" gutterBottom>
        Your Current Plan: {planName}
      </Typography>
      <Box sx={{ mb: 2 }}>
        {planFeatures.map((feature, index) => (
          <Typography key={index} variant="body2">
            • {feature}
          </Typography>
        ))}
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="body2" gutterBottom>
          Plan Expiry Date: {formattedExpiryDate}
        </Typography>
      </Box>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        sx={{ mt: 1 }}
      >
        Manage Your Plan
      </Button>
    </StyledPaper>
  );
};

export default UserPlanDisplay;