// src/components/agent_builder_basic/VoiceSelectionBasic.tsx

import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
  CircularProgress,
  Alert,
} from '@mui/material';
import { motion } from 'framer-motion';
import WaveSurfer from 'wavesurfer.js';
import { AgentData } from '@/types/AgentTypes';

/**
 * Represents a voice tailored for the VoiceSelectionBasic component.
 */
interface Voice {
  id: string;
  name: string;
  gender: string;
  type?: string;
  description: string;
  sampleUrl?: string;
}

interface VoiceSelectionBasicProps {
  agentData: AgentData;
  onChange: (newData: Partial<AgentData>) => void;
}

const VoiceSelectionBasic: React.FC<VoiceSelectionBasicProps> = ({
  agentData,
  onChange,
}) => {
  const [voices] = useState<Voice[]>([]);
  const [playingVoice, setPlayingVoice] = useState<string | null>(null);
  const [loading] = useState(true);
  const [error] = useState<string | null>(null);
  const waveformRefs = useRef<{ [key: string]: WaveSurfer | null }>({});

  /**
   * Initializes WaveSurfer instances for each voice.
   */
  useEffect(() => {
    voices.forEach((voice) => {
      if (!waveformRefs.current[voice.id] && voice.sampleUrl) {
        const wavesurfer = WaveSurfer.create({
          container: `#waveform-${voice.id}`,
          waveColor: '#3f51b5',
          progressColor: '#1a237e',
          cursorColor: '#303f9f',
          height: 40,
          barWidth: 2,
          barRadius: 3,
          cursorWidth: 1,
          barGap: 3,
          normalize: true,
        });

        wavesurfer.load(voice.sampleUrl);

        // Handle when the audio is finished playing
        wavesurfer.on('finish', () => {
          setPlayingVoice(null);
        });

        waveformRefs.current[voice.id] = wavesurfer;
      }
    });

    return () => {
      // Destroy WaveSurfer instances on unmount
      Object.values(waveformRefs.current).forEach((wavesurfer) => {
        if (wavesurfer) {
          wavesurfer.destroy();
        }
      });
      waveformRefs.current = {};
    };
  }, [voices]);

  /**
   * Handles the selection of a voice.
   */
  const handleVoiceSelect = (voiceId: string) => {
    onChange({ voiceId });
  };

  /**
   * Handles playing and pausing of voice samples.
   */
  const handlePlaySample = (voiceId: string) => {
    const wavesurfer = waveformRefs.current[voiceId];
    if (!wavesurfer) return;

    if (playingVoice && playingVoice !== voiceId) {
      // Pause the currently playing voice
      const currentWavesurfer = waveformRefs.current[playingVoice];
      currentWavesurfer?.pause();
    }

    if (playingVoice === voiceId) {
      // Pause if the same voice is clicked again
      wavesurfer.pause();
      setPlayingVoice(null);
    } else {
      // Play the selected voice
      wavesurfer.play();
      setPlayingVoice(voiceId);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 400,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 400,
        }}
      >
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Typography variant="h5" gutterBottom>
        Voice Selection
      </Typography>
      <Grid container spacing={3}>
        {voices.map((voice) => (
          <Grid item xs={12} sm={6} md={4} key={voice.id}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Card
                variant={
                  agentData.voiceId === voice.id ? 'outlined' : 'elevation'
                }
                onClick={() => handleVoiceSelect(voice.id)}
                sx={{
                  cursor: 'pointer',
                  borderColor:
                    agentData.voiceId === voice.id
                      ? 'primary.main'
                      : 'transparent',
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: 3,
                  },
                }}
              >
                <CardContent>
                  <Typography variant="h6">{voice.name}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {voice.type ? `${voice.type} - ` : ''}
                    {voice.description}
                  </Typography>
                  <Box id={`waveform-${voice.id}`} sx={{ mt: 2 }} />
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent card click
                      handlePlaySample(voice.id);
                    }}
                  >
                    {playingVoice === voice.id ? 'Pause' : 'Play Sample'}
                  </Button>
                  <Button
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent card click
                      handleVoiceSelect(voice.id);
                    }}
                    color={
                      agentData.voiceId === voice.id ? 'primary' : 'inherit'
                    }
                  >
                    {agentData.voiceId === voice.id ? 'Selected' : 'Select Voice'}
                  </Button>
                </CardActions>
                {agentData.voiceId === voice.id && (
                  <Box
                    sx={{
                      p: 1,
                      bgcolor: 'primary.main',
                      color: 'primary.contrastText',
                      textAlign: 'center',
                    }}
                  >
                    <Typography variant="body2">Selected</Typography>
                  </Box>
                )}
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </motion.div>
  );
};

export default VoiceSelectionBasic;