// src/components/agentprofile/AgentBasicInfo.tsx

import React from 'react';
import {
  TextField,
  Button,
  Box,
  Grid,
  Avatar,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
} from '@mui/material';
import { PhotoCamera, Delete as DeleteIcon } from '@mui/icons-material';
import { FormData } from '../../types/AgentTypes';
import MiniSkillBuilder from './MiniSkillBuilder';
import MiniPersonalityBuilder from './MiniPersonalityBuilder';
import defaultAvatar from 'src/assets/images/divergent-agents-logo.png';

interface AgentBasicInfoProps {
  formData: FormData;
  handleTextareaChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleSubmit: (e: React.FormEvent) => void;
  isUpdating: boolean;
  phoneNumbers: string[]; // Retained as we'll use it now
  handleImageUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDeleteAgent: () => void;
  assistantId: string;
  setFormData: React.Dispatch<React.SetStateAction<FormData | null>>;
  handleDeletePhoneNumber?: (number: string) => void; // Optional: Function to handle phone number deletion
}

const AgentBasicInfo: React.FC<AgentBasicInfoProps> = ({
  formData,
  handleTextareaChange,
  handleSubmit,
  isUpdating,
  phoneNumbers,
  handleImageUpload,
  handleDeleteAgent,
  assistantId,
  setFormData,
  handleDeletePhoneNumber, // Optional: Used if deletion functionality is added
}) => {
  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container spacing={3} justifyContent="center">
        {/* Profile Image */}
        <Grid item xs={12} display="flex" justifyContent="center" mb={3}>
          <Box position="relative" display="inline-block">
            <Avatar
              alt={formData.name}
              src={formData.profileImageUrl || defaultAvatar}
              sx={{ width: 128, height: 128 }}
            />
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              sx={{ position: 'absolute', bottom: 0, right: 0 }}
            >
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={handleImageUpload}
              />
              <PhotoCamera />
            </IconButton>
          </Box>
        </Grid>

        {/* Basic Information */}
        <Grid item xs={12} md={8}>
          <TextField
            label="Agent Name"
            name="name"
            value={formData.name}
            onChange={(e) =>
              setFormData((prev) =>
                prev ? { ...prev, name: e.target.value } : null
              )
            }
            fullWidth
            margin="normal"
          />
          <TextField
            label="General Knowledge"
            name="generalKnowledge"
            value={formData.generalKnowledge}
            onChange={handleTextareaChange}
            fullWidth
            multiline
            rows={4}
            margin="normal"
          />
          <TextField
            label="Highlights"
            name="highlights"
            value={formData.highlights}
            onChange={handleTextareaChange}
            fullWidth
            multiline
            rows={2}
            margin="normal"
          />
        </Grid>

        {/* Phone Numbers Section */}
        <Grid item xs={12} md={8}>
          <Typography variant="h6" gutterBottom>
            Phone Numbers
          </Typography>
          {phoneNumbers.length > 0 ? (
            <List>
              {phoneNumbers.map((number, index) => (
                <React.Fragment key={index}>
                  <ListItem>
                    <ListItemText primary={number} />
                    {handleDeletePhoneNumber && (
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleDeletePhoneNumber(number)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          ) : (
            <Typography variant="body2" color="textSecondary">
              No phone numbers available.
            </Typography>
          )}
        </Grid>

        {/* Mini Skill Builder */}
        <Grid item xs={12} md={8}>
          <MiniSkillBuilder
            assistantId={assistantId}
            skills={formData.skills}
            onChange={(skills) =>
              setFormData((prev) =>
                prev ? { ...prev, skill: skills } : null
              )
            }
          />
        </Grid>

        {/* Mini Personality Builder */}
        <Grid item xs={12} md={8}>
          <MiniPersonalityBuilder
            personality={formData.personality}
            onChange={(personality: { [key: string]: number }) =>
              setFormData((prev) =>
                prev ? { ...prev, personality } : null
              )
            }
          />
        </Grid>

        {/* Actions */}
        <Grid
          item
          xs={12}
          md={8}
          display="flex"
          justifyContent="center"
          mt={2}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isUpdating}
            sx={{ mr: 2 }}
          >
            Update Agent
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleDeleteAgent}
          >
            Delete Agent
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AgentBasicInfo;
