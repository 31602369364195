import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Button, TextareaAutosize, Paper, Tabs } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';

/**
 * Styled container component with custom margins, background gradient, border radius, box shadow, and padding.
 */
export const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  background: `linear-gradient(45deg, ${theme.palette.background.paper} 30%, ${theme.palette.background.default} 90%)`,
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.1)',
  padding: theme.spacing(3),
}));

/**
 * Animated button component with smooth transform and background-color transitions on hover.
 */
export const AnimatedButton = styled(Button)(() => ({
  transition: 'transform 0.2s ease-in-out, background-color 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
}));

/**
 * Upload button component with a primary gradient background, transition effects, and hover scaling with box shadow.
 */
export const UploadButton = styled(Button)<ButtonProps & { component?: React.ElementType }>(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
  color: theme.palette.primary.contrastText,
  transition: 'transform 0.2s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: `0 6px 12px ${theme.palette.primary.main}`,
  },
}));

/**
 * Styled textarea component with full width, padding, border styling, font settings, and focus state enhancements.
 */
export const StyledTextArea = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  padding: '16.5px 14px',
  borderRadius: '4px',
  borderColor: 'rgba(0, 0, 0, 0.23)',
  fontFamily: theme.typography.fontFamily,
  fontSize: '1rem',
  transition: 'border-color 0.3s ease-in-out',
  '&:focus': {
    borderColor: theme.palette.primary.main,
    outline: 'none',
  },
}));

/**
 * Styled paper component with enhanced padding, increased border radius, box shadow, transition effects, and hover state changes.
 */
export const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
  },
}));

/**
 * Styled tabs component with custom margin and enhanced styling for individual tabs.
 */
export const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .MuiTab-root': {
    minWidth: 120,
    fontWeight: theme.typography.fontWeightMedium,
  },
}));