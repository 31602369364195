import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  Slider,
  Grid,
  Paper,
  Tooltip,
  IconButton,
  Box,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Radar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js';
import { motion } from 'framer-motion';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  ChartTooltip,
  Legend
);

// Define the structure for personality traits
interface PersonalityTrait {
  name: string;
  description: string;
  example: string;
  initialValue: number;
  minValue: number;
  maxValue: number;
}

// Define the props for the MiniPersonalityBuilder component
interface MiniPersonalityBuilderProps {
  personality: { [key: string]: number };
  onChange: (personality: { [key: string]: number }) => void;
}

// Define a subset of personality traits from the main PersonalityBuilder
const personalityTraits: PersonalityTrait[] = [
  {
    name: 'Friendliness',
    description: 'Warmth and kindness towards others',
    example: 'Approaches others with a smile and engages in friendly conversations.',
    initialValue: 70,
    minValue: 50,
    maxValue: 90,
  },
  {
    name: 'Professionalism',
    description: 'Maintaining a professional demeanor and work ethic',
    example: 'Consistently meets deadlines and maintains high-quality work standards.',
    initialValue: 80,
    minValue: 60,
    maxValue: 100,
  },
  {
    name: 'Enthusiasm',
    description: 'Showing excitement and eagerness',
    example: 'Displays genuine interest and excitement in projects and tasks.',
    initialValue: 75,
    minValue: 55,
    maxValue: 95,
  },
  {
    name: 'Patience',
    description: 'Ability to remain calm under pressure',
    example: 'Handles challenging situations without frustration.',
    initialValue: 65,
    minValue: 45,
    maxValue: 85,
  },
  {
    name: 'Assertiveness',
    description: 'Confidently expressing opinions and needs',
    example: 'Communicates ideas clearly and stands up for personal beliefs.',
    initialValue: 70,
    minValue: 50,
    maxValue: 90,
  },
  {
    name: 'Adaptability',
    description: 'Ability to adjust to new conditions',
    example: 'Quickly adapts to changes in the work environment.',
    initialValue: 85,
    minValue: 65,
    maxValue: 95,
  },
  {
    name: 'Empathy',
    description: 'Understanding and sharing the feelings of others',
    example: 'Recognizes and responds to the emotional needs of colleagues.',
    initialValue: 80,
    minValue: 60,
    maxValue: 100,
  },
];

const MiniPersonalityBuilder: React.FC<MiniPersonalityBuilderProps> = ({
  personality,
  onChange,
}) => {
  // Initialize trait values
  const [traits, setTraits] = useState<{ [key: string]: number }>(() => {
    const initialTraits: { [key: string]: number } = {};
    personalityTraits.forEach((trait) => {
      initialTraits[trait.name] = personality[trait.name] ?? trait.initialValue;
    });
    return initialTraits;
  });

  // Update parent component when traits change
  const updatePersonality = useCallback(() => {
    onChange(traits);
  }, [traits, onChange]);

  useEffect(() => {
    updatePersonality();
  }, [updatePersonality]);

  // Handle slider changes
  const handleTraitChange = (traitName: string, value: number) => {
    setTraits((prevTraits) => ({
      ...prevTraits,
      [traitName]: value,
    }));
  };

  // Prepare chart data
  const chartData = {
    labels: personalityTraits.map((trait) => trait.name),
    datasets: [
      {
        label: 'Personality Traits',
        data: personalityTraits.map((trait) => traits[trait.name]),
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 2,
        pointBackgroundColor: 'rgba(255, 99, 132, 1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(255, 99, 132, 1)',
      },
    ],
  };

  const chartOptions = {
    scales: {
      r: {
        angleLines: {
          display: false,
        },
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: {
          stepSize: 25,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5, ease: 'easeOut' }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          borderRadius: 3,
          background: 'linear-gradient(135deg, #f0f4f8 0%, #d9e2ec 100%)',
        }}
      >
        <Typography variant="h5" gutterBottom align="center">
          Mini Personality Builder
        </Typography>
        <Grid container spacing={4}>
          {/* Traits Sliders */}
          <Grid item xs={12} md={6}>
            {personalityTraits.map((trait, index) => (
              <motion.div
                key={trait.name}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.05 }}
              >
                <Box display="flex" alignItems="center" mb={2}>
                  <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                    {trait.name}
                  </Typography>
                  <Tooltip
                    title={
                      <Box>
                        <Typography variant="subtitle2" color="inherit">
                          {trait.description}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          Example: {trait.example}
                        </Typography>
                      </Box>
                    }
                    arrow
                    placement="top"
                  >
                    <IconButton size="small">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Slider
                  value={traits[trait.name]}
                  onChange={(_, value) =>
                    handleTraitChange(trait.name, value as number)
                  }
                  aria-labelledby={`slider-${index}`}
                  valueLabelDisplay="auto"
                  min={trait.minValue}
                  max={trait.maxValue}
                  step={1}
                  marks={[
                    { value: trait.minValue, label: trait.minValue.toString() },
                    { value: trait.maxValue, label: trait.maxValue.toString() },
                  ]}
                  sx={{
                    color: '#1976d2',
                    '& .MuiSlider-thumb': {
                      width: 24,
                      height: 24,
                      backgroundColor: '#fff',
                      border: '2px solid currentColor',
                      '&:hover': {
                        boxShadow: '0 0 0 8px rgba(25, 118, 210, 0.16)',
                      },
                    },
                  }}
                />
              </motion.div>
            ))}
          </Grid>
          {/* Radar Chart */}
          <Grid item xs={12} md={6}>
            <Paper
              elevation={2}
              sx={{
                padding: 2,
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'rgba(255, 255, 255, 0.8)',
                borderRadius: 2,
              }}
            >
              <Radar data={chartData} options={chartOptions} />
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </motion.div>
  );
};

export default MiniPersonalityBuilder;