// src/utils/jwtUtils.ts

/**
 * Represents the payload of a JWT token.
 */
export interface JwtPayload {
  userId: string;
  exp: number;
  // Add other relevant fields based on your JWT structure
}

/**
 * Utility function to get access token from localStorage.
 * @returns The JWT access token as a string or null if not found.
 */
export const getToken = (): string | null => {
  return localStorage.getItem('token');
};

/**
 * Utility function to set access token in localStorage.
 * @param token - The JWT access token to set.
 */
export const setToken = (token: string): void => {
  localStorage.setItem('token', token);
};

/**
 * Utility function to remove access token from localStorage.
 */
export const removeToken = (): void => {
  localStorage.removeItem('token');
};

/**
 * Utility function to get refresh token from localStorage.
 * @returns The JWT refresh token as a string or null if not found.
 */
export const getRefreshToken = (): string | null => {
  return localStorage.getItem('refreshToken');
};

/**
 * Utility function to set refresh token in localStorage.
 * @param refreshToken - The JWT refresh token to set.
 */
export const setRefreshToken = (refreshToken: string): void => {
  localStorage.setItem('refreshToken', refreshToken);
};

/**
 * Utility function to remove refresh token from localStorage.
 */
export const removeRefreshToken = (): void => {
  localStorage.removeItem('refreshToken');
};

/**
 * Utility function to clear both access and refresh tokens from localStorage.
 */
export const clearTokens = (): void => {
  removeToken();
  removeRefreshToken();
};

/**
 * Decodes a JWT token and extracts the payload.
 * @param token - The JWT token to decode.
 * @returns The decoded payload or null if decoding fails.
 */
export const decodeJWT = (token: string): JwtPayload | null => {
  try {
    const base64Url = token.split('.')[1];
    if (!base64Url) {
      throw new Error('Invalid JWT token');
    }
    const base64 = atob(base64Url.replace(/-/g, '+').replace(/_/g, '/'));
    const decoded: JwtPayload = JSON.parse(base64);
    return decoded;
  } catch (error) {
    console.error('Failed to decode JWT:', error);
    return null;
  }
};

/**
 * Extracts the user ID from the JWT token.
 * @returns The user ID as a string or null if not found.
 */
export const getUserIdFromToken = (): string | null => {
  const token = getToken();
  if (!token) {
    console.error('No token found');
    return null;
  }
  const decoded = decodeJWT(token);
  return decoded?.userId || null;
};

/**
 * Checks if the current token is expired.
 * @returns True if the token is expired or invalid, false otherwise.
 */
export const isTokenExpired = (): boolean => {
  const token = getToken();
  if (!token) {
    return true;
  }
  const decoded = decodeJWT(token);
  if (!decoded || !decoded.exp) {
    return true;
  }
  const currentTime = Math.floor(Date.now() / 1000);
  return decoded.exp < currentTime;
};