// src/config.ts
const config = {
    DB_URL: import.meta.env.VITE_DB_URL,
    MAKE_ENDPOINT: import.meta.env.VITE_MAKE_ENDPOINT,
    ELEVENLABS_API_KEY: import.meta.env.VITE_ELEVENLABS_API_KEY,
    ELEVENLABS_API_URL: 'https://api.elevenlabs.io/v1/voices',
    SECRET_KEY: import.meta.env.VITE_SECRET_KEY,
    VAPI_URL: import.meta.env.VITE_VAPI_URL,
    API_KEY: import.meta.env.VITE_VAPI_API_KEY,
    EMAIL_SUMMARY_WEBHOOK_URL: import.meta.env.VITE_EMAIL_SUMMARY_WEBHOOK_URL,
    API_BASE_URL: import.meta.env.VITE_API_BASE_URL,
    ADVANCED_AGENT_WEBHOOK_URL: import.meta.env.VITE_ADVANCED_AGENT_WEBHOOK_URL,
    XTIME_SERVER_SECRET: import.meta.env.VITE_XTIME_SERVER_SECRET,
  };
  
  export default config;
  