import React from 'react';
import { Grid, Card, CardContent, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

interface Voice {
  voice_name: string;
  voice_id: string;
  description: React.ReactNode;
}

interface AgentProfileVoicesProps {
  voices: Voice[];
  selectedVoice: string;
  onVoiceSelect: (voiceId: string) => void;
}

const AgentProfileVoices: React.FC<AgentProfileVoicesProps> = ({ voices, selectedVoice, onVoiceSelect }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth margin="normal" variant="outlined">
          <InputLabel>Voice</InputLabel>
          <Select
            name="voice"
            value={selectedVoice}
            onChange={(e) => onVoiceSelect(e.target.value)}
            label="Voice"
          >
            {voices.map((voice) => (
              <MenuItem key={voice.voice_id} value={voice.voice_id}>
                {voice.voice_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {voices.map((voice) => (
        <Grid item xs={12} sm={6} md={4} key={voice.voice_id}>
          <Card
            variant={selectedVoice === voice.voice_id ? 'outlined' : 'elevation'}
            onClick={() => onVoiceSelect(voice.voice_id)}
            sx={{
              cursor: 'pointer',
              borderColor: selectedVoice === voice.voice_id ? 'primary.main' : 'transparent',
              '&:hover': {
                boxShadow: 6,
              },
            }}
          >
            <CardContent>
              <Typography variant="h6">{voice.voice_name}</Typography>
              <Typography variant="body2">{voice.description}</Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default AgentProfileVoices;
