import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Typography,
  CircularProgress,
  Paper,
} from '@mui/material';
import {
  fetchUserPlanDetails,
  fetchUserAssistants,
} from '@/api/user';
import { getRecentActivity } from '@/api/metrics'; // Add this import
import { getAgentMetrics, fetchAndStoreMetrics } from '@/api/billing';
import UserPlanDisplay from './UserPlanDisplay';
import RecentActivity from './RecentActivity';
import MetricsDisplay from './MetricsDisplay';
import {
  ActivityLog,
  AgentMetrics,
  Assistant,
  UserPlanDetails,
} from '@/types/AgentTypes';

interface UserPlanDisplayProps {
  planName: string;
  planFeatures: string[];
  agentsCreated: number;
  agentLimit: number;
}

const OverviewPage: React.FC = () => {
  const [userPlan, setUserPlan] = useState<UserPlanDisplayProps | null>(null);
  const [recentActivity, setRecentActivity] = useState<ActivityLog[]>([]);
  const [agentMetrics, setAgentMetrics] = useState<AgentMetrics | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadOverviewData = async () => {
      try {
        const planDetails: UserPlanDetails = await fetchUserPlanDetails();

        const mappedUserPlan: UserPlanDisplayProps = {
          planName: planDetails.planName,
          planFeatures: planDetails.features,
          agentsCreated: 0, // TODO: Replace with actual data
          agentLimit: 10,   // TODO: Replace with actual data
        };

        setUserPlan(mappedUserPlan);

        const assistants: Assistant[] = await fetchUserAssistants();
        const assistantId = assistants.length > 0 ? assistants[0].assistantId : null;

        if (assistantId) {
          await fetchAndStoreMetrics(assistantId);
          const [activity, metrics] = await Promise.all([
            getRecentActivity(assistantId),
            getAgentMetrics(assistantId),
          ]);

          setRecentActivity(activity);
          setAgentMetrics(metrics);
        } else {
          setRecentActivity([]);
          setAgentMetrics(null);
        }
      } catch (error) {
        console.error('Failed to load overview data:', error);
        setError('Failed to load overview data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    loadOverviewData();
  }, []);

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4, textAlign: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4, textAlign: 'center' }}>
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        Dashboard Overview
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          {userPlan ? (
            <UserPlanDisplay {...userPlan} />
          ) : (
            <Typography variant="body1">
              No plan details available.
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              padding: 3,
              borderRadius: 2,
              boxShadow: 3,
              height: '100%',
            }}
          >
            <Typography variant="h6" gutterBottom>
              Recent Activity
            </Typography>
            {recentActivity.length > 0 ? (
              <RecentActivity activities={recentActivity} />
            ) : (
              <Typography variant="body1">
                No recent activity available.
              </Typography>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper
            sx={{
              padding: 3,
              borderRadius: 2,
              boxShadow: 3,
            }}
          >
            {agentMetrics ? (
              <MetricsDisplay metrics={agentMetrics} />
            ) : (
              <Typography variant="body1">No metrics available.</Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default OverviewPage;