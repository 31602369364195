// src/api/callLogs.ts

import api from './api';
import { handleError } from '../utils/errors';
import {
  Assistant,
  CallLog,
  CallSummary,
  SalesCallAnalysis,
  SalesCallAnalysisDetails, // Import the correct type
} from '@/types/AgentTypes';

/**
 * Fetches all call logs or logs for a specific assistant.
 * @param assistantId - (Optional) The ID of the assistant to filter call logs.
 * @returns A promise that resolves to an array of CallLog objects.
 */
export const fetchCallLogs = async (assistantId?: string): Promise<CallLog[]> => {
  try {
    const response = await api.get<CallLog[]>('/api/call-logs', {
      params: assistantId ? { assistant_id: assistantId } : {},
    });
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch call logs');
    return []; // Ensures the function always returns a CallLog array
  }
};

/**
 * Fetches a single call log by its ID.
 * @param callId - The ID of the call log to fetch.
 * @returns A promise that resolves to a CallLog object.
 */
export const fetchCallLogById = async (callId: string): Promise<CallLog> => {
  try {
    const response = await api.get<CallLog>(`/api/call-logs/${callId}`);
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch call log');
    throw error; // Propagates the error after handling
  }
};

/**
 * Fetches the summary of a specific call.
 * @param assistantId - The ID of the assistant associated with the call.
 * @param callId - The ID of the call to summarize.
 * @returns A promise that resolves to a CallSummary object.
 */
export const fetchCallSummary = async (
  assistantId: string,
  callId: string
): Promise<CallSummary> => {
  try {
    const response = await api.get<CallSummary>(
      `/api/call-logs/summary/${assistantId}/${callId}`
    );
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch call summary');
    throw error;
  }
};

export const fetchAnalysisForCall = async (
  callId: string
): Promise<SalesCallAnalysisDetails> => {
  try {
    const response = await api.get<SalesCallAnalysisDetails>(
      `/api/sales-analysis/${callId}`
    );
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch analysis for call');
    throw error;
  }
};

export const triggerSalesAnalysis = async (data: {
  call_id: string;
  assistant_id: string;
  summary: string;
  transcript: string;
}): Promise<SalesCallAnalysisDetails | null> => {
  if (!data.summary && !data.transcript) {
    console.log('No summary or transcript available for analysis.');
    return null;
  }

  try {
    const response = await api.post<SalesCallAnalysisDetails>(
      '/api/sales-analysis/trigger',
      data
    );
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to trigger sales call analysis');
    throw error;
  }
};

/**
 * Fetches all sales call analyses for a specific assistant.
 * @param assistantId - The ID of the assistant whose analyses are to be fetched.
 * @returns A promise that resolves to an array of SalesCallAnalysis objects.
 */
export const fetchSalesCallAnalysis = async (
  assistantId: string
): Promise<SalesCallAnalysis[]> => {
  try {
    const response = await api.get<SalesCallAnalysis[]>('/api/sales-analysis', {
      params: { assistant_id: assistantId },
    });
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch sales call analyses');
    throw error;
  }
};

/**
 * Fetches all assistants available for sales analysis.
 * @returns A promise that resolves to an array of Assistant objects.
 */
export const fetchAssistantsForSalesAnalysis = async (): Promise<Assistant[]> => {
  try {
    const response = await api.get<Assistant[]>(
      '/api/sales-analysis/assistants'
    );
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch assistants for sales analysis');
    throw error;
  }
};
