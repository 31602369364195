import React, { useState, useEffect } from 'react';
import {
  Typography,
  Paper,
  Chip,
  TextField,
  Box,
  Autocomplete,
  CircularProgress,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableProvided,
} from 'react-beautiful-dnd';
import { AgentData, Skill } from '@/types/AgentTypes';
import { getSkillRecommendations } from '@/services/aiRecommendationService';
import { motion, AnimatePresence } from 'framer-motion';

interface SkillBuilderProps {
  agentData: AgentData;
  onChange: (newData: Partial<AgentData>) => void;
}

const skillCategories = [
  'Call Handling',
  'Product Knowledge',
  'Objection Handling',
  'Empathy',
  'Problem Solving',
  'Technical Support',
  'Sales Techniques',
  'Customer Service',
  'Industry Knowledge',
  'Custom',
];

const MAX_SKILLS = 6;

const SkillBuilder: React.FC<SkillBuilderProps> = ({ agentData, onChange }) => {
  const [skills, setSkills] = useState<Skill[]>(agentData.skills || []);
  const [newSkillName, setNewSkillName] = useState<string>('');
  const [recommendations, setRecommendations] = useState<Skill[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastFetchedRole, setLastFetchedRole] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    setSkills(agentData.skills || []);
  }, [agentData.skills]);

  useEffect(() => {
    if (agentData.role && agentData.role !== lastFetchedRole) {
      const fetchRecommendations = async () => {
        setIsLoading(true);
        try {
          const recs = await getSkillRecommendations(
            agentData.role || '',
            agentData.dealershipName || ''
          );
          const mappedRecs: Skill[] = recs.map((rec, index) => ({
            id: `rec-${index}`,
            name: rec.skill,
            category: rec.category,
            scenario: rec.scenario,
          }));
          setRecommendations(mappedRecs);
          setLastFetchedRole(agentData.role || '');
        } catch (error) {
          console.error('Error fetching recommendations:', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchRecommendations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentData.role]);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedSkills = Array.from(skills);
    const [movedSkill] = reorderedSkills.splice(result.source.index, 1);
    reorderedSkills.splice(result.destination.index, 0, movedSkill);

    setSkills(reorderedSkills);
    onChange({ skills: reorderedSkills });
  };

  const handleAddSkill = (skillToAdd: Skill) => {
    if (skills.length >= MAX_SKILLS) {
      setError(`You can only add up to ${MAX_SKILLS} skills.`);
      return;
    }
    if (skills.find((skill) => skill.name === skillToAdd.name)) {
      setError('This skill is already added.');
      return;
    }
    const updatedSkills = [...skills, { ...skillToAdd, id: `skill-${Date.now()}` }];
    setSkills(updatedSkills);
    onChange({ skills: updatedSkills });
    setNewSkillName('');
    setError('');
  };

  const handleDeleteSkill = (skillId: string) => {
    const updatedSkills = skills.filter((skill) => skill.id !== skillId);
    setSkills(updatedSkills);
    onChange({ skills: updatedSkills });
  };

  const filteredSkills = selectedCategory
    ? skills.filter((skill) => skill.category === selectedCategory)
    : skills;

  const availableOptions = [
    ...recommendations,
    ...skills.filter((skill) => !recommendations.find((rec) => rec.name === skill.name)),
  ];

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
      <Typography variant="h5" gutterBottom>
        Skill Builder
      </Typography>
      <Box sx={{ mb: 2 }}>
        <Autocomplete
          value={null}
          onChange={(_, value) => {
            if (typeof value === 'string' && value !== '') {
              const newSkill: Skill = {
                id: `skill-${Date.now()}`,
                name: value,
                category: 'Custom',
                scenario: '',
              };
              handleAddSkill(newSkill);
            } else if (value && 'name' in value) {
              handleAddSkill(value as Skill);
            }
          }}
          inputValue={newSkillName}
          onInputChange={(_, value) => setNewSkillName(value)}
          options={availableOptions}
          getOptionLabel={(option) => option.name}
          filterOptions={(options, params) => {
            const filtered = options.filter((option) =>
              option.name.toLowerCase().includes(params.inputValue.toLowerCase())
            );
            if (
              params.inputValue !== '' &&
              !filtered.some((option) => option.name === params.inputValue)
            ) {
              filtered.push({
                id: `new-${Date.now()}`,
                name: params.inputValue,
                category: 'Custom',
                scenario: '',
              });
            }
            return filtered;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="Add a new skill"
              variant="outlined"
              error={!!error}
              helperText={error}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body1">{option.name}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {option.category}
                </Typography>
              </Box>
            </li>
          )}
        />
      </Box>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Filter by Category</InputLabel>
        <Select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value as string)}
          label="Filter by Category"
        >
          <MenuItem value="">All Categories</MenuItem>
          {skillCategories.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="skills">
          {(provided) => (
            <Paper
              {...provided.droppableProps}
              ref={provided.innerRef}
              elevation={3}
              sx={{ p: 2, minHeight: 100 }}
            >
              <AnimatePresence initial={false}>
                {filteredSkills.map((skill, index) => (
                  <Draggable key={skill.id} draggableId={skill.id} index={index}>
                    {(provided: DraggableProvided) => {
                      // Extract and clean the draggable and dragHandle props
                      const draggableProps = {
                        ...provided.draggableProps,
                        onTransitionEnd: undefined,
                      };
                      const dragHandleProps = provided.dragHandleProps || {};

                      return (
                        <motion.div
                          ref={provided.innerRef}
                          {...draggableProps}
                          {...dragHandleProps}
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -20 }}
                          transition={{ duration: 0.3 }}
                        >
                          <Tooltip
                            title={skill.scenario || 'No scenario available'}
                            arrow
                            placement="top"
                          >
                            <Chip
                              label={
                                <Box display="flex" alignItems="center">
                                  <Typography variant="body2">{skill.name}</Typography>
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    sx={{ ml: 1 }}
                                  >
                                    ({skill.category})
                                  </Typography>
                                </Box>
                              }
                              onDelete={() => handleDeleteSkill(skill.id)}
                              sx={{ m: 0.5 }}
                            />
                          </Tooltip>
                        </motion.div>
                      );
                    }}
                  </Draggable>
                ))}
              </AnimatePresence>
              {provided.placeholder}
            </Paper>
          )}
        </Droppable>
      </DragDropContext>
    </motion.div>
  );
};

export default SkillBuilder;