// src/components/agent_builder_basic/ReviewBasic.tsx

import React from 'react';
import {
  Typography,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
} from '@mui/material';
import { motion } from 'framer-motion';
import { AgentData, Skill } from '@/types/AgentTypes';
import AgentBuildingAnimation from './AgentBuildingAnimation';

interface ReviewBasicProps {
  agentData: AgentData;
  onCreateAgent: () => Promise<void>;
  isBuilding: boolean;
}

const ReviewBasic: React.FC<ReviewBasicProps> = ({
  agentData,
  onCreateAgent,
  isBuilding,
}) => {
  // Display a loading animation if the agent is being created
  if (isBuilding) {
    return (
      <AgentBuildingAnimation
        agentName={agentData.name || 'Agent'}
        voiceSampleUrl={agentData.voiceSampleUrl || ''}
      />
    );
  }

  // Use the skills array from agentData
  const parsedSkills: Skill[] = agentData.skills || [];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Typography variant="h5" gutterBottom>
        Review Your Agent
      </Typography>
      <Grid container spacing={3}>
        {/* Basic Information Summary */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Basic Information
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="Name" secondary={agentData.name} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Role" secondary={agentData.role} />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="General Knowledge"
                  secondary={agentData.generalKnowledge}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Highlight" secondary={agentData.highlight} />
              </ListItem>
            </List>
          </Paper>
        </Grid>
        {/* Voice and Skills Summary */}
        <Grid item xs={12} md={6}>
          {/* Voice Information */}
          <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Voice
            </Typography>
            <Typography>
              Selected Voice ID: {agentData.voiceId || agentData.voiceId || 'Not Selected'}
            </Typography>
          </Paper>
          {/* Skills Information */}
          <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Skills
            </Typography>
            <List>
              {parsedSkills.length > 0 ? (
                parsedSkills.map((skill: Skill, index: number) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={skill.name}
                      secondary={skill.category}
                    />
                  </ListItem>
                ))
              ) : (
                <ListItem>
                  <ListItemText primary="No skills set" />
                </ListItem>
              )}
            </List>
          </Paper>
        </Grid>
      </Grid>
      {/* Create Agent Button */}
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={onCreateAgent}
          disabled={isBuilding}
        >
          Create Agent
        </Button>
      </Box>
    </motion.div>
  );
};

export default ReviewBasic;