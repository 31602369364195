// src/utils/agentDataAdapter.ts

import { AgentTemplate, AgentData, Skill } from '@/types/AgentTypes';
import { BackendAgentTemplate, BackendAgentData, BackendSkill } from '@/types/BackendTypes';

/**
 * Adapts a backend agent template to the frontend AgentTemplate interface.
 * @param backendTemplate The backend agent template.
 * @returns The adapted AgentTemplate.
 */
export function adaptAgentTemplate(backendTemplate: BackendAgentTemplate): AgentTemplate {
  return {
    assistantId: backendTemplate.id, // Mapped 'assistantId' to 'id' from BackendAgentTemplate
    id: backendTemplate.id,
    name: backendTemplate.name || '',
    role: backendTemplate.role || '',
    skills: adaptSkill(backendTemplate.skill), // Changed from 'skill' to 'skills'
    generalKnowledge: backendTemplate.general_knowledge || '',
    knowledgeBase: '', // Set to default as it's not available in BackendAgentTemplate
    personality: adaptPersonality(backendTemplate.personality),
    highlight: backendTemplate.highlight || '',
    voiceId: '', // Set to default as it's not available in BackendAgentTemplate
    gender: '', // Set to default as it's not available in BackendAgentTemplate
    organizationId: backendTemplate.organization_id || null,
    profileImageUrl: backendTemplate.profile_image_url || '',
  };
}

/**
 * Adapts backend agent data to the frontend AgentData interface.
 * @param backendData The backend agent data.
 * @returns The adapted AgentData.
 */
export function adaptAgentData(backendData: BackendAgentData): AgentData {
  return {
    id: backendData.id,
    knowledgeBase: backendData.knowledge_base || '',
    skills: adaptSkill(backendData.skill),
    personality: adaptPersonality(backendData.personality),
    name: backendData.name || '',
    role: backendData.role || '',
    highlight: backendData.highlight || '',
    voiceId: backendData.voice_id || '',
    gender: backendData.gender || '',
    // Map additional fields as necessary
    // Example:
    // dealershipName: backendData.dealership_name || '',
  };
}

/**
 * Adapts backend skill data to the frontend Skill interface.
 * @param skill The backend skill data.
 * @returns The adapted Skill array.
 */
function adaptSkill(skill: BackendSkill[] | string): Skill[] {
  if (Array.isArray(skill)) {
    return skill.map((s: BackendSkill) => ({
      id: s.id,
      name: s.name,
      category: s.category,
      scenario: s.scenario || '',
    }));
  }
  if (typeof skill === 'string') {
    return [{
      id: generateSkillId(skill),
      name: skill,
      category: 'General',
      scenario: '',
    }];
  }
  return [];
}

/**
 * Generates a unique ID for a skill based on its name.
 * @param skillName The name of the skill.
 * @returns A unique ID string.
 */
function generateSkillId(skillName: string): string {
  return 'skill-' + skillName.toLowerCase().replace(/\s+/g, '-');
}

/**
 * Adapts backend personality data to the frontend personality format.
 * @param personality The backend personality data.
 * @returns The adapted personality object.
 */
function adaptPersonality(personality: Record<string, number> | string): { [key: string]: number } {
  if (typeof personality === 'object' && personality !== null) {
    return personality as Record<string, number>;
  }
  if (typeof personality === 'string') {
    // Assuming the string is in the format "Trait1:Value1, Trait2:Value2"
    return Object.fromEntries(
      personality.split(',').map(trait => {
        const [key, value] = trait.trim().split(':').map(s => s.trim());
        return [key, isNaN(Number(value)) ? 0 : Number(value)];
      })
    );
  }
  return {};
}