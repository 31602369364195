// src/api/file.ts

import api from './api';
import { handleError } from '../utils/errors';
import { FileDetails } from '../types/AgentTypes';
import { toCamelCase } from '../utils/caseConverter';

/**
 * Uploads a file to the server.
 * @param file - The file to be uploaded.
 * @param assistantId - The ID of the assistant (optional).
 * @returns A promise that resolves to the uploaded file's details.
 */
export const uploadFile = async (file: File, assistantId?: string): Promise<FileDetails> => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    if (assistantId) {
      formData.append('assistantId', assistantId);
    }

    const response = await api.post<FileDetails>('/api/files/upload', formData);
    return toCamelCase(response.data) as FileDetails;
  } catch (error) {
    handleError(error, 'Error uploading file');
    throw error; // Re-throw the error after handling
  }
};

/**
 * Fetches all file IDs associated with a specific assistant.
 * @param assistantId - The ID of the assistant.
 * @returns A promise that resolves to an array of file IDs.
 */
export const fetchFileIds = async (assistantId: string): Promise<string[]> => {
  try {
    const response = await api.get<{ fileId: string }[]>(
      `/api/files/assistants/${assistantId}`
    );
    const data = toCamelCase(response.data) as { fileId: string }[];
    return data.map((file) => file.fileId);
  } catch (error) {
    handleError(error, 'Failed to fetch file IDs');
    throw error;
  }
};

/**
 * Fetches detailed information about a specific file.
 * @param fileId - The ID of the file.
 * @returns A promise that resolves to the file's details.
 */
export const fetchFileDetails = async (fileId: string): Promise<FileDetails> => {
  try {
    const response = await api.get<FileDetails>(`/api/files/${fileId}`);
    return toCamelCase(response.data) as FileDetails;
  } catch (error) {
    handleError(error, 'Failed to fetch file details');
    throw error;
  }
};

/**
 * Deletes a specific file.
 * @param fileId - The ID of the file to delete.
 * @returns A promise that resolves when the file is deleted.
 */
export const deleteFile = async (fileId: string): Promise<void> => {
  try {
    await api.delete(`/api/files/${fileId}`);
  } catch (error) {
    handleError(error, 'Failed to delete file');
    throw error;
  }
};

/**
 * Fetches all files associated with the current user.
 * @returns A promise that resolves to an array of file details.
 */
export const fetchUserFiles = async (): Promise<FileDetails[]> => {
  try {
    const response = await api.get<FileDetails[]>('/api/files');
    return toCamelCase(response.data) as FileDetails[];
  } catch (error) {
    handleError(error, 'Error fetching user files');
    throw error;
  }
};
