// src/components/BillingSummary.tsx

import React, { useEffect, useState } from 'react';
import {
  fetchUserMetrics,
  updateUserMetrics,
} from '@/api/billing';
import { UserMetrics } from '@/types/AgentTypes';
// Removed unused imports: DBVoice and FileDetails
import { useTheme } from './ThemeContext';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';

const BillingSummary: React.FC = () => {
  const { isDarkMode } = useTheme();
  const [metrics, setMetrics] = useState<UserMetrics[] | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        await updateUserMetrics();
        const data = await fetchUserMetrics('all');
        setMetrics(Array.isArray(data) ? data : [data]);
      } catch (error) {
        console.error(error);
        toast.error('Failed to fetch billing summary');
      } finally {
        setLoading(false);
      }
    };

    fetchMetrics();
  }, []);

  const renderMetricCard = (title: string, value: number, unit: string) => (
    <div
      className={`rounded-lg shadow p-6 text-center ${
        isDarkMode ? 'bg-gray-800' : 'bg-white'
      }`}
    >
      <h3
        className={`text-lg font-semibold mb-2 ${
          isDarkMode ? 'text-gray-200' : 'text-gray-700'
        }`}
      >
        {title}
      </h3>
      <p
        className={`text-3xl font-bold ${
          isDarkMode ? 'text-blue-400' : 'text-blue-600'
        }`}
      >
        {value.toFixed(2)}{' '}
        <span
          className={`text-sm ${
            isDarkMode ? 'text-gray-400' : 'text-gray-500'
          }`}
        >
          {unit}
        </span>
      </p>
    </div>
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <CircularProgress />
      </div>
    );
  }

  if (!metrics || metrics.length === 0) {
    return (
      <div
        className={`shadow-lg rounded-lg p-6 text-center ${
          isDarkMode ? 'bg-gray-800' : 'bg-white'
        }`}
      >
        <h2
          className={`text-2xl font-bold mb-2 ${
            isDarkMode ? 'text-gray-200' : 'text-gray-700'
          }`}
        >
          No Billing Summary Available
        </h2>
        <p
          className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}
        >
          Usage data will appear here once activity is recorded.
        </p>
      </div>
    );
  }

  const totalDuration = metrics.reduce(
    (sum, metric) => sum + metric.totalDuration,
    0
  );
  const totalCalls = metrics.reduce(
    (sum, metric) => sum + metric.callCount,
    0
  );
  const totalCost = metrics.reduce(
    (sum, metric) => sum + metric.totalCost,
    0
  );

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {renderMetricCard(
        'Monthly Total Minutes',
        totalDuration / 60,
        'minutes'
      )}
      {renderMetricCard('Monthly Call Count', totalCalls, 'calls')}
      {renderMetricCard('Monthly Total Cost', totalCost, 'USD')}
    </div>
  );
};

export default BillingSummary;
