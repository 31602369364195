// AgentProfileCharts.tsx
import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from 'recharts';
import { UserMetrics } from '@/types/AgentTypes';

// MonthlyMinutesChart component
interface MonthlyMinutesChartProps {
  usageData: {
    totalMinutes: number;
  };
}

export const MonthlyMinutesChart: React.FC<MonthlyMinutesChartProps> = ({ usageData }) => {
  const data = [
    { name: 'Used Minutes', value: usageData.totalMinutes },
    { name: 'Remaining', value: Math.max(0, 1000 - usageData.totalMinutes) }, // Assuming a 1000 minute limit
  ];

  const COLORS = ['#0088FE', '#FFBB28'];

  return (
    <Card sx={{ width: '100%', marginBottom: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Monthly Minutes Usage
        </Typography>
        <Box height={300} display="flex" flexDirection="column" justifyContent="center">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
          <Typography variant="body1" align="center">
            {usageData.totalMinutes} / 1000 minutes used
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

// WeeklyCallChart component
interface WeeklyCallChartProps {
  callData: {
    callCount: number;
    successRate: number;
  };
}

export const WeeklyCallChart: React.FC<WeeklyCallChartProps> = ({ callData }) => {
  const successfulCalls = Math.round(callData.callCount * callData.successRate);
  const failedCalls = callData.callCount - successfulCalls;

  const data = [
    { name: 'Successful Calls', value: successfulCalls },
    { name: 'Failed Calls', value: failedCalls },
  ];

  const COLORS = ['#00C49F', '#FF8042'];

  return (
    <Card sx={{ width: '100%', marginBottom: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Weekly Call Statistics
        </Typography>
        <Box height={300} display="flex" flexDirection="column" justifyContent="center">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
          <Typography variant="body1" align="center">
            Total Calls: {callData.callCount} | Success Rate: {(callData.successRate * 100).toFixed(1)}%
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

// BillingChart component
interface BillingChartProps {
  billingData: {
    totalCost: number;
    averageCallCost: number;
  };
}

export const BillingChart: React.FC<BillingChartProps> = ({ billingData }) => {
  return (
    <Card sx={{ width: '100%', marginBottom: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Billing Overview
        </Typography>
        <Box mt={2}>
          <Typography variant="body1">
            Total Cost: ${billingData.totalCost.toFixed(2)}
          </Typography>
          <Typography variant="body1">
            Average Call Cost: ${billingData.averageCallCost.toFixed(2)}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

// Main ChartsSection component
interface ChartsSectionProps {
  metrics: UserMetrics;
}

export const ChartsSection: React.FC<ChartsSectionProps> = ({ metrics }) => {
  const totalMinutes = metrics.totalDuration / 60; // Convert seconds to minutes
  const averageCallCost = metrics.callCount > 0 ? metrics.totalCost / metrics.callCount : 0;

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 3 }}>
      <Box sx={{ flexBasis: '100%', flexGrow: 1, maxWidth: 'calc(33.333% - 16px)' }}>
        <BillingChart
          billingData={{
            totalCost: metrics.totalCost,
            averageCallCost: averageCallCost,
          }}
        />
      </Box>
      <Box sx={{ flexBasis: '100%', flexGrow: 1, maxWidth: 'calc(33.333% - 16px)' }}>
        <WeeklyCallChart
          callData={{
            callCount: metrics.callCount,
            successRate: metrics.successRate,
          }}
        />
      </Box>
      <Box sx={{ flexBasis: '100%', flexGrow: 1, maxWidth: 'calc(33.333% - 16px)' }}>
        <MonthlyMinutesChart
          usageData={{
            totalMinutes: totalMinutes,
          }}
        />
      </Box>
    </Box>
  );
};