// src/components/account/ProfileContext.tsx

import React, { createContext, useState, useContext, useEffect } from 'react';
import { fetchUserProfile } from '@/api/user';
import { useAuth } from '../AuthContext';
import { UserProfile } from '@/types/AgentTypes'; // Ensure this import exists

interface ProfileContextType {
  profileImage: string;
  setProfileImage: (image: string) => void;
  refreshProfileImage: () => Promise<void>;
}

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

export const ProfileProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [profileImage, setProfileImage] = useState<string>('');
  const { user } = useAuth();

  const refreshProfileImage = async () => {
    try {
      const userProfile: UserProfile = await fetchUserProfile();
      if (userProfile.profilePicture) {
        // Construct the full URL if necessary
        const apiBaseUrl = import.meta.env.VITE_API_BASE_URL || 'http://localhost:5000';
        const profileImageUrl = userProfile.profilePicture.startsWith('http')
          ? userProfile.profilePicture
          : `${apiBaseUrl}${userProfile.profilePicture}`;

        setProfileImage(profileImageUrl);
      } else {
        setProfileImage(''); 
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error('Failed to fetch profile image:', error.message);
      } else {
        console.error('Failed to fetch profile image:', error);
      }
      setProfileImage('');
    }
  };

  useEffect(() => {
    if (user) {
      refreshProfileImage();
    }
  }, [user]);

  return (
    <ProfileContext.Provider value={{ profileImage, setProfileImage, refreshProfileImage }}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (context === undefined) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }
  return context;
};