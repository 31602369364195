// src/components/SalesCallAnalysis.tsx

import React, { useState, useEffect, useCallback } from 'react';
import {
  fetchSalesCallAnalysis,
  triggerSalesAnalysis,
  fetchAssistantsForSalesAnalysis,
  fetchAnalysisForCall,
} from '@/api/callLogs';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  TextField,
  IconButton,
  Button,
  useMediaQuery,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  Tooltip,
  CircularProgress,
  Chip,
  Grid,
} from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import AnalyzeIcon from '@mui/icons-material/Analytics';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { SalesCallAnalysisData, Assistant, SalesCallAnalysisDetails } from '@/types/AgentTypes';

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

const StyledTableContainer = styled(TableContainer)(() => ({
  maxHeight: 'calc(100vh - 300px)',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '0.4em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,.1)',
    outline: '1px solid slategrey',
    borderRadius: '4px',
  },
}));

const SalesCallAnalysis: React.FC = () => {
  const [analysisData, setAnalysisData] = useState<SalesCallAnalysisData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [selectedCall, setSelectedCall] = useState<SalesCallAnalysisData | null>(null);
  const [isAnalysisDialogOpen, setIsAnalysisDialogOpen] = useState(false);
  const [analysisLoading, setAnalysisLoading] = useState(false);
  const [assistants, setAssistants] = useState<Assistant[]>([]);
  const [selectedAssistant, setSelectedAssistant] = useState<string | null>(null);

  useEffect(() => {
    const fetchAssistants = async () => {
      try {
        const data = await fetchAssistantsForSalesAnalysis();
        setAssistants(data);
        if (data.length > 0) {
          setSelectedAssistant(data[0].assistantId);
        }
      } catch (error) {
        console.error('Error fetching assistants:', error);
        setError('Failed to fetch assistants. Please try again later.');
      }
    };
    fetchAssistants();
  }, []);

  const fetchData = useCallback(async () => {
    if (!selectedAssistant) return;
    try {
      setLoading(true);
      const data = await fetchSalesCallAnalysis(selectedAssistant);
      setAnalysisData(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching sales call analysis:', error);
      setError('Failed to fetch sales call analysis data. Please try again later.');
      setLoading(false);
    }
  }, [selectedAssistant]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const filteredData = analysisData.filter((row) =>
    row.callId.includes(debouncedSearchTerm) ||
    (row.summary && row.summary.toLowerCase().includes(debouncedSearchTerm.toLowerCase()))
  );

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const handleAnalyzeCall = async (call: SalesCallAnalysisData) => {
    setSelectedCall(call);
    setIsAnalysisDialogOpen(true);
    setAnalysisLoading(true);
    try {
      let analysisResult: SalesCallAnalysisDetails | null = null;
      if (call.hasAnalysis) {
        analysisResult = await fetchAnalysisForCall(call.callId);
      } else {
        analysisResult = await triggerSalesAnalysis({
          call_id: call.callId,
          assistant_id: selectedAssistant!,
          summary: call.summary || '',
          transcript: '',
        });
      }

      if (analysisResult) {
        setAnalysisData((prevData) =>
          prevData.map((item) =>
            item.callId === call.callId
              ? { ...item, analysis: analysisResult, has_analysis: true }
              : item
          )
        );

        setSelectedCall((prev) =>
          prev ? { ...prev, analysis: analysisResult, has_analysis: true } : null
        );
      } else {
        throw new Error('Analysis result is null');
      }

      await fetchData();
    } catch (error) {
      console.error('Error analyzing call:', error);
      setError('Failed to analyze call. Please try again later.');
    } finally {
      setAnalysisLoading(false);
    }
  };

  const renderAnalysisDialog = () => {
    if (!selectedCall) return null;
    const analysisData = selectedCall.analysis || null;

    return (
      <Dialog open={isAnalysisDialogOpen} onClose={() => setIsAnalysisDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Call Analysis</DialogTitle>
        <DialogContent>
          {analysisLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height={200}>
              <CircularProgress />
            </Box>
          ) : analysisData ? (
            <Box>
              <Typography variant="h6" gutterBottom>Call Overview</Typography>
              <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={12} sm={6}>
                  <Paper elevation={2} sx={{ p: 2 }}>
                    <Typography variant="subtitle1">Customer Satisfaction Score</Typography>
                    <Typography variant="h4">{analysisData.customerSatisfactionScore}/10</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper elevation={2} sx={{ p: 2 }}>
                    <Typography variant="subtitle1">Lead Quality</Typography>
                    <Typography variant="h4">{analysisData.leadQuality}</Typography>
                  </Paper>
                </Grid>
              </Grid>

              {analysisData.keyTopicsDiscussed && (
                <Box sx={{ mb: 3 }}>
                  <Typography variant="h6" gutterBottom>Key Topics Discussed</Typography>
                  <Box display="flex" flexWrap="wrap" gap={1}>
                    {analysisData.keyTopicsDiscussed.map((topic: string, index: number) => (
                      <Chip key={index} label={topic} color="primary" variant="outlined" />
                    ))}
                  </Box>
                </Box>
              )}

              {analysisData.sentimentAnalysis && (
                <Box sx={{ mb: 3 }}>
                  <Typography variant="h6" gutterBottom>Sentiment Analysis</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Paper elevation={2} sx={{ p: 2, textAlign: 'center' }}>
                        <Typography variant="subtitle2">Positive</Typography>
                        <Typography variant="h5">{analysisData.sentimentAnalysis.positive}%</Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={4}>
                      <Paper elevation={2} sx={{ p: 2, textAlign: 'center' }}>
                        <Typography variant="subtitle2">Negative</Typography>
                        <Typography variant="h5">{analysisData.sentimentAnalysis.negative}%</Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={4}>
                      <Paper elevation={2} sx={{ p: 2, textAlign: 'center' }}>
                        <Typography variant="subtitle2">Neutral</Typography>
                        <Typography variant="h5">{analysisData.sentimentAnalysis.neutral}%</Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Box>
              )}

              {analysisData.actionItems && (
                <Box sx={{ mb: 3 }}>
                  <Typography variant="h6" gutterBottom>Action Items</Typography>
                  <ul>
                    {analysisData.actionItems.map((item: string, index: number) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </Box>
              )}

              {analysisData.areasForImprovement && analysisData.areasForImprovement.length > 0 && (
                <Box>
                  <Typography variant="h6" gutterBottom>Areas for Improvement</Typography>
                  <ul>
                    {analysisData.areasForImprovement.map((item: string, index: number) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </Box>
              )}
            </Box>
          ) : (
            <Typography>No analysis data available.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAnalysisDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  };

  if (loading) {
    return (
      <Box sx={{ padding: theme.spacing(3) }}>
        <Skeleton variant="rectangular" width="100%" height={56} sx={{ marginBottom: theme.spacing(2) }} />
        <Skeleton variant="rectangular" width="100%" height={400} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
          <Paper elevation={3} sx={{ p: 3, textAlign: 'center' }}>
            <ErrorOutlineIcon color="error" sx={{ fontSize: 48, mb: 2 }} />
            <Typography color="error" variant="h6">{error}</Typography>
          </Paper>
        </motion.div>
      </Box>
    );
  }

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
      <Box sx={{ padding: theme.spacing(3) }}>
        <Typography variant="h4" gutterBottom component={motion.h1} layoutId="title">
          Sales Call Analysis
        </Typography>
        <Paper elevation={3} sx={{ padding: theme.spacing(3), marginBottom: theme.spacing(3) }}>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: theme.spacing(2) }}>
            <Select
              value={selectedAssistant || ''}
              onChange={(e) => setSelectedAssistant(e.target.value as string)}
              sx={{ marginRight: theme.spacing(2), minWidth: 200 }}
              aria-label="Select Assistant"
            >
              {assistants.map((assistant) => (
                <MenuItem key={assistant.assistantId} value={assistant.assistantId}>
                  {assistant.name}
                </MenuItem>
              ))}
            </Select>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search by Call ID or Summary"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon color="action" sx={{ marginRight: 1 }} />,
                endAdornment: searchTerm && (
                  <IconButton size="small" onClick={handleClearSearch} aria-label="Clear search">
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />
          </Box>
          <StyledTableContainer>
            <Table stickyHeader aria-label="Sales call analysis table">
              <TableHead>
                <TableRow>
                  <TableCell>Call ID</TableCell>
                  {!isMobile && <TableCell>Phone Number</TableCell>}
                  <TableCell>Summary</TableCell>
                  {!isMobile && <TableCell>Created At</TableCell>}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <AnimatePresence>
                  {filteredData.map((row) => (
                    <motion.tr
                      key={row.callId}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      <TableCell>{row.callId}</TableCell>
                      {!isMobile && <TableCell>{row.callerPhoneNumber}</TableCell>}
                      <TableCell>{row.summary || 'No summary available'}</TableCell>
                      {!isMobile && <TableCell>{new Date(row.createdAt).toLocaleString()}</TableCell>}
                      <TableCell>
                        <Tooltip title={row.hasAnalysis ? 'View Analysis' : 'Analyze Call'}>
                          <IconButton onClick={() => handleAnalyzeCall(row)} color={row.hasAnalysis ? 'primary' : 'secondary'}>
                            <AnalyzeIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </motion.tr>
                  ))}
                </AnimatePresence>
              </TableBody>
            </Table>
          </StyledTableContainer>
          {filteredData.length === 0 && (
            <Typography sx={{ marginTop: theme.spacing(2) }}>No results found.</Typography>
          )}
        </Paper>
      </Box>
      {renderAnalysisDialog()}
    </motion.div>
  );
};

export default SalesCallAnalysis;
