// src/api/user.ts

import api from './api';
import { handleError } from '../utils/errors';
import { 
  UserProfile,
  Assistant,
  PhoneNumber,
  UserPlanDetails,
} from '@/types/AgentTypes';
import config from '../config';

/**
 * Response type for uploading profile picture.
 */
interface UploadProfilePictureResponse {
  message: string;
  filePath: string;
}

/**
 * Response type for password-related operations.
 */
interface PasswordResponse {
  success: boolean;
  message: string;
}

/**
 * Initiate forgot password response type.
 */
interface ForgotPasswordResponse {
  success: boolean;
  message: string;
}

/**
 * Fetch current user's information.
 */
export const fetchCurrentUser = async (): Promise<UserProfile> => {
  try {
    const response = await api.get<UserProfile>('/api/users/me');
    const userData = response.data;
    if (userData.profilePicture) {
      const dbUrl = config.DB_URL;
      userData.profilePicture = `${dbUrl}${userData.profilePicture}`;
    }
    return userData;
  } catch (error) {
    handleError(error, 'Error fetching current user');
    throw error; // Ensure the function always returns a UserProfile or throws
  }
};

/**
 * Fetch user profile.
 */
export const fetchUserProfile = async (): Promise<UserProfile> => {
  try {
    const response = await api.get<UserProfile>('/api/users/profile');
    return response.data;
  } catch (error) {
    handleError(error, 'Error fetching user profile');
    throw error;
  }
};

/**
 * Update user profile.
 */
export const updateUserProfile = async (
  profileData: Partial<UserProfile>
): Promise<UserProfile> => {
  try {
    const response = await api.put<UserProfile>('/api/users/profile', profileData);
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to update user profile');
    throw error;
  }
};

/**
 * Upload profile picture.
 */
export const uploadProfilePicture = async (
  formData: FormData
): Promise<UploadProfilePictureResponse> => {
  try {
    const response = await api.post<UploadProfilePictureResponse>(
      '/api/users/profile-picture',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data; // { message, filePath }
  } catch (error) {
    handleError(error, 'Error uploading profile picture');
    throw error;
  }
};

/**
 * Change user password.
 */
export const changeUserPassword = async (
  passwordData: { oldPassword: string; newPassword: string }
): Promise<PasswordResponse> => {
  try {
    const response = await api.post<PasswordResponse>(
      '/api/users/change-password',
      passwordData
    );
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to change password');
    throw error;
  }
};

/**
 * Reset user password.
 */
export const resetUserPassword = async (
  resetData: { token: string; newPassword: string }
): Promise<PasswordResponse> => {
  try {
    const response = await api.post<PasswordResponse>(
      '/api/auth/reset-password',
      resetData
    );
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to reset password');
    throw error;
  }
};

/**
 * Initiate forgot password process.
 */
export const forgotPassword = async (email: string): Promise<ForgotPasswordResponse> => {
  try {
    const response = await api.post<ForgotPasswordResponse>(
      '/api/auth/forgot-password',
      { email }
    );
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to initiate password reset');
    throw error;
  }
};

/**
 * Fetch user plan details.
 */
export const fetchUserPlanDetails = async (): Promise<UserPlanDetails> => {
  try {
    const response = await api.get<UserPlanDetails>('/api/users/me/plan-details');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch user plan details');
    throw error;
  }
};

/**
 * Fetch user plan ID.
 */
export const fetchUserPlanId = async (): Promise<string> => {
  try {
    const response = await api.get<{ planId: string }>('/api/users/plan-id');
    return response.data.planId;
  } catch (error) {
    handleError(error, 'Failed to fetch user plan ID');
    throw error;
  }
};

/**
 * Fetch user organization ID.
 */
export const fetchUserOrganizationId = async (): Promise<string> => {
  try {
    const response = await api.get<{ organizationId: string }>('/api/users/organization');
    const { organizationId } = response.data;
    if (!organizationId) {
      throw new Error('Organization ID not found in response');
    }
    return organizationId;
  } catch (error) {
    handleError(error, 'Failed to fetch user organization ID');
    throw error;
  }
};

/**
 * Fetch user assistants.
 */
export const fetchUserAssistants = async (): Promise<Assistant[]> => {
  try {
    const response = await api.get<Assistant[]>('/api/users/me/assistants');
    return response.data.map((assistant) => ({
      ...assistant,
      phoneNumbers: assistant.phoneNumbers || [],
      profileImageUrl: assistant.profileImageUrl || null,
    }));
  } catch (error) {
    handleError(error, 'Error fetching user assistants');
    throw error;
  }
};

/**
 * Fetch user phone numbers.
 */
export const fetchUserPhoneNumbers = async (): Promise<PhoneNumber[]> => {
  try {
    const response = await api.get<PhoneNumber[]>('/api/phone-numbers/me');
    return response.data;
  } catch (error) {
    handleError(error, 'Error fetching user phone numbers');
    throw error;
  }
};

/**
 * Get user phone numbers based on user ID.
 */
export const getUserPhoneNumbers = async (userId: string): Promise<PhoneNumber[]> => {
  if (!userId) {
    throw new Error('User ID is required');
  }

  try {
    const response = await api.get<Assistant[]>(`/api/users/${userId}/assistants`);
    const assistants: Assistant[] = response.data;

    const phoneNumbers: PhoneNumber[] = assistants.flatMap((assistant) => {
      const numbers = Array.isArray(assistant.phoneNumbers)
        ? assistant.phoneNumbers
        : [assistant.phoneNumbers];

      return numbers.map((number: string) => ({
        id: assistant.id,
        number,
        userId: parseInt(userId, 10),
        assistantId: assistant.assistantId,
        status: 'active',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        vapiId: '',
        name: `${assistant.name}'s number`,
        areaCode: number.substring(2, 5),
        monthlyCost: 0,
      }));
    });

    return phoneNumbers;
  } catch (error) {
    handleError(error, 'Failed to fetch user phone numbers');
    throw error;
  }
};

/**
 * Fetch user by ID.
 */
export const fetchUserById = async (userId: string): Promise<UserProfile> => {
  try {
    const response = await api.get<UserProfile>(`/api/users/${userId}`);
    return response.data;
  } catch (error) {
    handleError(error, 'Error fetching user by ID');
    throw error;
  }
};

/**
 * Fetch assistants by user ID.
 */
export const fetchUserAssistantsByUserId = async (userId: string): Promise<Assistant[]> => {
  try {
    const response = await api.get<Assistant[]>(`/api/users/${userId}/assistants`);
    return response.data;
  } catch (error) {
    handleError(error, 'Error fetching assistants by user ID');
    throw error;
  }
};