// src/utils/errors.ts

export class ApiError extends Error {
  constructor(message: string, public originalError?: unknown) { // Changed 'any' to 'unknown'
    super(message);
    this.name = 'ApiError';
  }
}

/**
 * Handles errors by logging and throwing an ApiError.
 * @param error - The caught error.
 * @param customMessage - An optional custom message to provide context.
 * @throws ApiError with a descriptive message.
 */
export function handleError(error: unknown, customMessage?: string): never {
  let errorMessage = 'An error occurred';

  if (typeof error === 'object' && error !== null) {
    const errorObj = error as { response?: { data?: { message?: string } }; message?: string };
    errorMessage =
      customMessage ||
      errorObj.response?.data?.message ||
      errorObj.message ||
      'An error occurred';
  } else if (typeof error === 'string') {
    errorMessage = customMessage || error;
  }

  console.error(errorMessage);
  throw new ApiError(errorMessage, error);
}
