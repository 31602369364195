import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import { useProfile } from './ProfileContext';
import { 
  fetchUserProfile, 
  updateUserProfile, 
  uploadProfilePicture, 
  changeUserPassword,
  fetchUserPlanDetails,
  fetchUserPhoneNumbers
} from '@/api/user';
import { 
  getTwoFactorStatus, 
  setup2FA, 
  disable2FA,
  checkGoogleConnectionStatus,
  checkMicrosoftConnectionStatus,
  connectGoogleAccount,
  connectMicrosoftAccount
} from '@/api/auth';
import { UserProfile, UserPlanDetails, PhoneNumber } from '@/types/AgentTypes';
import { Tab } from '@headlessui/react';
import { CreditCard, User, Lock, Phone, Bell } from 'lucide-react';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const Settings: React.FC = () => {
  // Removed 'user' from destructuring as it is not used
  const { logout } = useAuth();
  const { profileImage, refreshProfileImage } = useProfile();
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [planDetails, setPlanDetails] = useState<UserPlanDetails | null>(null);
  const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumber[]>([]);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
  const [googleConnected, setGoogleConnected] = useState(false);
  const [microsoftConnected, setMicrosoftConnected] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userProfile = await fetchUserProfile();
        setProfile(userProfile);

        const userPlanDetails = await fetchUserPlanDetails();
        setPlanDetails(userPlanDetails);

        const userPhoneNumbers = await fetchUserPhoneNumbers();
        setPhoneNumbers(userPhoneNumbers);

        const twoFactorStatus = await getTwoFactorStatus();
        setTwoFactorEnabled(twoFactorStatus.twoFactorEnabled);

        const googleStatus = await checkGoogleConnectionStatus();
        const microsoftStatus = await checkMicrosoftConnectionStatus();
        setGoogleConnected(googleStatus);
        setMicrosoftConnected(microsoftStatus);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();
  }, []);


  const handleProfileUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    if (profile) {
      try {
        await updateUserProfile(profile);
        setSuccessMessage('Profile updated successfully');
      } catch (error) {
        console.error('Error updating profile:', error);
      }
    }
  };

  const handlePasswordChange = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setPasswordError('Passwords do not match');
      return;
    }
    try {
      await changeUserPassword({ oldPassword, newPassword });
      setSuccessMessage('Password changed successfully');
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      console.error('Error changing password:', error);
      setPasswordError('Failed to change password');
    }
  };

  const handleProfilePictureUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append('profilePicture', file);
      try {
        await uploadProfilePicture(formData);
        await refreshProfileImage();
        setSuccessMessage('Profile picture updated successfully');
      } catch (error) {
        console.error('Error uploading profile picture:', error);
      }
    }
  };


  const handleToggle2FA = async () => {
    try {
      if (twoFactorEnabled) {
        await disable2FA();
        setTwoFactorEnabled(false);
      } else {
        const response = await setup2FA();
        // Handle the setup process (e.g., show QR code)
        console.log(response);
        setTwoFactorEnabled(true);
      }
      setSuccessMessage(`Two-factor authentication ${twoFactorEnabled ? 'disabled' : 'enabled'} successfully`);
    } catch (error) {
      console.error('Error toggling 2FA:', error);
    }
  };

  const handleConnectGoogle = async () => {
    try {
      // Implement Google OAuth flow
      const credential = await new Promise<string>((resolve) => {
        // Simulating Google OAuth flow
        setTimeout(() => resolve('google-credential'), 1000);
      });
      await connectGoogleAccount(credential);
      setGoogleConnected(true);
      setSuccessMessage('Google account connected successfully');
    } catch (error) {
      console.error('Error connecting Google account:', error);
    }
  };


  const handleConnectMicrosoft = async () => {
    try {
      // Implement Microsoft OAuth flow
      const accessToken = await new Promise<string>((resolve) => {
        // Simulating Microsoft OAuth flow
        setTimeout(() => resolve('microsoft-access-token'), 1000);
      });
      await connectMicrosoftAccount(accessToken);
      setMicrosoftConnected(true);
      setSuccessMessage('Microsoft account connected successfully');
    } catch (error) {
      console.error('Error connecting Microsoft account:', error);
    }
  };

  const renderProfileTab = () => (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium leading-6 text-gray-900">Profile</h3>
        <p className="mt-1 text-sm text-gray-500">
          This information will be displayed publicly so be careful what you share.
        </p>
      </div>

      <form onSubmit={handleProfileUpdate}>
        <div className="space-y-6">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              value={profile?.name || ''}
              onChange={(e) => setProfile(prev => prev ? {...prev, name: e.target.value} : null)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={profile?.email || ''}
              onChange={(e) => setProfile(prev => prev ? {...prev, email: e.target.value} : null)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Photo</label>
            <div className="mt-1 flex items-center">
              <span className="inline-block h-12 w-12 overflow-hidden rounded-full bg-gray-100">
                {profileImage ? (
                  <img src={profileImage} alt="Profile" className="h-full w-full object-cover" />
                ) : (
                  <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                )}
              </span>
              <input
                type="file"
                onChange={handleProfilePictureUpload}
                accept="image/*"
                className="ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              />
            </div>
          </div>
        </div>

        <div className="mt-6">
          <button
            type="submit"
            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );

  const renderAccountTab = () => (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium leading-6 text-gray-900">Account</h3>
        <p className="mt-1 text-sm text-gray-500">
          Manage your account settings and set e-mail preferences.
        </p>
      </div>

      <div className="space-y-6">
      <div>
      <h4 className="text-sm font-medium text-gray-900">Plan Details</h4>
      {planDetails ? (
        <div className="mt-2 text-sm text-gray-500">
          <p>Plan Name: {planDetails.planName}</p>
          <p>Expiry Date: {new Date(planDetails.expiryDate).toLocaleDateString()}</p>
          {planDetails.features && planDetails.features.length > 0 ? ( // Added null check
            <ul className="list-disc pl-5 mt-2">
              {planDetails.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          ) : (
            <p>No features available</p>
          )}
        </div>
      ) : (
        <p className="mt-2 text-sm text-gray-500">Loading plan details...</p>
      )}
    </div>

        <div>
          <h4 className="text-sm font-medium text-gray-900">Connected Accounts</h4>
          <div className="mt-2 space-y-2">
            <button
              onClick={handleConnectGoogle}
              className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${
                googleConnected ? 'bg-green-600 hover:bg-green-700' : 'bg-red-600 hover:bg-red-700'
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500`}
            >
              {googleConnected ? 'Google Connected' : 'Connect Google'}
            </button>
            <button
              onClick={handleConnectMicrosoft}
              className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${
                microsoftConnected ? 'bg-green-600 hover:bg-green-700' : 'bg-blue-600 hover:bg-blue-700'
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
            >
              {microsoftConnected ? 'Microsoft Connected' : 'Connect Microsoft'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  const renderSecurityTab = () => (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium leading-6 text-gray-900">Security</h3>
        <p className="mt-1 text-sm text-gray-500">
          Ensure your account is using a long, random password to stay secure.
        </p>
      </div>

      <form onSubmit={handlePasswordChange}>
        <div className="space-y-6">
          <div>
            <label htmlFor="current-password" className="block text-sm font-medium text-gray-700">
              Current Password
            </label>
            <input
              type="password"
              name="current-password"
              id="current-password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>

          <div>
            <label htmlFor="new-password" className="block text-sm font-medium text-gray-700">
              New Password
            </label>
            <input
              type="password"
              name="new-password"
              id="new-password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>

          <div>
            <label htmlFor="confirm-password" className="block text-sm font-medium text-gray-700">
              Confirm New Password
            </label>
            <input
              type="password"
              name="confirm-password"
              id="confirm-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>

          {passwordError && (
            <p className="mt-2 text-sm text-red-600" id="password-error">
              {passwordError}
            </p>
          )}
        </div>

        <div className="mt-6">
          <button
            type="submit"
            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Change Password
          </button>
        </div>
      </form>

      <div className="mt-6">
        <h4 className="text-sm font-medium text-gray-900">Two-Factor Authentication</h4>
        <button
          onClick={handleToggle2FA}
          className={`mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${
            twoFactorEnabled ? 'bg-red-600 hover:bg-red-700' : 'bg-green-600 hover:bg-green-700'
          } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500`}
        >
          {twoFactorEnabled ? 'Disable 2FA' : 'Enable 2FA'}
        </button>
      </div>
    </div>
  );

  const renderPhoneNumbersTab = () => (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium leading-6 text-gray-900">Phone Numbers</h3>
        <p className="mt-1 text-sm text-gray-500">
          Manage your associated phone numbers.
        </p>
      </div>

      <div className="space-y-4">
        {phoneNumbers.map((phoneNumber) => (
          <div key={phoneNumber.id} className="flex justify-between items-center">
            <span>{phoneNumber.number}</span>
            <span className="text-sm text-gray-500">{phoneNumber.status}</span>
          </div>
        ))}
      </div>

      {/* Add functionality to add/remove phone numbers if needed */}
    </div>
  );

  const renderNotificationsTab = () => (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium leading-6 text-gray-900">Notifications</h3>
        <p className="mt-1 text-sm text-gray-500">
          Manage your notification preferences.
        </p>
      </div>

      {/* Add notification settings here */}
    </div>
  );

  if (!profile) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
          <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-white shadow'
                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                )
              }
            >
              <User className="w-5 h-5 mx-auto" />
              <span>Profile</span>
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-white shadow'
                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                )
              }
            >
              <CreditCard className="w-5 h-5 mx-auto" />
              <span>Account</span>
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-white shadow'
                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                )
              }
            >
              <Lock className="w-5 h-5 mx-auto" />
              <span>Security</span>
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-white shadow'
                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                )
              }
            >
              <Phone className="w-5 h-5 mx-auto" />
              <span>Phone Numbers</span>
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-white shadow'
                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                )
              }
            >
              <Bell className="w-5 h-5 mx-auto" />
              <span>Notifications</span>
            </Tab>
          </Tab.List>
          <Tab.Panels className="mt-2">
            <Tab.Panel>{renderProfileTab()}</Tab.Panel>
            <Tab.Panel>{renderAccountTab()}</Tab.Panel>
            <Tab.Panel>{renderSecurityTab()}</Tab.Panel>
            <Tab.Panel>{renderPhoneNumbersTab()}</Tab.Panel>
            <Tab.Panel>{renderNotificationsTab()}</Tab.Panel>
          </Tab.Panels>
        </Tab.Group>

        {successMessage && (
          <div className="mt-4 p-4 bg-green-100 rounded-md">
            <p className="text-green-700">{successMessage}</p>
          </div>
        )}

        <div className="mt-6">
          <button
            onClick={logout}
            className="inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Settings;