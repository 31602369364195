import React, { useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  CssBaseline,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Box,
  Snackbar,
  Alert,
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import ClientSelection from './ClientSelection';
import AgentBasicInfo from './BasicInfo';
import VoiceSelection from './VoiceSelection';
import SkillBuilder from './SkillBuilder';
import PersonalityBuilder from './PersonalityBuilder';
import PhoneNumberAssignment from './PhoneNumberAssignment';
import WebhookPreview from './WebhookPreview';
import { useTheme as useCustomTheme } from '@/components/ThemeContext';
import { AgentData, ClientData, AgentTemplate } from '@/types/AgentTypes';
import { createAgent, updateAgentData } from '@/api/assistant';
import { searchClients } from '@/api/client';

const AdvancedAgentBuilder: React.FC = () => {
  const { isDarkMode } = useCustomTheme();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [clientData, setClientData] = useState<ClientData | null>(null);
  const [agentData, setAgentData] = useState<AgentData>({
    knowledgeBase: '',
    role: '',
    status: 'Inactive',
    dateUpdated: new Date().toISOString(),
    skills: [],
    generalKnowledge: '',
    personality: {},
    voiceId: '',
    highlight: '',
    // Removed 'assistantId' as it's managed after creation
    transcriberProvider: '',
    transcriberModel: '',
    transcriberLanguage: '',
    provider: '',
    model: '',
    messages: [],
    voiceProvider: '',
    voiceIdSecondary: '',
    phoneNumbers: [],
    gender: '',
    dealershipName: '',
    dealershipWebsiteUrl: '',
    dealerInventorySearchUrlParameters: '',
    enableInventoryLookupTool: false,
    dealershipId: null,
    dealershipAddress: '',
    salesTarget: null,
    preferredVehicleBrands: [],
    accountId: null,
    assistantDemo: null,
    emailSummaryEnabled: false,
    emailSummaryAddress: null,
    firstName: '',
    lastName: '',
    dashboardAccess: false,
    isAdmin: false,
    address: '',
    customerNote: '',
    dmsApis: [],
    email: '',
    country: '',
    state: '',
    zip: '',
    voiceSampleUrl: '',
    organizationId: null,
    profileImageUrl: '',
    // Ensure all required properties from AgentTemplate are included
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submitResult, setSubmitResult] = useState<string | null>(null);

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
    },
  });

  const steps: string[] = [
    'Select Client',
    'Agent Basic Information',
    'Voice Selection',
    'Skill Builder',
    'Personality Builder',
    'Phone Number Assignment',
    'Review & Submit',
  ];

  const handleNext = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClientSelect = (client: ClientData): void => {
    setClientData(client);
    handleNext();
  };

  const handleAgentDataChange = (newData: Partial<AgentData>): void => {
    setAgentData((prevData) => ({ ...prevData, ...newData }));
  };

  const handleSubmit = async (): Promise<void> => {
    setIsSubmitting(true);
    setSubmitResult(null);
    try {
      const fullAgentData: AgentData = {
        ...agentData,
        dealershipName: clientData?.dealershipName || '',
        dealershipWebsiteUrl: clientData?.dealershipWebsiteUrl || '',
        dealershipAddress: clientData?.address || '',
        dealershipId: clientData?.dealershipId || null,
      };

      let result: AgentData; // Explicitly type 'result' as AgentData

      if (agentData.assistantId) {
        // Update existing agent
        const updatedAgentTemplate: AgentTemplate = await updateAgentData(agentData.assistantId, fullAgentData);
        result = mapAgentTemplateToAgentData(updatedAgentTemplate);
        setSubmitResult(`Agent updated successfully: ${result.assistantId}`);
      } else {
        // Create new agent
        const createdAgentTemplate: AgentTemplate = await createAgent(fullAgentData);
        result = mapAgentTemplateToAgentData(createdAgentTemplate);
        setSubmitResult(`Agent created successfully: ${result.assistantId}`);

        // Update agentData with the new assistantId
        setAgentData((prevData) => ({
          ...prevData,
          assistantId: result.assistantId || '',
        }));
      }
      // Optionally reset form or navigate to a success page
    } catch (error) {
      setSubmitResult(
        `Error ${agentData.assistantId ? 'updating' : 'creating'} agent: ${
          (error as Error).message
        }`
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  /**
   * Maps an AgentTemplate object to an AgentData object.
   * Ensures type compatibility by converting and assigning appropriate properties.
   */
  const mapAgentTemplateToAgentData = (template: AgentTemplate): AgentData => {
    return {
      ...template,
      id: template.id ? template.id.toString() : undefined, // Convert Key (string | number) to string
      organizationId: template.organizationId || null,
      // Add any additional mappings if necessary
    };
  };

  const getStepContent = (step: number): React.ReactNode => {
    switch (step) {
      case 0:
        return <ClientSelection onSelect={handleClientSelect} onSearch={searchClients} />;
      case 1:
        return <AgentBasicInfo agentData={agentData} onChange={handleAgentDataChange} />;
      case 2:
        return <VoiceSelection agentData={agentData} onChange={handleAgentDataChange} />;
      case 3:
        return <SkillBuilder agentData={agentData} onChange={handleAgentDataChange} />;
      case 4:
        return <PersonalityBuilder agentData={agentData} onChange={handleAgentDataChange} />;
      case 5:
        return <PhoneNumberAssignment agentData={agentData} onChange={handleAgentDataChange} />;
      case 6:
        return (
          <WebhookPreview
            agentData={{
              ...agentData,
              ...clientData,
              id: clientData?.id?.toString() || '',
              // Removed 'clientId' as it does not exist in AgentData
              // Ensure all necessary properties are passed
            }}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ width: '100%', p: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Advanced Agent Builder
        </Typography>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label: string) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <AnimatePresence mode="wait">
          <motion.div
            key={activeStep}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.3 }}
          >
            <Box sx={{ mt: 4, mb: 4 }}>{getStepContent(activeStep)}</Box>
          </motion.div>
        </AnimatePresence>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {activeStep !== 0 && (
            <Button onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
          )}
          <Button
            variant="contained"
            onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
            disabled={isSubmitting}
          >
            {activeStep === steps.length - 1
              ? isSubmitting
                ? 'Submitting...'
                : 'Submit'
              : 'Next'}
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={!!submitResult}
        autoHideDuration={6000}
        onClose={() => setSubmitResult(null)}
      >
        <Alert
          onClose={() => setSubmitResult(null)}
          severity={submitResult?.includes('Error') ? 'error' : 'success'}
          sx={{ width: '100%' }}
        >
          {submitResult}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default AdvancedAgentBuilder;