// src/components/agent_builder_basic/SaveDraftButton.tsx

import React, { useState } from 'react';
import { Button, Snackbar } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { AgentData } from '@/types/AgentTypes';

interface SaveDraftButtonProps {
  agentData: AgentData;
}

const SaveDraftButton: React.FC<SaveDraftButtonProps> = ({ agentData }) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleSaveDraft = () => {
     try {
        localStorage.setItem('agentDraft', JSON.stringify(agentData));
        setOpenSnackbar(true);
     } catch (e) {
       console.error('Error saving draft:', e);
       setOpenSnackbar(true);
     }
    };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<SaveIcon />}
        onClick={handleSaveDraft}
      >
        Save Draft
      </Button>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Draft saved successfully"
      />
    </>
  );
};

export default SaveDraftButton;

// Add this function to load saved drafts
export const loadSavedDraft = (): AgentData | null => {
  const savedDraft = localStorage.getItem('agentDraft');
  return savedDraft ? JSON.parse(savedDraft) : null;
};