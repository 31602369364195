import React from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { AgentMetrics } from '@/types/AgentTypes';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';

interface MetricsDisplayProps {
  metrics: AgentMetrics;
}

const MetricsDisplay: React.FC<MetricsDisplayProps> = ({ metrics }) => {
  const theme = useTheme();

  const data = [
    { name: 'Total Calls', value: metrics.totalCalls },
    { name: 'Successful Calls', value: metrics.successfulCalls },
    { name: 'Avg Call Duration', value: metrics.averageCallDuration },
  ];

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Agent Metrics
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Box>
            <Typography variant="subtitle1" color="textSecondary">
              Total Calls
            </Typography>
            <Typography variant="h5">{metrics.totalCalls}</Typography>
          </Box>
          <Box mt={3}>
            <Typography variant="subtitle1" color="textSecondary">
              Successful Calls
            </Typography>
            <Typography variant="h5">{metrics.successfulCalls}</Typography>
          </Box>
          <Box mt={3}>
            <Typography variant="subtitle1" color="textSecondary">
              Success Rate
            </Typography>
            <Typography variant="h5">
              {(metrics.successRate * 100).toFixed(2)}%
            </Typography>
          </Box>
          <Box mt={3}>
            <Typography variant="subtitle1" color="textSecondary">
              Avg Call Duration
            </Typography>
            <Typography variant="h5">
              {metrics.averageCallDuration.toFixed(2)}s
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={8}>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="value"
                stroke={theme.palette.primary.main}
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MetricsDisplay;