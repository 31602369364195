import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  Slider,
  Grid,
  Paper,
  Tooltip,
  IconButton,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Radar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip as ChartTooltip,
  Legend,
  ChartOptions,
  TooltipItem,
} from 'chart.js';
import { motion } from 'framer-motion';
import { AgentData } from '@/types/AgentTypes';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  ChartTooltip,
  Legend
);

interface PersonalityBuilderProps {
  agentData: AgentData;
  onChange: (newData: Partial<AgentData>) => void;
}

interface PersonalityTrait {
  name: string;
  description: string;
  example: string;
  initialValue: number;
  minValue: number;
  maxValue: number;
}

interface TraitCategory {
  name: string;
  traits: PersonalityTrait[];
}

// Complete list of personality traits categorized
const traitCategories: TraitCategory[] = [
  {
    name: 'Cognitive Traits',
    traits: [
      {
        name: 'Intelligence',
        description: 'Ability to acquire and apply knowledge and skills.',
        example: 'Quickly learns new tasks and solves complex problems.',
        initialValue: 75,
        minValue: 65,
        maxValue: 85,
      },
      {
        name: 'Adaptive Intelligence',
        description: 'Ability to adapt to new situations and solve problems.',
        example: 'Easily adjusts approaches when faced with new challenges.',
        initialValue: 90,
        minValue: 80,
        maxValue: 100,
      },
      {
        name: 'Analytical Skills',
        description: 'Ability to analyze information and solve problems.',
        example: 'Uses logic to solve problems efficiently.',
        initialValue: 75,
        minValue: 65,
        maxValue: 85,
      },
      {
        name: 'Detail-oriented',
        description: 'Ability to pay attention to small details.',
        example: 'Notices small details that others might overlook.',
        initialValue: 85,
        minValue: 75,
        maxValue: 95,
      },
      {
        name: 'Competence',
        description: 'Ability to do something successfully.',
        example: 'Consistently performs tasks successfully.',
        initialValue: 80,
        minValue: 70,
        maxValue: 90,
      },
    ],
  },
  {
    name: 'Emotional Traits',
    traits: [
      {
        name: 'Emotional Intelligence',
        description: 'Ability to recognize and manage emotions in oneself and others.',
        example: 'Recognizes emotions in others and responds appropriately.',
        initialValue: 60,
        minValue: 50,
        maxValue: 70,
      },
      {
        name: 'Emotional Stability',
        description: 'Tendency to remain stable and balanced.',
        example: 'Maintains a balanced mood even in stressful situations.',
        initialValue: 70,
        minValue: 60,
        maxValue: 80,
      },
      {
        name: 'Neuroticism',
        description: 'Tendency to experience negative emotions.',
        example: 'May experience emotional ups and downs.',
        initialValue: 55,
        minValue: 45,
        maxValue: 65,
      },
      {
        name: 'Anxiety',
        description: 'Feeling of worry or unease.',
        example: 'Feels nervous in unfamiliar situations.',
        initialValue: 50,
        minValue: 40,
        maxValue: 60,
      },
      {
        name: 'Anger',
        description: 'Strong feeling of annoyance or displeasure.',
        example: 'Quick to feel frustrated or annoyed.',
        initialValue: 60,
        minValue: 50,
        maxValue: 70,
      },
      {
        name: 'Depression',
        description: 'Feelings of severe despondency and dejection.',
        example: 'Feels down or unmotivated at times.',
        initialValue: 55,
        minValue: 45,
        maxValue: 65,
      },
      {
        name: 'Stress Resistance',
        description: 'Ability to withstand stress without becoming overwhelmed.',
        example: 'Remains calm under pressure.',
        initialValue: 80,
        minValue: 70,
        maxValue: 90,
      },
      {
        name: 'Vulnerability',
        description: 'Susceptibility to emotional injury.',
        example: 'Sensitive to criticism or rejection.',
        initialValue: 65,
        minValue: 55,
        maxValue: 75,
      },
    ],
  },
  {
    name: 'Social Traits',
    traits: [
      {
        name: 'Extraversion',
        description: 'Energy directed outward toward people and activities.',
        example: 'Feels energized when interacting with others.',
        initialValue: 80,
        minValue: 70,
        maxValue: 90,
      },
      {
        name: 'Warmth',
        description: 'Friendliness and approachability.',
        example: 'Approachable and friendly in social situations.',
        initialValue: 85,
        minValue: 75,
        maxValue: 95,
      },
      {
        name: 'Generosity',
        description: 'Willingness to give and share unselfishly.',
        example: 'Willing to share time and resources with others.',
        initialValue: 80,
        minValue: 70,
        maxValue: 90,
      },
      {
        name: 'Agreeableness',
        description: 'Tendency to be compassionate and cooperative.',
        example: 'Cooperates well with team members.',
        initialValue: 75,
        minValue: 65,
        maxValue: 85,
      },
      {
        name: 'Social Engagement',
        description: 'Participation and interaction with others.',
        example: 'Enjoys socializing and participating in group activities.',
        initialValue: 65,
        minValue: 55,
        maxValue: 75,
      },
      {
        name: 'Charisma',
        description: 'Compelling attractiveness or charm.',
        example: 'Naturally attracts and inspires others.',
        initialValue: 75,
        minValue: 65,
        maxValue: 85,
      },
    ],
  },
  {
    name: 'Personal Values',
    traits: [
      {
        name: 'Integrity',
        description: 'Quality of being honest and having strong moral principles.',
        example: 'Always acts honestly and keeps promises.',
        initialValue: 95,
        minValue: 85,
        maxValue: 100,
      },
      {
        name: 'Trustworthiness',
        description: 'Ability to be relied on as honest and truthful.',
        example: 'Others can rely on them to be honest.',
        initialValue: 80,
        minValue: 70,
        maxValue: 90,
      },
      {
        name: 'Altruism',
        description: 'Selfless concern for the well-being of others.',
        example: 'Often helps others without expecting anything in return.',
        initialValue: 85,
        minValue: 75,
        maxValue: 95,
      },
    ],
  },
  {
    name: 'Personality Traits',
    traits: [
      {
        name: 'Openness to Experience',
        description: 'Appreciation for art, emotion, adventure, and unusual ideas.',
        example: 'Enjoys exploring new ideas and taking on creative projects.',
        initialValue: 85,
        minValue: 75,
        maxValue: 95,
      },
      {
        name: 'Adventurousness',
        description: 'Willingness to take risks and try new experiences.',
        example: 'Loves trying new activities and stepping outside comfort zones.',
        initialValue: 90,
        minValue: 80,
        maxValue: 100,
      },
      {
        name: 'Open-mindedness',
        description: 'Willingness to consider new ideas.',
        example: 'Considers different perspectives before making decisions.',
        initialValue: 85,
        minValue: 75,
        maxValue: 95,
      },
      {
        name: 'Conscientiousness',
        description: 'Desire to do a task well and to take obligations to others seriously.',
        example: 'Pays attention to detail and follows through on commitments.',
        initialValue: 70,
        minValue: 60,
        maxValue: 80,
      },
      {
        name: 'Orderliness',
        description: 'Need for order and organization.',
        example: 'Keeps things organized and values structure.',
        initialValue: 60,
        minValue: 50,
        maxValue: 70,
      },
      {
        name: 'Cautiousness',
        description: 'Tendency to avoid risks.',
        example: 'Thinks carefully before taking action.',
        initialValue: 70,
        minValue: 60,
        maxValue: 80,
      },
      {
        name: 'Assertiveness',
        description: 'Confidence in expressing opinions and needs.',
        example: 'Confidently expresses opinions and needs.',
        initialValue: 80,
        minValue: 70,
        maxValue: 90,
      },
      {
        name: 'Ambition',
        description: 'Desire and determination to achieve success.',
        example: 'Motivated to achieve personal and professional goals.',
        initialValue: 70,
        minValue: 60,
        maxValue: 80,
      },
      {
        name: 'Self-Confidence',
        description: 'Belief in oneself and one’s abilities.',
        example: 'Believes in their abilities and decisions.',
        initialValue: 75,
        minValue: 65,
        maxValue: 85,
      },
      {
        name: 'Modesty',
        description: 'Humility about one’s abilities and achievements.',
        example: 'Does not boast about achievements.',
        initialValue: 75,
        minValue: 65,
        maxValue: 85,
      },
      {
        name: 'Time Management',
        description: 'Ability to use time effectively.',
        example: 'Efficiently organizes tasks to meet deadlines.',
        initialValue: 85,
        minValue: 75,
        maxValue: 95,
      },
      {
        name: 'Self-Control',
        description: 'Ability to control oneself.',
        example: 'Able to resist temptations and impulses.',
        initialValue: 60,
        minValue: 50,
        maxValue: 70,
      },
      {
        name: 'Immoderation',
        description: 'Lack of restraint.',
        example: 'May overindulge in activities or behaviors.',
        initialValue: 70,
        minValue: 60,
        maxValue: 80,
      },
    ],
  },
];

const PersonalityBuilder: React.FC<PersonalityBuilderProps> = ({ agentData, onChange }) => {
  const totalTraits = traitCategories.reduce((acc, category) => acc + category.traits.length, 0);

  const [traits, setTraits] = useState<{ [key: string]: number }>(() => {
    if (
      agentData.personality &&
      Object.keys(agentData.personality).length === totalTraits
    ) {
      return agentData.personality;
    }
    // Initialize traits with default values
    return traitCategories.reduce((acc, category) => {
      category.traits.forEach((trait) => {
        acc[trait.name] = trait.initialValue;
      });
      return acc;
    }, {} as { [key: string]: number });
  });

  const handleTraitChange = (traitName: string, value: number) => {
    setTraits((prevTraits) => ({
      ...prevTraits,
      [traitName]: value,
    }));
  };

  const updatePersonality = useCallback(() => {
    onChange({ personality: traits });
  }, [traits, onChange]);

  useEffect(() => {
    updatePersonality();
  }, [updatePersonality]);

  const chartData = {
    labels: traitCategories.flatMap((category) => category.traits.map((trait) => trait.name)),
    datasets: [
      {
        label: 'Personality Traits',
        data: traitCategories.flatMap((category) => category.traits.map((trait) => traits[trait.name])),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
        pointBackgroundColor: 'rgba(75, 192, 192, 1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(75, 192, 192, 1)',
      },
    ],
  };

  const chartOptions: ChartOptions<'radar'> = {
    scales: {
      r: {
        angleLines: {
          display: false,
        },
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: {
          stepSize: 25,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context: TooltipItem<'radar'>) {
            return `${context.label}: ${context.formattedValue}`;
          },
        },
      },
    },
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Typography variant="h5" gutterBottom>
        Personality Builder
      </Typography>
      <Grid container spacing={3}>
        {/* Trait Sliders Section */}
        <Grid item xs={12} md={6}>
          {traitCategories.map((category, catIndex) => (
            <Accordion key={category.name} defaultExpanded={catIndex === 0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${category.name}-content`}
                id={`${category.name}-header`}
              >
                <Typography variant="h6">{category.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {category.traits.map((trait) => (
                  <Box key={trait.name} mb={3}>
                    <Box display="flex" alignItems="center" mb={1}>
                      <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                        {trait.name}
                      </Typography>
                      <Tooltip
                        title={
                          <React.Fragment>
                            <Typography color="inherit">{trait.description}</Typography>
                            <Typography variant="body2">Example: {trait.example}</Typography>
                          </React.Fragment>
                        }
                        arrow
                        placement="top"
                      >
                        <IconButton size="small">
                          <InfoIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Slider
                      value={traits[trait.name]}
                      onChange={(_, value) => handleTraitChange(trait.name, value as number)}
                      aria-labelledby={`${trait.name}-slider`}
                      valueLabelDisplay="auto"
                      min={trait.minValue}
                      max={trait.maxValue}
                      step={1}
                      marks={[
                        { value: trait.minValue, label: `${trait.minValue}` },
                        { value: trait.maxValue, label: `${trait.maxValue}` },
                      ]}
                      sx={{
                        color: 'primary.main',
                        '& .MuiSlider-thumb': {
                          '&:hover, &.Mui-focusVisible, &.Mui-active': {
                            boxShadow: 'inherit',
                          },
                        },
                      }}
                    />
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
        {/* Radar Chart Section */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Radar data={chartData} options={chartOptions} />
          </Paper>
        </Grid>
      </Grid>
    </motion.div>
  );
};

export default PersonalityBuilder;