import React, { useEffect, useState, useCallback } from 'react';
import { Box, Typography, CircularProgress, Grid, Paper, useTheme } from '@mui/material';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { fetchUserMetrics, updateUserMetrics } from '@/api/billing';
import { UserMetrics } from '@/types/AgentTypes';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const CallMetrics: React.FC = () => {
  const [metrics, setMetrics] = useState<UserMetrics[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const theme = useTheme();

  const fetchMetrics = useCallback(async () => {
    try {
      setLoading(true);
      await updateUserMetrics(); // Update metrics before fetching
      const data = await fetchUserMetrics('all');
      setMetrics(Array.isArray(data) ? data : [data]);
      setError(null);
    } catch (error) {
      console.error('Error fetching metrics:', error);
      setError('Failed to fetch metrics. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchMetrics();
  }, [fetchMetrics]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!metrics || metrics.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography>No metrics data available</Typography>
      </Box>
    );
  }

  const totalCalls = metrics.reduce((sum, metric) => sum + Number(metric.callCount), 0);
  const totalCost = metrics.reduce((sum, metric) => sum + Number(metric.totalCost), 0);
  const totalDuration = metrics.reduce((sum, metric) => sum + Number(metric.totalDuration), 0);
  const averageCallCost = totalCalls > 0 ? totalCost / totalCalls : 0;
  const averageCallDuration = totalCalls > 0 ? totalDuration / totalCalls / 60 : 0;

  const dailyData = metrics.map(metric => ({
    date: new Date(metric.date).toLocaleDateString(),
    bill: Number(metric.totalCost)
  }));

  // This is example data. In a real scenario, you'd calculate these values from your metrics.
  const pieData = [
    { name: 'Pipeline Error', value: 3 },
    { name: 'No Microphone Permission', value: 2 },
    { name: 'Customer Ended Call', value: 11 },
    { name: 'Unknown Error', value: 1 },
  ];

  return (
    <Box sx={{ p: 3, bgcolor: theme.palette.background.default, color: theme.palette.text.primary }}>
      <Typography variant="h4" gutterBottom>Call Metrics Overview</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Paper sx={{ p: 2, bgcolor: theme.palette.background.paper, color: theme.palette.text.primary }}>
            <Typography variant="h6">Total Minutes</Typography>
            <Typography variant="h4">{(totalDuration / 60).toFixed(2)}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper sx={{ p: 2, bgcolor: theme.palette.background.paper, color: theme.palette.text.primary }}>
            <Typography variant="h6">Average Call Duration</Typography>
            <Typography variant="h4">{averageCallDuration.toFixed(2)} minutes</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper sx={{ p: 2, bgcolor: theme.palette.background.paper, color: theme.palette.text.primary }}>
            <Typography variant="h6">Average Call Cost</Typography>
            <Typography variant="h4">${averageCallCost.toFixed(2)}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper sx={{ p: 2, bgcolor: theme.palette.background.paper, color: theme.palette.text.primary }}>
            <Typography variant="h6">Total Cost</Typography>
            <Typography variant="h4">${totalCost.toFixed(2)}</Typography>
          </Paper>
        </Grid>
      </Grid>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>Call Analysis</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 2, bgcolor: theme.palette.background.paper, height: 300 }}>
              <Typography variant="h6" gutterBottom>Daily Billing</Typography>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={dailyData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Area type="monotone" dataKey="bill" stroke={theme.palette.primary.main} fill={theme.palette.primary.light} />
                </AreaChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 2, bgcolor: theme.palette.background.paper, height: 300 }}>
              <Typography variant="h6" gutterBottom>Reason Call Ended</Typography>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={pieData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {pieData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CallMetrics;