import React, { useEffect, useState } from 'react';
import { fetchAdminLogs, Log } from '../../api/admin';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Container,
  CircularProgress,
  Alert,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import ActivityIcon from '@mui/icons-material/Visibility';

const AdminLogs: React.FC = () => {
  const [logs, setLogs] = useState<Log[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const getLogs = async () => {
      try {
        const data = await fetchAdminLogs();
        setLogs(data);
        setLoading(false);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError('Failed to fetch logs');
        }
        setLoading(false);
      }
    };

    getLogs();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box m={2}>
        <Alert severity="error">Error: {error}</Alert>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Admin Logs
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" paragraph>
          View recent login and activity logs
        </Typography>
        <TableContainer component={Paper} elevation={3}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                {!isMobile && <TableCell>User ID</TableCell>}
                <TableCell>Details</TableCell>
                <TableCell>Timestamp</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((log) => (
                <TableRow
                  key={`${log.log_type}-${log.id}`}
                  sx={{
                    '&:nth-of-type(odd)': { backgroundColor: theme.palette.action.hover },
                    '&:hover': { backgroundColor: theme.palette.action.selected },
                  }}
                >
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      {log.log_type === 'login' ? (
                        <LoginIcon color="primary" sx={{ mr: 1 }} />
                      ) : (
                        <ActivityIcon color="secondary" sx={{ mr: 1 }} />
                      )}
                      {log.log_type}
                    </Box>
                  </TableCell>
                  {!isMobile && <TableCell>{log.user_id}</TableCell>}
                  <TableCell>{log.details}</TableCell>
                  <TableCell>{new Date(log.timestamp).toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default AdminLogs;