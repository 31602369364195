import api from './api';
import { handleError } from '../utils/errors';
import { UserMetrics, ActivityLog } from '@/types/AgentTypes';

/**
 * Fetches and stores metrics for a specific assistant.
 * 
 * @param {string} assistantId - The ID of the assistant.
 * @param {Partial<UserMetrics>} metricsData - The metrics data to be fetched and stored.
 * @returns {Promise<UserMetrics>} - The fetched and stored metrics.
 */
export const fetchAssistantMetrics = async (
  assistantId: string,
  metricsData: Partial<UserMetrics>
): Promise<UserMetrics> => {
  try {
    const response = await api.post<UserMetrics>(
      `/api/metrics/fetch?assistantId=${assistantId}`,
      metricsData
    );
    return response.data;
  } catch (error) {
    handleError(error, 'Error fetching assistant metrics');
    throw error;
  }
};

/**
 * Retrieves recent activity logs for a specific assistant.
 * 
 * @param {string} assistantId - The ID of the assistant.
 * @returns {Promise<ActivityLog[]>} - An array of recent activity logs.
 */
export const getRecentActivity = async (assistantId: string): Promise<ActivityLog[]> => {
  try {
    const response = await api.get<ActivityLog[]>(
      `/api/metrics/${assistantId}/recent-activity`
    );
    return response.data;
  } catch (error) {
    handleError(error, 'Error fetching recent activity');
    throw error;
  }
};

/**
 * Retrieves user-wide recent activity logs.
 * 
 * @returns {Promise<ActivityLog[]>} - An array of recent activity logs.
 */
export const getUserRecentActivity = async (): Promise<ActivityLog[]> => {
  try {
    const response = await api.get<ActivityLog[]>(
      `/api/metrics/recent-activity`
    );
    return response.data;
  } catch (error) {
    handleError(error, 'Error fetching user recent activity');
    throw error;
  }
};