// src/components/BillingPage/BillingPage.tsx

import React, { useEffect, useState, useCallback } from 'react';
import {
  Container,
  Grid,
  TextField,
  Button,
  Typography,
  Paper,
  Box,
  CircularProgress,
  Alert,
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';
import styles from './BillingPage.module.scss';
import BillingSummary from '../BillingSummary';
import PaymentForm from './PaymentForm';
import {
  getBillingInfo,
  getInvoices,
  updateBillingInfo,
} from '@/api/billing';
import { fetchCurrentUser } from '@/api/user';
import { useTheme } from '../ThemeContext';
import { UserProfile } from '@/types/AgentTypes'; // Import only UserProfile from AgentTypes
import { InvoiceItem } from '@/types/BillingTypes'; // Import InvoiceItem from BillingTypes

interface NonEditableBillingInfo {
  userId: string;
  accountId: string;
  organizationId: string;
  accountName: string;
}

interface EditableBillingInfo {
  business_name: string | null;
  state: string | null;
  country: string | null;
  zip: string | null;
  address: string | null;
  billing_address: string | null;
}

interface Invoice {
  invoiceId: string;
  amount: number;
  currency: string;
  date_created: string;
  usage_time: number;
  status: string;
  items: InvoiceItem[];
}

const BillingPage: React.FC = () => {
  const { isDarkMode } = useTheme();

  const [nonEditableBillingInfo, setNonEditableBillingInfo] =
    useState<NonEditableBillingInfo>({
      userId: '',
      accountId: '',
      organizationId: '',
      accountName: '',
    });

  const [editableBillingInfo, setEditableBillingInfo] =
    useState<EditableBillingInfo>({
      business_name: '',
      state: '',
      country: '',
      zip: '',
      address: '',
      billing_address: '',
    });

  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // useCallback to memoize fetchUserInfo and avoid ESLint warnings
  const fetchUserInfo = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const userData: UserProfile = await fetchCurrentUser();

      if (!userData.accountId) {
        throw new Error('No accountId found in user data');
      }

      setNonEditableBillingInfo({
        userId: userData.userId || '',
        accountId: userData.accountId || '',
        organizationId: userData.organizationId || '',
        accountName: userData.accountName || '',
      });

      await Promise.all([
        fetchBillingInfoData(userData.accountId),
        fetchInvoicesData(userData.accountId),
      ]);
    } catch (err) {
      console.error(err);
      setError('An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUserInfo();
  }, [fetchUserInfo]);

  const fetchBillingInfoData = async (accountId: string) => {
    try {
      const data = await getBillingInfo(accountId);
      setEditableBillingInfo({
        business_name: data.business_name ?? '',
        state: data.state ?? '',
        country: data.country ?? '',
        zip: data.zip ?? '',
        address: data.address ?? '',
        billing_address: data.billing_address ?? '',
      });
    } catch (err) {
      console.error(err);
      throw new Error('Error fetching billing information');
    }
  };

  const fetchInvoicesData = async (accountId: string) => {
    try {
      const data = await getInvoices(accountId);
      setInvoices(data);
    } catch (err) {
      console.error(err);
      throw new Error('Error fetching invoices');
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditableBillingInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveBillingInfo = async () => {
    if (!nonEditableBillingInfo.accountId) {
      setError('Account ID is not available');
      return;
    }
    try {
      const data = await updateBillingInfo(
        nonEditableBillingInfo.accountId,
        editableBillingInfo
      );
      setEditableBillingInfo({
        business_name: data.business_name ?? '',
        state: data.state ?? '',
        country: data.country ?? '',
        zip: data.zip ?? '',
        address: data.address ?? '',
        billing_address: data.billing_address ?? '',
      });
      toast.success('Billing information updated successfully');
    } catch (err) {
      console.error(err);
      setError(
        err instanceof Error
          ? err.message
          : 'Failed to update billing information'
      );
    }
  };

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
    },
  });

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        {error}
        <Button color="inherit" size="small" onClick={fetchUserInfo}>
          Retry
        </Button>
      </Alert>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Container className={styles.container}>
        <Typography variant="h4" gutterBottom>
          Billing &amp; Usage
        </Typography>
        <Box mb={3}>
          <BillingSummary />
        </Box>
        <Paper elevation={3} className={styles.paper}>
          <Typography variant="h5" gutterBottom>
            Account Information
          </Typography>
          <Grid container spacing={2}>
            {Object.entries(nonEditableBillingInfo).map(([key, value]) => (
              <Grid item xs={12} sm={6} key={key}>
                <TextField
                  label={key
                    .replace(/([A-Z])/g, ' $1')
                    .replace(/^./, (str) => str.toUpperCase())
                    .trim()}
                  value={value}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Paper>
        <Paper elevation={3} className={styles.paper}>
          <Typography variant="h5" gutterBottom>
            Billing Information
          </Typography>
          <Grid container spacing={2}>
            {Object.entries(editableBillingInfo).map(([key, value]) => (
              <Grid item xs={12} sm={6} key={key}>
                <TextField
                  label={key
                    .replace(/_/g, ' ')
                    .replace(/\b\w/g, (l) => l.toUpperCase())}
                  name={key}
                  value={value}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveBillingInfo}
              >
                Save Billing Info
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Box mt={3}>
          <Paper elevation={3} className={styles.paper}>
            <Typography variant="h5" gutterBottom>
              Make a Payment
            </Typography>
            <PaymentForm />
          </Paper>
        </Box>
        <Box mt={3}>
          <Paper elevation={3} className={styles.paper}>
            <Typography variant="h5" gutterBottom>
              Invoices
            </Typography>
            {invoices.length > 0 ? (
              <Grid container spacing={2}>
                {invoices.map((invoice) => (
                  <Grid item xs={12} key={invoice.invoiceId}>
                    <Paper elevation={1} className={styles.invoice}>
                      <Typography>
                        Date:{' '}
                        {new Date(invoice.date_created).toLocaleDateString()}
                      </Typography>
                      <Typography>Status: {invoice.status}</Typography>
                      <Typography>
                        Amount: {invoice.amount} {invoice.currency}
                      </Typography>
                      <Typography>
                        Usage Time: {invoice.usage_time} hours
                      </Typography>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography>No invoices found.</Typography>
            )}
          </Paper>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default BillingPage;