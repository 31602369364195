import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemAvatar,
  Avatar,
  Typography,
  useTheme,
  Box,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { ActivityLog } from '@/types/AgentTypes';
import { formatDistanceToNow } from 'date-fns';

interface RecentActivityProps {
  activities: ActivityLog[];
}

const RecentActivity: React.FC<RecentActivityProps> = ({ activities }) => {
  const theme = useTheme();

  return (
    <Box sx={{ maxHeight: 400, overflowY: 'auto' }}>
      <List>
        {activities.map((activity, index) => (
          <React.Fragment key={activity.id || index}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                  <AccessTimeIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={activity.activityType || 'No Activity Type'}
                secondary={
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.secondary"
                  >
                    {formatDistanceToNow(new Date(activity.timestamp), {
                      addSuffix: true,
                    })}
                  </Typography>
                }
              />
            </ListItem>
            {index < activities.length - 1 && (
              <Divider variant="fullWidth" component="li" />
            )}
          </React.Fragment>
        ))}
        {activities.length === 0 && (
          <Typography variant="body1">No recent activity available.</Typography>
        )}
      </List>
    </Box>
  );
};

export default RecentActivity;