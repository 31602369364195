// src/components/CallLogs.tsx

import React, { useState, useEffect, useCallback } from 'react';
import { fetchCallLogs, fetchCallLogById, triggerSalesAnalysis } from '@/api/callLogs';
import { fetchUserAssistants } from '@/api/user';
import { format, parseISO } from 'date-fns';
import { motion, AnimatePresence } from 'framer-motion';
import { FaPhone, FaClock, FaFileAlt, FaChevronDown, FaChevronUp, FaSort, FaSync, FaChartBar } from 'react-icons/fa';
import styled, { ThemeProvider } from 'styled-components';
import { useTheme } from './ThemeContext';
import { Assistant, CallLog } from '@/types/AgentTypes';


// Styled components (unchanged)
const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background-color: ${(props) => props.theme.backgroundColor};
  color: ${(props) => props.theme.textColor};
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: ${(props) => props.theme.titleColor};
  margin-bottom: 2rem;
  text-align: center;
`;

const CallLogEntry = styled(motion.div)`
  background-color: ${(props) => props.theme.cardBackgroundColor};
  border-radius: 12px;
  box-shadow: 0 4px 6px ${(props) => props.theme.shadowColor};
  padding: 1.5rem;
  margin-bottom: 1.5rem;
`;

const CallInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1.1rem;
`;

const Icon = styled.span`
  margin-right: 0.5rem;
  color: ${(props) => props.theme.accentColor};
`;

const SummaryContent = styled.div`
  background-color: ${(props) => props.theme.summaryBackgroundColor};
  border-radius: 8px;
  padding: 1rem;
  margin-top: 1rem;
`;

const ExpandButton = styled.button`
  background: none;
  border: none;
  color: ${(props) => props.theme.accentColor};
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-top: 1rem;

  &:hover {
    text-decoration: underline;
  }
`;

const SortButton = styled.button`
  background: none;
  border: none;
  color: ${(props) => props.theme.accentColor};
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-bottom: 1rem;

  &:hover {
    text-decoration: underline;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const PageButton = styled.button`
  background-color: ${(props) => props.theme.accentColor};
  color: ${(props) => props.theme.buttonTextColor};
  border: none;
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  border-radius: 4px;
  cursor: pointer;

  &:disabled {
    background-color: ${(props) => props.theme.disabledColor};
    cursor: not-allowed;
  }
`;

const AnalysisButton = styled.button`
  background: none;
  border: none;
  color: ${(props) => props.theme.accentColor};
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-left: 1rem;

  &:hover {
    text-decoration: underline;
  }
`;

// CallLogs component
const CallLogs: React.FC = () => {
  const { isDarkMode } = useTheme();
  const [callLogs, setCallLogs] = useState<CallLog[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [expandedLogs, setExpandedLogs] = useState<Set<string>>(new Set());
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAssistantId, setSelectedAssistantId] = useState<string | null>(null);
  const [assistants, setAssistants] = useState<Assistant[]>([]);
  const logsPerPage = 10;

  const loadAssistants = useCallback(async () => {
    try {
      const userAssistants = await fetchUserAssistants();
      setAssistants(userAssistants);
      if (userAssistants.length > 0) {
        setSelectedAssistantId(userAssistants[0].assistantId);
      }
    } catch (err) {
      console.error('Error loading assistants:', err);
      setError('Failed to load assistants. Please try again.');
    }
  }, []);

  useEffect(() => {
    loadAssistants();
  }, [loadAssistants]);

  // Function to load call logs
  const loadCallLogs = useCallback(async () => {
    if (!selectedAssistantId) {
      setError('No assistant selected');
      return;
    }

    try {
      setLoading(true);
      setError(null);
      const logs = await fetchCallLogs(selectedAssistantId);
      setCallLogs(logs);
    } catch (err) {
      console.error('Error loading call logs:', err);
      setError('Failed to load call logs. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [selectedAssistantId]);

  const handleTriggerAnalysis = async (log: CallLog) => {
    if (log.analysis || !log.summary) {
      return;
    }

    try {
      setLoading(true);
      const analysisResult = await triggerSalesAnalysis({
        call_id: log.callId,
        assistant_id: log.assistantId,
        summary: log.summary,
        transcript: log.transcript || ''
      });

      if (analysisResult) {
        const analysis = `Customer Satisfaction: ${analysisResult.customerSatisfactionScore}, Lead Quality: ${analysisResult.leadQuality}`;
        const analysis_summary = `Key Topics: ${analysisResult.keyTopicsDiscussed.join(', ')}, Sentiment - Positive: ${analysisResult.sentimentAnalysis.positive}, Negative: ${analysisResult.sentimentAnalysis.negative}, Neutral: ${analysisResult.sentimentAnalysis.neutral}`;

        setCallLogs(prevLogs => 
          prevLogs.map(l => 
            l.callId === log.callId 
              ? { ...l, analysis, analysisSummary: analysis_summary } 
              : l
          )
        );
      } else {
        setError('Analysis result was null.');
      }
    } catch (err) {
      console.error('Error triggering analysis:', err);
      setError('Failed to trigger analysis. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedAssistantId) {
      loadCallLogs();
    }
  }, [loadCallLogs, selectedAssistantId]);

  const toggleExpand = async (callId: string) => {
    if (expandedLogs.has(callId)) {
      setExpandedLogs(prev => {
        const newSet = new Set(prev);
        newSet.delete(callId);
        return newSet;
      });
    } else {
      try {
        const detailedLog = await fetchCallLogById(callId);
        setCallLogs(prevLogs => 
          prevLogs.map(log => 
            log.callId === callId ? { ...log, ...detailedLog } : log
          )
        );
        setExpandedLogs(prev => new Set(prev).add(callId));
      } catch (err) {
        console.error('Error fetching call log details:', err);
        setError('Failed to fetch call log details. Please try again.');
      }
    }
  };

  const toggleSortOrder = () => {
    setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
  };

  const sortedLogs = [...callLogs].sort((a, b) => {
    const dateA = new Date(a.startedAt).getTime();
    const dateB = new Date(b.startedAt).getTime();
    return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
  });

  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = sortedLogs.slice(indexOfFirstLog, indexOfLastLog);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const theme = {
    backgroundColor: isDarkMode ? '#1a1a1a' : '#f5f7fa',
    textColor: isDarkMode ? '#e0e0e0' : '#2c3e50',
    titleColor: isDarkMode ? '#ffffff' : '#2c3e50',
    cardBackgroundColor: isDarkMode ? '#2a2a2a' : '#ffffff',
    shadowColor: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
    accentColor: isDarkMode ? '#64b5f6' : '#3498db',
    summaryBackgroundColor: isDarkMode ? '#3a3a3a' : '#ecf0f1',
    buttonTextColor: '#ffffff',
    disabledColor: isDarkMode ? '#4a4a4a' : '#bdc3c7',
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Title>Call Logs</Title>
        <div>
          <select 
            value={selectedAssistantId || ''} 
            onChange={(e) => setSelectedAssistantId(e.target.value)}
          >
            <option value="">Select an assistant</option>
            {assistants.map((assistant) => (
              <option key={assistant.assistantId} value={assistant.assistantId}>
                {assistant.name}
              </option>
            ))}
          </select>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
          <SortButton onClick={toggleSortOrder}>
            Sort by Date {sortOrder === 'asc' ? 'Ascending' : 'Descending'} <FaSort />
          </SortButton>
          <SortButton onClick={loadCallLogs}>
            Refresh <FaSync />
          </SortButton>
        </div>
        {loading && <p>Loading call logs...</p>}
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <AnimatePresence>
          {currentLogs.map((log) => (
            <CallLogEntry
              key={log.callId}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <CallInfo>
                <Icon><FaPhone /></Icon>
                <strong>Started: {format(parseISO(log.startedAt), 'PPpp')}</strong>
              </CallInfo>
              {log.endedAt && (
                <CallInfo>
                  <Icon><FaClock /></Icon>
                  Ended: {format(parseISO(log.endedAt), 'PPpp')}
                </CallInfo>
              )}
              <CallInfo>
                <Icon><FaFileAlt /></Icon>
                Type: {log.type}
                {log.type === "inboundPhoneCall" && log.phoneNumber && (
                  <span style={{ marginLeft: '1rem' }}>
                    Phone: {log.phoneNumber}
                  </span>
                )}
                {!log.analysis && log.summary && (
                  <AnalysisButton onClick={() => handleTriggerAnalysis(log)}>
                    <FaChartBar style={{ marginRight: '5px' }} />
                    Analyze
                  </AnalysisButton>
                )}
              </CallInfo>
              <SummaryContent>
                <strong>Call Summary:</strong>
                {log.summary || 'No call summary available'}
              </SummaryContent>
              {log.analysis && (
                <SummaryContent>
                  <strong>Analysis:</strong>
                  {log.analysis}
                </SummaryContent>
              )}
              {log.analysisSummary && (
                <SummaryContent>
                  <strong>Analysis Summary:</strong>
                  {log.analysisSummary}
                </SummaryContent>
              )}
              <ExpandButton onClick={() => toggleExpand(log.callId)}>
                {expandedLogs.has(log.callId) ? (
                  <>
                    Hide Details <FaChevronUp style={{ marginLeft: '5px' }} />
                  </>
                ) : (
                  <>
                    Show Details <FaChevronDown style={{ marginLeft: '5px' }} />
                  </>
                )}
              </ExpandButton>
              <AnimatePresence>
                {expandedLogs.has(log.callId) && (
                  <motion.div
                    key={`details-${log.callId}`}
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <SummaryContent>
                      <h4>Transcript:</h4>
                      <pre style={{ whiteSpace: 'pre-wrap', fontFamily: 'inherit' }}>
                        {log.transcript || 'No transcript available'}
                      </pre>
                    </SummaryContent>
                  </motion.div>
                )}
              </AnimatePresence>
            </CallLogEntry>
          ))}
        </AnimatePresence>
        <Pagination>
          <PageButton onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
            Previous
          </PageButton>
          <PageButton onClick={() => paginate(currentPage + 1)} disabled={indexOfLastLog >= sortedLogs.length}>
            Next
          </PageButton>
        </Pagination>
      </Container>
    </ThemeProvider>
  );
};

export default CallLogs;
