// src/api/api.ts

import axios, {
  AxiosInstance,
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import config from '../config';
import {
  getToken,
  setToken,
  getRefreshToken,
  setRefreshToken,
  clearTokens,
} from '../utils/jwtUtils';
import { ApiError } from '../utils/errors';
import { toCamelCase } from '../utils/caseConverter';

/**
 * Create an Axios instance with default configurations.
 */
const api: AxiosInstance = axios.create({
  baseURL: config.API_BASE_URL, // Base URL now includes '/api' prefix
  timeout: 10000, // Request timeout in milliseconds
  headers: {
    'Content-Type': 'application/json', // Default content type
  },
  withCredentials: true, // Include cookies in requests if needed
});

/**
 * Custom interface to include _retry flag
 */
interface AxiosRequestWithRetry extends InternalAxiosRequestConfig {
  _retry?: boolean;
}

/**
 * Flag to indicate if token refresh is in progress
 */
let isRefreshing = false;

/**
 * Queue to hold failed requests while token is being refreshed
 */
let failedQueue: Array<{
  resolve: (value: unknown) => void;
  reject: (error: unknown) => void;
}> = [];

/**
 * Function to process the queue once token is refreshed
 */
const processQueue = (error: unknown, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

/**
 * Request interceptor to attach the access token to every request.
 */
api.interceptors.request.use(
  (config) => {
    const token = getToken(); // Retrieve the access token from storage
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`; // Attach token to Authorization header
    }
    return config;
  },
  (error: AxiosError) => {
    // Handle request errors here
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

/**
 * Response interceptor to handle responses globally, including token refresh.
 */
api.interceptors.response.use(
  (response: AxiosResponse) => {
    // Convert response data to camelCase for internal API responses
    const isExternalAPI =
      !response.config.baseURL || response.config.baseURL !== config.API_BASE_URL;
    if (!isExternalAPI) {
      response.data = toCamelCase(response.data);
    }
    return response;
  },
  async (error: AxiosError) => {
    const originalRequest = error.config as AxiosRequestWithRetry;

    if (error.response) {
      const { status } = error.response;

      if (status === 401 && !originalRequest._retry) {
        // Handle Unauthorized errors and attempt to refresh the token
        if (isRefreshing) {
          // If token refresh is already in progress, queue the request
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              if (originalRequest.headers && typeof token === 'string') {
                originalRequest.headers.Authorization = `Bearer ${token}`;
              }
              return api(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        const refreshTokenValue = getRefreshToken();
        if (!refreshTokenValue) {
          // No refresh token available, redirect to login
          clearTokens();
          isRefreshing = false;
          if (window.location.pathname !== '/login') {
            window.location.href = '/login';
          }
          return Promise.reject(error);
        }

        try {
          // Attempt to refresh the token using axios without interceptors
          const response = await axios.post(
            `${config.API_BASE_URL}/auth/refresh-token`,
            { refreshToken: refreshTokenValue },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
          const { token: newToken, refreshToken: newRefreshToken } = response.data;

          // Update tokens in storage
          setToken(newToken);
          setRefreshToken(newRefreshToken);

          // Update the Authorization header and retry the original request
          api.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
          if (originalRequest.headers) {
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
          }

          processQueue(null, newToken);
          isRefreshing = false;

          return api(originalRequest);
        } catch (refreshError) {
          processQueue(refreshError, null);
          console.error('Error refreshing token:', refreshError);
          // If refresh fails, clear tokens and redirect to login
          clearTokens();
          isRefreshing = false;
          if (window.location.pathname !== '/login') {
            window.location.href = '/login';
          }
          return Promise.reject(refreshError);
        }
      } else if (status === 403) {
        // Forbidden - access denied
        console.error('Forbidden - you do not have access to this resource.');
        // Optionally, redirect to a forbidden page or show a message
      } else if (status === 404) {
        // Not Found - resource doesn't exist
        console.error('Resource not found.');
        // Optionally, handle 404 errors globally
      } else if (status === 500) {
        // Internal Server Error - server-side issue
        console.error('Internal server error.');
        // Optionally, handle server errors
      } else {
        const errorMessage =
          (error.response.data as { message?: string }).message || 'An error occurred.';
        console.error(`Error: ${errorMessage}`);
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received:', error.request);
    } else {
      // Something happened in setting up the request
      console.error('Error in setting up the request:', error.message);
    }

    // Optionally, throw a custom error or handle it as needed
    return Promise.reject(new ApiError(error.message, error));
  }
);

export default api;