// src/components/overview/UserPlanDisplay.tsx

import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  LinearProgress,
  Button,
  // Removed `useTheme` as it's no longer needed
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';

// Define the props interface
interface UserPlanDisplayProps {
  planName: string;
  planFeatures: string[];
  agentsCreated: number;
  agentLimit: number;
}

const PlanContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  boxShadow: theme.shadows[4],
  backgroundColor: theme.palette.background.paper,
  position: 'relative',
  overflow: 'hidden',
}));

const BackgroundCircle = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: -theme.spacing(10),
  right: -theme.spacing(10),
  width: theme.spacing(30),
  height: theme.spacing(30),
  backgroundColor: theme.palette.primary.light,
  borderRadius: '50%',
  opacity: 0.1,
}));

const UserPlanDisplay: React.FC<UserPlanDisplayProps> = ({
  planName,
  planFeatures,
  agentsCreated,
  agentLimit,
}) => {
  const navigate = useNavigate();
  // Removed `theme` as it's no longer used
  // const theme = useTheme();

  // Handle agentLimit being null or zero
  const progress =
    agentLimit && agentLimit > 0
      ? (agentsCreated / agentLimit) * 100
      : 100; // Assume 100% if unlimited or zero

  return (
    <PlanContainer>
      <BackgroundCircle />
      <Typography variant="h5" gutterBottom sx={{ zIndex: 1 }}>
        {planName} Plan
      </Typography>
      <List dense sx={{ zIndex: 1 }}>
        {planFeatures.map((feature: string, index: number) => (
          <ListItem key={index}>
            <ListItemIcon>
              <CheckCircleIcon color="success" />
            </ListItemIcon>
            <ListItemText primary={feature} />
          </ListItem>
        ))}
      </List>
      <Box sx={{ mt: 3, zIndex: 1 }}>
        <Typography variant="body1" gutterBottom>
          Agents Created: {agentsCreated}
          {agentLimit ? ` / ${agentLimit}` : ''}
        </Typography>
        {agentLimit && (
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{ height: 10, borderRadius: 5 }}
          />
        )}
      </Box>
      {agentLimit && progress >= 80 && (
        <Button
          variant="contained"
          color="secondary"
          size="medium"
          startIcon={<UpgradeIcon />}
          sx={{ mt: 3, zIndex: 1 }}
          onClick={() => navigate('/upgrade')}
        >
          Upgrade Your Plan
        </Button>
      )}
    </PlanContainer>
  );
};

export default UserPlanDisplay;
