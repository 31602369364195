// src/components/AuthContext.tsx

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  ReactNode,
} from 'react';
import {
  decodeJWT,
  setToken,
  setRefreshToken,
  removeToken,
  removeRefreshToken,
  getRefreshToken,
} from '@/utils/jwtUtils';
import {
  loginUser,
  logoutUser,
  microsoftLogin,
  refreshToken as apiRefreshToken,
  checkAuthStatus as apiCheckAuthStatus,
} from '@/api/auth';
import { useMsal } from '@azure/msal-react';
import {
  User,
  FullAuthResponse,
} from '@/types/AgentTypes';

interface TwoFactorAuthResponse {
  twoFactorRequired: true;
  email: string;
  rememberMe?: boolean;
}

type AuthResponseType = FullAuthResponse | TwoFactorAuthResponse;

interface AuthContextType {
  isAuthenticated: boolean;
  setAuthenticated: (auth: boolean) => void;
  userId: string | null;
  setUserId: (id: string | null) => void;
  user: User | null;
  setUser: (user: User | null) => void;
  logout: () => Promise<void>;
  login: (email: string, password: string, rememberMe: boolean) => Promise<void>;
  setAuthenticatedUser: (token: string, userObj: User, refreshToken: string) => void;
  loginWithRedirect: () => Promise<void>;
  checkAuthStatus: () => Promise<void>;
  isLoading: boolean;
  isAdmin: boolean;
  refreshToken: () => Promise<boolean>;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const MAX_REFRESH_ATTEMPTS = 3;

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
  const [userId, setUserId] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [refreshAttempts, setRefreshAttempts] = useState<number>(0);
  const { instance } = useMsal();

  // Memoized logout function
  const logout = useCallback(async () => {
    try {
      await logoutUser();
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      removeToken();
      removeRefreshToken();
      localStorage.removeItem('rememberMe');
      setAuthenticated(false);
      setUserId(null);
      setUser(null);
      setIsAdmin(false);
      setRefreshAttempts(0);
      // Ensure instance.logoutPopup is called only if instance is available
      if (instance) {
        await instance.logoutPopup();
      }
      window.location.href = '/login';
    }
  }, [instance]);

  // Memoized refreshTokenFunction
  const refreshTokenFunction = useCallback(async (): Promise<boolean> => {
    if (refreshAttempts >= MAX_REFRESH_ATTEMPTS) {
      console.error('Max refresh attempts reached');
      await logout();
      return false;
    }

    const storedRefreshToken = getRefreshToken();
    if (!storedRefreshToken) {
      console.error('No refresh token available');
      await logout();
      return false;
    }

    try {
      const response = await apiRefreshToken(storedRefreshToken);
      if ('token' in response && typeof response.token === 'string') {
        setToken(response.token);
        if ('refreshToken' in response && typeof response.refreshToken === 'string') {
          setRefreshToken(response.refreshToken);
        }
        setAuthenticated(true);
        const decodedToken = decodeJWT(response.token);
        setUserId(decodedToken?.userId || null);
        setRefreshAttempts(0);
        return true;
      } else {
        throw new Error('Invalid refresh token response');
      }
    } catch (error) {
      console.error('Error refreshing token:', error);
      setRefreshAttempts((prev) => prev + 1);
      return false;
    }
  }, [logout, refreshAttempts]);

  // Set authenticated user function
  // Set authenticated user function
  const setAuthenticatedUser = useCallback(
    (token: string, userObj: User, refreshToken: string) => {
      setToken(token); // Stores the token in storage (e.g., localStorage or cookies)
      setRefreshToken(refreshToken); // Stores the refresh token
      setAuthenticated(true);
      setUserId(userObj.id);
      setUser(userObj);
      setIsAdmin(userObj.isAdmin);
      setRefreshAttempts(0);

      if (localStorage.getItem('rememberMe') === 'true') {
        localStorage.setItem('rememberMe', 'true');
      } else {
        localStorage.removeItem('rememberMe');
      }
    },
    []
  );

  // Login function
  const login = useCallback(
    async (email: string, password: string, rememberMe: boolean) => {
      try {
        const response = await loginUser({ email, password, rememberMe });

        if (
          'token' in response &&
          'user' in response &&
          'refreshToken' in response
        ) {
          setAuthenticatedUser(
            response.token,
            response.user,
            response.refreshToken
          );
        } else if ('twoFactorRequired' in response && response.twoFactorRequired) {
          console.log('Two-factor authentication required');
          // Handle 2FA flow here
        }
      } catch (error) {
        console.error('Login error:', error);
        throw error;
      }
    },
    [setAuthenticatedUser]
  );

  // Microsoft login with redirect
  const loginWithRedirect = useCallback(async () => {
    try {
      const result = await instance.loginPopup({
        scopes: ['user.read'],
        prompt: 'select_account',
      });

      if (result && result.accessToken) {
        const response: AuthResponseType = await microsoftLogin(result.accessToken);

        if (
          'token' in response &&
          'user' in response &&
          'refreshToken' in response
        ) {
          setAuthenticatedUser(
            response.token,
            response.user,
            response.refreshToken
          );
        }
      }
    } catch (error) {
      console.error('Error during Microsoft login:', error);
      throw error;
    }
  }, [instance, setAuthenticatedUser]);

  // Memoized checkAuthStatus function
  const checkAuthStatus = useCallback(async () => {
    try {
      const authStatus = await apiCheckAuthStatus();
      setAuthenticated(authStatus.isAuthenticated);
      if (authStatus.isAuthenticated && authStatus.user) {
        setUserId(authStatus.user.id);
        setUser(authStatus.user);
        setIsAdmin(authStatus.user.isAdmin);
      } else {
        setUserId(null);
        setUser(null);
        setIsAdmin(false);
      }
    } catch (error) {
      console.error('Error checking auth status:', error);
      const refreshSuccessful = await refreshTokenFunction();
      if (!refreshSuccessful) {
        setAuthenticated(false);
        setUserId(null);
        setUser(null);
        setIsAdmin(false);
      }
    }
  }, [refreshTokenFunction]);

  // Memoized initializeAuth function
  const initializeAuth = useCallback(async () => {
    await checkAuthStatus();
    setIsLoading(false);
  }, [checkAuthStatus]);

  // Initialize authentication on component mount
  useEffect(() => {
    initializeAuth();
  }, [initializeAuth]);

  const contextValue: AuthContextType = {
    isAuthenticated,
    setAuthenticated,
    userId,
    setUserId,
    user,
    setUser,
    isAdmin,
    logout,
    login,
    setAuthenticatedUser,
    loginWithRedirect,
    checkAuthStatus,
    isLoading,
    refreshToken: refreshTokenFunction,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

// Custom hook to use the authentication context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};