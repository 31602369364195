import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SidebarList from './SidebarList';
import { updateAssistantProfileImage, deleteAssistant } from '@/api/assistant';
import { fetchUserAssistants } from '@/api/user';
import { Assistant } from '@/types/AgentTypes';

export const SidebarListWrapper: React.FC = () => {
  const [assistants, setAssistants] = useState<Assistant[]>([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState<string | null>(null);

  // **Move all hooks above any conditional returns to comply with React Hooks rules**
  
  useEffect(() => {
    const loadAssistants = async () => {
      try {
        const userAssistants = await fetchUserAssistants();
        setAssistants(userAssistants);
      } catch (error) {
        console.error('Error fetching assistants:', error);
        setError('Failed to load assistants.');
      }
    };
    loadAssistants();
  }, []);

  useEffect(() => {
    const handleAssistantProfileUpdated = (event: Event) => {
      const customEvent = event as CustomEvent<{ assistantId: string; profileImageUrl: string }>;
      const { assistantId, profileImageUrl } = customEvent.detail;
      setAssistants((prevAssistants) =>
        prevAssistants.map((assistant) =>
          assistant.assistantId === assistantId
            ? { ...assistant, profileImageUrl: profileImageUrl }
            : assistant
        )
      );
    };

    window.addEventListener('assistantProfileUpdated', handleAssistantProfileUpdated as EventListener);

    return () => {
      window.removeEventListener('assistantProfileUpdated', handleAssistantProfileUpdated as EventListener);
    };
  }, []);

  useEffect(() => {
    const handleAssistantDeleted = (event: Event) => {
      const customEvent = event as CustomEvent<string>;
      const deletedAssistantId = customEvent.detail;
      removeAssistant(deletedAssistantId).catch((err) => {
        console.error('Failed to remove assistant:', err);
        setError('Failed to remove assistant.');
      });
    };

    window.addEventListener('assistantDeleted', handleAssistantDeleted as EventListener);

    return () => {
      window.removeEventListener('assistantDeleted', handleAssistantDeleted as EventListener);
    };
  }, []);

  // **Conditional Rendering after hooks**
  
  // Hide SidebarListWrapper when on the agent builder route
  if (location.pathname.startsWith('/agent-builder')) {
    return null;
  }

  const handleAssistantClick = (assistantId: string) => {
    navigate(`/agent/${assistantId}`);
  };

  const handleInitialAssistantClick = (assistantId: string) => {
    if (location.pathname === '/') {
      navigate(`/agent/${assistantId}`);
    }
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleImageUpload = async (assistantId: string, file: File) => {
    try {
      const updatedAssistant = await updateAssistantProfileImage(assistantId, file);
      setAssistants((prevAssistants) =>
        prevAssistants.map((assistant) =>
          assistant.assistantId === assistantId
            ? { ...assistant, profileImageUrl: updatedAssistant.profile_image_url }
            : assistant
        )
      );
    } catch (error) {
      console.error('Error updating profile image:', error);
      setError('Failed to update profile image.');
    }
  };

  const removeAssistant = async (assistantId: string): Promise<void> => {
    try {
      await deleteAssistant(assistantId);
      setAssistants((prevAssistants) =>
        prevAssistants.filter((assistant) => assistant.assistantId !== assistantId)
      );
    } catch (error) {
      console.error('Failed to remove assistant:', error);
      setError('Failed to remove assistant.');
      throw error; // Re-throw to be caught in event handler
    }
  };

  if (error) {
    return <div style={{ color: 'red', padding: '10px' }}>{error}</div>;
  }

  return (
    <SidebarList
      onAssistantClick={handleAssistantClick}
      assistants={assistants}
      onInitialAssistantClick={handleInitialAssistantClick}
      isCollapsed={isCollapsed}
      onToggleCollapse={toggleCollapse}
      onImageUpload={handleImageUpload}
      removeAssistant={removeAssistant}
    />
  );
};

export default SidebarListWrapper;