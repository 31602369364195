// src/components/agent_builder/WebhookPreview.tsx

import React, { useState, useEffect } from 'react';
import { Typography, Paper, Button } from '@mui/material';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { motion } from 'framer-motion';
import { AgentData } from '@/types/AgentTypes';
import { sendAgentWebhook } from '@/api/assistant';

interface WebhookPreviewProps {
  agentData: AgentData;
}

const WebhookPreview: React.FC<WebhookPreviewProps> = ({ agentData }) => {
  const [webhookPayload, setWebhookPayload] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [sendResult, setSendResult] = useState<string | null>(null);

  useEffect(() => {
    const formattedData = {
      assistant_id: agentData.assistantId,
      name: agentData.name || '',
      general_knowledge: agentData.generalKnowledge || '',
      skill: agentData.skills || [],
      personality: agentData.personality || {},
      highlight: agentData.highlight || '',
      voiceid: agentData.voiceId || '',
      gender: agentData.gender || '',
    };

    const payload = JSON.stringify(formattedData, null, 2);
    setWebhookPayload(payload);
  }, [agentData]);

  const handleSendWebhook = async () => {
    setIsSending(true);
    setSendResult(null);
    try {
      if (!agentData.assistantId) {
        throw new Error('Assistant ID is required');
      }

      const result = await sendAgentWebhook(agentData as AgentData & { assistantId: string });
      setSendResult(`Webhook sent successfully: ${result}`);
    } catch (error) {
      setSendResult(
        `Error sending webhook: ${
          error instanceof Error ? error.message : String(error)
        }`
      );
    } finally {
      setIsSending(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Typography variant="h5" gutterBottom>
        Webhook Preview
      </Typography>
      <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
        <SyntaxHighlighter language="json" style={tomorrow}>
          {webhookPayload}
        </SyntaxHighlighter>
      </Paper>
      <Button
        variant="contained"
        onClick={handleSendWebhook}
        disabled={isSending || !agentData.assistantId}
      >
        {isSending ? 'Sending...' : 'Send Webhook'}
      </Button>
      {sendResult && (
        <Typography
          sx={{
            mt: 2,
            color: sendResult.includes('Error') ? 'error.main' : 'success.main',
          }}
        >
          {sendResult}
        </Typography>
      )}
    </motion.div>
  );
};

export default WebhookPreview;