// src/api/client.ts

import api from './api';
import { handleError } from '../utils/errors';
import {
  ClientData,
  User,
  UserPlan,
  DmsApiOption,
  AccountResponse, // Ensure AccountResponse is imported
} from '@/types/AgentTypes';
import { toCamelCase } from '../utils/caseConverter';

// Define additional interfaces for request and response payloads

/**
 * Data required to create a new customer.
 */
interface CreateCustomerData {
  name: string;
  email: string;
  // Add other necessary fields here
}

/**
 * Data required to create a new user.
 */
interface CreateUserData {
  name: string;
  email: string;
  assistantId: string | null;
  accountId: number;
}

/**
 * Data structure returned from creating a user.
 */
type CreateUserResponse = User;

/**
 * Create Customer
 *
 * Creates a new customer by first creating an account and then creating a user associated with that account.
 *
 * @param customerData - The data required to create a new customer.
 * @returns A promise that resolves to the created User object.
 */
export const createCustomer = async (
  customerData: CreateCustomerData
): Promise<User> => {
  try {
    // Create a new account
    const accountResponse = await api.post<AccountResponse>('/api/accounts', customerData);
    const accountResult = toCamelCase(accountResponse.data) as AccountResponse; // Type assertion added

    // Prepare user data
    const userData: CreateUserData = {
      name: customerData.name,
      email: customerData.email,
      assistantId: null,
      accountId: accountResult.accountId,
    };

    // Create a new user associated with the account
    const userResponse = await api.post<CreateUserResponse>('/api/users', userData);
    return toCamelCase(userResponse.data) as User;
  } catch (error) {
    handleError(error, 'Failed to create customer');
    throw error; // Ensures the function adheres to its return type
  }
};

/**
 * Get Client by ID
 *
 * Retrieves client data based on the provided client ID.
 *
 * @param id - The unique identifier of the client.
 * @returns A promise that resolves to the ClientData object.
 */
export const getClientById = async (id: number): Promise<ClientData> => {
  try {
    const response = await api.get<ClientData>(`/api/client/${id}`); // Adjusted endpoint
    return toCamelCase(response.data) as ClientData;
  } catch (error) {
    handleError(error, 'Error fetching client');
    throw error;
  }
};

/**
 * Create Client
 *
 * Creates a new client with the provided client data.
 *
 * @param clientData - Partial data required to create a new client.
 * @returns A promise that resolves to the created ClientData object.
 */
export const createClient = async (
  clientData: Partial<ClientData>
): Promise<ClientData> => {
  try {
    const response = await api.post<ClientData>('/api/client/create', clientData); // Adjusted endpoint
    return toCamelCase(response.data) as ClientData;
  } catch (error) {
    handleError(error, 'Error creating client');
    throw error;
  }
};

/**
 * Search Clients
 *
 * Searches for clients based on a query string.
 *
 * @param query - The search query string.
 * @returns A promise that resolves to an array of ClientData objects.
 */
export const searchClients = async (query: string): Promise<ClientData[]> => {
  try {
    const response = await api.get<ClientData[]>('/api/client/search', { params: { query } }); // Adjusted endpoint
    return toCamelCase(response.data) as ClientData[];
  } catch (error) {
    handleError(error, 'Error searching clients');
    throw error;
  }
};

/**
 * Fetch Plans
 *
 * Retrieves available subscription plans.
 *
 * @returns A promise that resolves to an array of UserPlan objects.
 */
export const fetchPlans = async (): Promise<UserPlan[]> => {
  try {
    const response = await api.get<UserPlan[]>('/api/newclient/plans');
    return toCamelCase(response.data) as UserPlan[];
  } catch (error) {
    handleError(error, 'Failed to fetch plans');
    throw error;
  }
};

/**
 * Fetch DMS API Options
 *
 * Retrieves available DMS API options.
 *
 * @returns A promise that resolves to an array of DmsApiOption objects.
 */
export const fetchDmsApiOptions = async (): Promise<DmsApiOption[]> => {
  try {
    const response = await api.get<DmsApiOption[]>('/api/newclient/dms-api-options');
    return toCamelCase(response.data) as DmsApiOption[];
  } catch (error) {
    handleError(error, 'Failed to fetch DMS API options');
    throw error;
  }
};
