// src/components/agent_builder_basic/SkillBuilderBasic.tsx

import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Paper, Chip, TextField, Box, Autocomplete, Button } from '@mui/material';
import { AgentData, Skill } from '@/types/AgentTypes';
import { getSkillRecommendations } from '@/services/aiRecommendationService';
import Lottie from 'lottie-react';
import loadingAnimation from '../../assets/loading-animation-skills.json';

interface SkillBuilderBasicProps {
  agentData: AgentData;
  onChange: (newData: Partial<AgentData>) => void;
}

const SkillBuilderBasic: React.FC<SkillBuilderBasicProps> = ({ agentData, onChange }) => {
  const [skills, setSkills] = useState<Skill[]>(agentData.skills || []);
  const [newSkill, setNewSkill] = useState<Skill | null>(null);
  const [recommendations, setRecommendations] = useState<Skill[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchRecommendations = useCallback(async () => {
    if (!agentData.role) return;
    try {
      setLoading(true);
      const recs = await getSkillRecommendations(agentData.role, 'Automotive');
      setRecommendations(
        recs.map((rec, index) => ({
          id: `rec-${Date.now()}-${index}`,
          name: rec.skill,
          category: rec.category,
          scenario: rec.scenario,
        }))
      );
    } catch (error) {
      console.error('Error fetching recommendations:', error);
    } finally {
      setLoading(false);
    }
  }, [agentData.role]);

  useEffect(() => {
    fetchRecommendations();
  }, [fetchRecommendations]);

  const handleAddSkill = (skillToAdd: Skill) => {
    if (skills.length >= 6) {
      alert('You can only add up to 6 skills.');
      return;
    }
    setSkills((prevSkills) => {
      const updatedSkills = [...prevSkills, { ...skillToAdd, id: `skill-${Date.now()}` }];
      onChange({ skills: updatedSkills });
      return updatedSkills;
    });
    setNewSkill(null);

    setRecommendations((prevRecommendations) =>
      prevRecommendations.filter((skill) => skill.id !== skillToAdd.id)
    );
  };

  const handleDeleteSkill = (skillId: string) => {
    setSkills((prevSkills) => {
      const updatedSkills = prevSkills.filter((skill) => skill.id !== skillId);
      onChange({ skills: updatedSkills });
      return updatedSkills;
    });
  };

  const handleRefreshRecommendations = () => {
    fetchRecommendations();
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Skills (Max 6)
      </Typography>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
          <Lottie animationData={loadingAnimation} loop style={{ height: 200, width: 200 }} />
        </Box>
      ) : (
        <>
          <Autocomplete
            value={newSkill}
            onChange={(_, value) => {
              if (value && typeof value !== 'string') {
                handleAddSkill(value);
              }
            }}
            options={recommendations}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Add a new skill"
                variant="outlined"
                margin="normal"
              />
            )}
          />
          {recommendations.length === 0 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2">No more recommended skills.</Typography>
              <Button variant="outlined" onClick={handleRefreshRecommendations}>
                Refresh Skills
              </Button>
            </Box>
          )}
        </>
      )}

      <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
        {skills.map((skill) => (
          <Chip
            key={skill.id}
            label={skill.name}
            onDelete={() => handleDeleteSkill(skill.id)}
            sx={{ m: 0.5 }}
          />
        ))}
      </Paper>
    </Box>
  );
};

export default SkillBuilderBasic;