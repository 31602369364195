// src/components/old_code/XtimeComponent.tsx

import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Snackbar,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createXTimeTool, assignToolToAssistant } from '@/api/assistant';
import { fetchUserAssistants } from '@/api/user';
import { ToolConfig, XTimeToolResponse, Assistant as AgentAssistant } from '@/types/AgentTypes';

interface IntegrationConfig extends ToolConfig {
  dealerCode: string;
  defaultVin: string;
  defaultOpcode: string;
}

const XtimeComponent: React.FC = () => {
  const [config, setConfig] = useState<IntegrationConfig>({
    dealerCode: '',
    defaultVin: '',
    defaultOpcode: '',
  });
  const [assistants, setAssistants] = useState<AgentAssistant[]>([]);
  const [selectedAssistants, setSelectedAssistants] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [toolId, setToolId] = useState<string | null>(null);

  useEffect(() => {
    const loadAssistants = async () => {
      try {
        const fetchedAssistants = await fetchUserAssistants();
        setAssistants(fetchedAssistants);
      } catch (error) {
        console.error('Error fetching assistants:', error);
        setSnackbarMessage('Failed to load assistants. You can still create the integration.');
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    loadAssistants();
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setConfig((prevConfig) => ({ ...prevConfig, [name]: value }));
  };

  const handleAssistantToggle = (assistantId: string) => {
    setSelectedAssistants((prev) =>
      prev.includes(assistantId) ? prev.filter((id) => id !== assistantId) : [...prev, assistantId]
    );
  };

  const handleCreateIntegration = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const response: XTimeToolResponse = await createXTimeTool(
        config.dealerCode,
        config.defaultVin,
        config.defaultOpcode
      );

      if (response.success && response.data.toolId) {
        setToolId(response.data.toolId);
        setSnackbarMessage('xTime integration created successfully! You can now assign it to assistants.');
      } else {
        throw new Error('Failed to create xTime integration.');
      }
    } catch (error) {
      console.error('Error creating xTime integration:', error);
      setSnackbarMessage('Error creating xTime integration. Please try again.');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleAssignIntegration = async () => {
    if (!toolId) {
      setSnackbarMessage('Please create the integration first.');
      setSnackbarOpen(true);
      return;
    }

    if (selectedAssistants.length === 0) {
      setSnackbarMessage('Please select at least one assistant.');
      setSnackbarOpen(true);
      return;
    }

    try {
      await Promise.all(
        selectedAssistants.map((assistantId) =>
          assignToolToAssistant(assistantId, toolId, config)
        )
      );

      setSnackbarMessage('xTime integration assigned successfully!');
    } catch (error) {
      console.error('Error assigning xTime integration:', error);
      setSnackbarMessage('Error assigning xTime integration. Please try again.');
    } finally {
      setSnackbarOpen(true);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        xTime Integration Configuration
      </Typography>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Available Time Slots Configuration</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form onSubmit={handleCreateIntegration}>
            <TextField
              fullWidth
              margin="normal"
              label="Dealer Code"
              name="dealerCode"
              value={config.dealerCode}
              onChange={handleInputChange}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Default VIN (Optional)"
              name="defaultVin"
              value={config.defaultVin}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Default Opcode (Optional)"
              name="defaultOpcode"
              value={config.defaultOpcode}
              onChange={handleInputChange}
            />
            <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
              Create Integration
            </Button>
          </form>
        </AccordionDetails>
      </Accordion>

      <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
        Assign Integration to Assistants:
      </Typography>
      {assistants.length > 0 ? (
        <FormGroup>
          {assistants.map((assistant) => (
            <FormControlLabel
              key={assistant.assistantId}
              control={
                <Checkbox
                  checked={selectedAssistants.includes(assistant.assistantId)}
                  onChange={() => handleAssistantToggle(assistant.assistantId)}
                />
              }
              label={`${assistant.name} (${assistant.phoneNumbers.join(', ')})`}
            />
          ))}
        </FormGroup>
      ) : (
        <Typography color="text.secondary">
          No assistants available. You can still create the integration and assign it later.
        </Typography>
      )}
      <Button
        onClick={handleAssignIntegration}
        variant="contained"
        color="secondary"
        sx={{ mt: 2 }}
        disabled={!toolId || selectedAssistants.length === 0}
      >
        Assign Integration to Selected Assistants
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default XtimeComponent;