import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Grid,
  Avatar,
  CircularProgress,
  Box,
} from '@mui/material';
import { motion } from 'framer-motion';
import { AgentTemplate } from '@/types/AgentTypes';
import { fetchAgentTemplates } from '@/api/assistant';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CodeIcon from '@mui/icons-material/Code';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import HomeIcon from '@mui/icons-material/Home';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

// Import default avatar image
import defaultAvatar from 'src/assets/images/divergent-agents-logo.png';

interface TemplateSelectionProps {
  onSelect: (template: AgentTemplate) => void;
}

// Map roles to icons (used when profileImageUrl is not available)
const iconMap: { [key: string]: React.ReactElement } = {
  'Customer Service': <SupportAgentIcon />,
  Sales: <ShoppingCartIcon />,
  'Technical Support': <CodeIcon />,
  'Food Service': <RestaurantIcon />,
  'Real Estate': <HomeIcon />,
  'Financial Services': <AttachMoneyIcon />,
  'Service Agent': <DirectionsCarIcon />,
  'Sales Agent': <DirectionsCarIcon />,
  'BDC Agent': <DirectionsCarIcon />,
};

const TemplateSelection: React.FC<TemplateSelectionProps> = ({ onSelect }) => {
  // State to hold fetched templates
  const [fetchedTemplates, setFetchedTemplates] = useState<AgentTemplate[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadTemplates = async () => {
      try {
        // Fetch templates from the backend API
        const apiTemplates = await fetchAgentTemplates();

        // Validate the fetched data
        if (!Array.isArray(apiTemplates)) {
          throw new Error('Invalid data format received from the server.');
        }

        setFetchedTemplates(apiTemplates);
      } catch (err) {
        console.error('Error fetching templates:', err);
        setError('Failed to load templates. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    loadTemplates();
  }, []);

  const handleSelectTemplate = (template: AgentTemplate) => {
    onSelect(template);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 400,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 400,
        }}
      >
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={3}>
      {fetchedTemplates.map((template: AgentTemplate, index: number) => (
        <Grid item xs={12} sm={6} md={4} key={template.id}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Avatar
                  src={template.profileImageUrl || defaultAvatar}
                  alt={`${template.name} Avatar`}
                  sx={{ width: 56, height: 56, mb: 2 }}
                >
                  {/* If profileImageUrl fails to load, display the icon based on role */}
                  {!template.profileImageUrl && iconMap[template.role] ? (
                    iconMap[template.role]
                  ) : null}
                </Avatar>
                <Typography variant="h5" component="div" gutterBottom>
                  {template.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" paragraph>
                  {template.highlight}
                </Typography>
                {template.skills && template.skills.length > 0 && (
                  <>
                    <Typography variant="subtitle2" color="primary" gutterBottom>
                      Key Skills:
                    </Typography>
                    <ul>
                      {template.skills.map((skillItem: { id?: string; name: string }, idx: number) => (
                        <li key={skillItem.id || idx}>
                          <Typography variant="body2">{skillItem.name}</Typography>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  onClick={() => handleSelectTemplate(template)}
                  variant="contained"
                  fullWidth
                  sx={{
                    mt: 'auto',
                    transition: 'all 0.3s',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: 3,
                    },
                  }}
                >
                  Select Template
                </Button>
              </CardActions>
            </Card>
          </motion.div>
        </Grid>
      ))}
    </Grid>
  );
};

export default TemplateSelection;