import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  Chip,
  Paper,
  Grid,
  FormControl,
  InputLabel,
} from '@mui/material';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { AgentData, UserPlan, DmsApiOption } from '@/types/AgentTypes';
import { fetchPlans, fetchDmsApiOptions } from '../../api/client';
import { SelectChangeEvent } from '@mui/material/Select';

const AnimatedPaper = motion(Paper);
const AnimatedTextField = motion(TextField);
const AnimatedFormControlLabel = motion(FormControlLabel);
const AnimatedChip = motion(Chip);

const StyledPaper = styled(AnimatedPaper)(({ theme }) => ({
  padding: theme.spacing(3),
  background: theme.palette.background.default,
  boxShadow: '0 8px 32px rgba(31, 38, 135, 0.37)',
  backdropFilter: 'blur(4px)',
  borderRadius: '10px',
  border: '1px solid rgba(255, 255, 255, 0.18)',
}));

interface BasicInfoProps {
  agentData: AgentData;
  onChange: (newData: Partial<AgentData>) => void;
}

const BasicInfo: React.FC<BasicInfoProps> = ({ agentData, onChange }) => {
  const [plans, setPlans] = useState<UserPlan[]>([]);
  const [dmsApiOptions, setDmsApiOptions] = useState<DmsApiOption[]>([]);

  useEffect(() => {
    const loadData = async () => {
      const plansData = await fetchPlans();
      const dmsApiOptionsData = await fetchDmsApiOptions();
      setPlans(plansData);
      setDmsApiOptions(dmsApiOptionsData);
    };
    loadData();
  }, []);

  const handleChange = (
    event:
      | React.ChangeEvent<
          HTMLInputElement | { name?: string; value: unknown }
        >
      | SelectChangeEvent<string | number>
  ) => {
    const { name, value } = event.target;
    onChange({
      [name as keyof AgentData]:
        (event.target as HTMLInputElement).type === 'checkbox'
          ? (event.target as HTMLInputElement).checked
          : value,
    });
  };

  const handleDmsApiChange = (event: SelectChangeEvent<string[]>) => {
    onChange({ dmsApis: event.target.value as string[] });
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <StyledPaper elevation={3}>
        <Typography
          variant="h5"
          gutterBottom
          component={motion.h5}
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          Agent Basic Information
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AnimatedTextField
              fullWidth
              label="Agent Name"
              name="name"
              value={agentData.name || ''}
              onChange={handleChange}
              variant="outlined"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            />
          </Grid>
          <Grid item xs={12}>
            <AnimatedTextField
              fullWidth
              label="General Knowledge"
              name="generalKnowledge"
              value={agentData.generalKnowledge || ''}
              onChange={handleChange}
              variant="outlined"
              multiline
              rows={4}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            />
          </Grid>
          <Grid item xs={12}>
            <AnimatedTextField
              fullWidth
              label="Highlights"
              name="highlight"
              value={agentData.highlight || ''}
              onChange={handleChange}
              variant="outlined"
              multiline
              rows={4}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.7 }}
            />
          </Grid>
          <Grid item xs={12}>
            <AnimatedFormControlLabel
              control={
                <Switch
                  checked={agentData.dashboardAccess || false}
                  onChange={handleChange}
                  name="dashboardAccess"
                />
              }
              label="Dashboard Access"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 1.1 }}
            />
          </Grid>
          <Grid item xs={12}>
            <AnimatedFormControlLabel
              control={
                <Switch
                  checked={agentData.isAdmin || false}
                  onChange={handleChange}
                  name="isAdmin"
                />
              }
              label="Admin Access"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 1.2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="plan-select-label">Plan</InputLabel>
              <Select
                labelId="plan-select-label"
                name="planId"
                value={agentData.planId || ''}
                onChange={handleChange}
                label="Plan"
              >
                {plans.map((plan: UserPlan) => (
                  <MenuItem key={plan.id} value={plan.id}>
                    {plan.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="dms-api-select-label">DMS APIs</InputLabel>
              <Select
                labelId="dms-api-select-label"
                multiple
                name="dmsApis"
                value={agentData.dmsApis || []}
                onChange={handleDmsApiChange}
                label="DMS APIs"
                renderValue={(selected) => (
                  <Box
                    sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                  >
                    {(selected as string[]).map((value) => (
                      <AnimatedChip
                        key={value}
                        label={value}
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        exit={{ scale: 0 }}
                      />
                    ))}
                  </Box>
                )}
              >
                {dmsApiOptions.map((option: DmsApiOption) => (
                  <MenuItem key={option.id} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </StyledPaper>
    </motion.div>
  );
};

export default BasicInfo;