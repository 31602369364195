import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { 
  List, 
  ListItem, 
  ListItemButton, 
  ListItemIcon, 
  ListItemText, 
  Box, 
  Typography,
  Drawer,
  Avatar,
  IconButton,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import defaultAvatar from 'src/assets/images/divergent-agents-logo.png';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/CloudUpload';
import { Assistant } from '@/types/AgentTypes';

const drawerWidth = 240;
const collapsedDrawerWidth = 60;

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'isCollapsed',
})<{ isCollapsed: boolean }>(({ theme, isCollapsed }) => ({
  width: isCollapsed ? collapsedDrawerWidth : drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: isCollapsed ? collapsedDrawerWidth : drawerWidth,
    boxSizing: 'border-box',
    top: 64,
    height: 'calc(100% - 64px)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

interface SidebarListProps {
  assistants: Assistant[];
  onAssistantClick: (assistantId: string) => void;
  onInitialAssistantClick: (assistantId: string) => void;
  isCollapsed: boolean;
  onToggleCollapse: () => void;
  onImageUpload: (assistantId: string, file: File) => Promise<void>;
  removeAssistant: (assistantId: string) => Promise<void>;
}

const SidebarList: React.FC<SidebarListProps> = ({ 
  assistants, 
  onAssistantClick, 
  onInitialAssistantClick, 
  isCollapsed, 
  onToggleCollapse,
  onImageUpload,
  removeAssistant,
}) => {
  const [error, setError] = useState<string | null>(null);
  const [initialSelectionMade, setInitialSelectionMade] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    if (assistants.length > 0 && !initialSelectionMade && location.pathname === '/') {
      onInitialAssistantClick(assistants[0].assistantId);
      setInitialSelectionMade(true);
    }
  }, [assistants, onInitialAssistantClick, initialSelectionMade, location.pathname]);

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <StyledDrawer variant="permanent" anchor="left" isCollapsed={isCollapsed}>
      <Box sx={{ overflow: 'auto' }}>
        <IconButton onClick={onToggleCollapse} sx={{ m: 1 }}>
          {isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
        <List>
          {assistants.map((assistant) => (
            <ListItem key={assistant.assistantId} disablePadding>
              <ListItemButton onClick={() => onAssistantClick(assistant.assistantId)}>
                <ListItemIcon>
                  <Avatar
                    src={assistant.profileImageUrl || defaultAvatar}
                    alt={`Avatar for ${assistant.name}`}
                  />
                </ListItemIcon>
                {!isCollapsed && <ListItemText primary={assistant.name} />}
              </ListItemButton>
              {!isCollapsed && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {/* Upload Button */}
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id={`upload-button-${assistant.assistantId}`}
                    type="file"
                    onChange={async (event) => {
                      if (event.target.files && event.target.files[0]) {
                        try {
                          await onImageUpload(assistant.assistantId, event.target.files[0]);
                        } catch (err) {
                          console.error('Failed to upload image:', err);
                          setError('Failed to upload image');
                        }
                      }
                    }}
                  />
                  <label htmlFor={`upload-button-${assistant.assistantId}`}>
                    <Tooltip title="Upload Image">
                      <IconButton component="span">
                        <UploadIcon />
                      </IconButton>
                    </Tooltip>
                  </label>

                  {/* Remove Assistant Button */}
                  <Tooltip title="Remove Assistant">
                    <IconButton
                      onClick={async () => {
                        try {
                          await removeAssistant(assistant.assistantId);
                        } catch (err) {
                          console.error('Failed to remove assistant:', err);
                          setError('Failed to remove assistant');
                        }
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </ListItem>
          ))}
        </List>
      </Box>
    </StyledDrawer>
  );
};

export default SidebarList;