import api from './api';
import { handleError } from '../utils/errors';
import { 
  UserMetrics, 
  AgentMetrics, 
  ActivityLog
} from '@/types/AgentTypes'; 
import { 
  CryptoCurrency, 
  BillingMetrics, 
  BillingInfo, 
  BillingData, 
  Invoice, 
  PaymentIntent, 
  CreateCryptoChargeRequest,
  CreateCryptoChargeResponse, // Import the CreateCryptoChargeResponse type
} from '@/types/BillingTypes'; 

/**
 * Fetches and stores metrics for a specific assistant.
 * 
 * @param {string} assistantId - The ID of the assistant.
 * @param {Partial<UserMetrics>} metricsData - The metrics data to be fetched and stored.
 * @returns {Promise<UserMetrics>} - The fetched and stored metrics.
 */
export const fetchAssistantMetrics = async (
  assistantId: string,
  metricsData: Partial<UserMetrics>
): Promise<UserMetrics> => {
  try {
    const response = await api.post<UserMetrics>(
      `/api/metrics/fetch?assistantId=${assistantId}`,
      metricsData
    );
    return response.data;
  } catch (error) {
    handleError(error, 'Error fetching assistant metrics');
    throw error;
  }
};

/**
 * Retrieves recent activity logs for a specific assistant.
 * 
 * @param {string} assistantId - The ID of the assistant.
 * @returns {Promise<ActivityLog[]>} - An array of recent activity logs.
 */
export const getRecentActivity = async (assistantId: string): Promise<ActivityLog[]> => {
  try {
    const response = await api.get<ActivityLog[]>(
      `/api/metrics/${assistantId}/recent-activity`
    );
    return response.data;
  } catch (error) {
    handleError(error, 'Error fetching recent activity');
    throw error;
  }
};

/**
 * Retrieves user-wide recent activity logs.
 * 
 * @returns {Promise<ActivityLog[]>} - An array of recent activity logs.
 */
export const getUserRecentActivity = async (): Promise<ActivityLog[]> => {
  try {
    const response = await api.get<ActivityLog[]>(
      `/api/metrics/recent-activity`
    );
    return response.data;
  } catch (error) {
    handleError(error, 'Error fetching user recent activity');
    throw error;
  }
};

// Fetch billing metrics
export const fetchBillingMetrics = async (rangeStart: string, rangeEnd: string): Promise<BillingMetrics> => {
  try {
    const response = await api.get<BillingMetrics>(`/api/billing/metrics`, {
      params: { rangeStart, rangeEnd },
    });
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch billing metrics');
    throw error; // Ensure the function always returns a value or throws
  }
};

// Get billing info for an account
export const getBillingInfo = async (accountId: string): Promise<BillingInfo> => {
  try {
    const response = await api.get<BillingInfo>(`/api/billing/accounts/${accountId}/billing`);
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to get billing info');
    throw error;
  }
};

// Update billing info for an account
export const updateBillingInfo = async (accountId: string, billingData: BillingData): Promise<BillingInfo> => {
  try {
    const response = await api.put<BillingInfo>(`/api/billing/accounts/${accountId}/billing`, billingData);
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to update billing info');
    throw error;
  }
};

// Get invoices for an account
export const getInvoices = async (accountId: string): Promise<Invoice[]> => {
  try {
    const response = await api.get<Invoice[]>(`/api/billing/accounts/${accountId}/invoices`);
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to get invoices');
    return [];
  }
};

// Create a payment intent
export const createPaymentIntent = async (
  amount: number,
  currency: string,
  paymentMethodTypes: string[] = ['card', 'us_bank_account']
): Promise<PaymentIntent> => {
  try {
    const response = await api.post<PaymentIntent>('/api/billing/create-payment-intent', {
      amount,
      currency,
      payment_method_types: paymentMethodTypes,
    });
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to create payment intent');
    throw error;
  }
};

// Fetch supported cryptocurrencies
export const fetchCryptoCurrencies = async (): Promise<CryptoCurrency[]> => {
  try {
    const response = await api.get<CryptoCurrency[]>('/api/billing/crypto-currencies');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch cryptocurrencies');
    return [];
  }
};

// Create a crypto charge
export const createCryptoCharge = async (
  amount: number,
  currency: string,
  selectedCrypto: string
): Promise<CreateCryptoChargeResponse> => { // Update the return type
  try {
    const requestData: CreateCryptoChargeRequest = {
      amount,
      currency,
      selectedCrypto,
    };
    const response = await api.post<CreateCryptoChargeResponse>('/api/billing/create-crypto-charge', requestData);
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to create crypto charge');
    throw error;
  }
};

// Update user metrics
export const updateUserMetrics = async (): Promise<void> => {
  try {
    await api.post('/api/billing/update-metrics');
  } catch (error) {
    handleError(error, 'Error updating user metrics');
    throw error;
  }
};

/**
 * Retrieves metrics for a specific assistant.
 * 
 * @param {string} assistantId - The ID of the assistant.
 * @returns {Promise<UserMetrics>} - The metrics data.
 */
export const fetchUserMetrics = async (assistantId: string): Promise<UserMetrics> => {
  try {
    const response = await api.get<UserMetrics>(`/api/metrics/${assistantId}`);
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch user metrics');
    throw error; // Ensure the error is propagated
  }
};

// Get agent metrics
export const getAgentMetrics = async (assistantId: string): Promise<AgentMetrics> => {
  try {
    const response = await api.get<AgentMetrics>(`/api/metrics/${assistantId}`);
    return response.data;
  } catch (error) {
    handleError(error, 'Error fetching agent metrics');
    throw error;
  }
};

// Fetch and store metrics
export const fetchAndStoreMetrics = async (
  assistantId: string
): Promise<{ message: string }> => {
  try {
    const response = await api.post<{ message: string }>(
      `/api/metrics/fetch`,
      {}, // Sending an empty body
      {
        params: { assistantId },
      }
    );
    return response.data;
  } catch (error) {
    handleError(error, 'Error fetching and storing metrics');
    throw error;
  }
};