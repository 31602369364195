// src/components/BillingPage/CryptoPaymentForm.tsx

import React, { useState, useEffect } from 'react';
import { Bitcoin, Loader2 } from 'lucide-react';
import { useTheme } from '../ThemeContext';
import { toast } from 'react-toastify';
import { createCryptoCharge, fetchCryptoCurrencies } from '@/api/billing';
import { CryptoCurrency, CreateCryptoChargeResponse } from '@/types/BillingTypes';

interface CryptoPaymentFormProps {
  amount: number;
  currency: string;
}

const CryptoPaymentForm: React.FC<CryptoPaymentFormProps> = ({
  amount,
  currency,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [cryptocurrencies, setCryptocurrencies] = useState<CryptoCurrency[]>([]);
  const [selectedCrypto, setSelectedCrypto] = useState<string>('');
  const { isDarkMode } = useTheme();

  useEffect(() => {
    // Fetch the list of supported cryptocurrencies from the backend
    const getCryptocurrencies = async () => {
      try {
        const cryptoList = await fetchCryptoCurrencies();
        setCryptocurrencies(cryptoList);
        if (cryptoList.length > 0) {
          setSelectedCrypto(cryptoList[0].code);
        }
      } catch (error: unknown) { // Changed 'any' to 'unknown'
        if (error instanceof Error) {
          toast.error(error.message);
        } else {
          toast.error('Failed to fetch cryptocurrencies.');
        }
      }
    };
    getCryptocurrencies();
  }, []);

  const handleCryptoPayment = async () => {
    if (!selectedCrypto) {
      toast.error('Please select a cryptocurrency.');
      return;
    }

    setIsLoading(true);

    try {
      const response: CreateCryptoChargeResponse = await createCryptoCharge(
        amount,
        currency,
        selectedCrypto
      );
      if (response && response.paymentUrl) {
        window.location.href = response.paymentUrl;
      } else {
        throw new Error('Failed to create crypto charge');
      }
    } catch (err: unknown) { // Already using 'unknown' and handling
      if (err instanceof Error) {
        toast.error(err.message);
      } else {
        toast.error('An unexpected error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={`p-6 max-w-md mx-auto ${
        isDarkMode ? 'bg-gray-800' : 'bg-white'
      } shadow-lg rounded-lg`}
    >
      <h3
        className={`text-2xl font-bold mb-4 text-center ${
          isDarkMode ? 'text-gray-100' : 'text-gray-800'
        }`}
      >
        Pay with Cryptocurrency
      </h3>
      <div className="flex items-center justify-center mb-6">
        <Bitcoin size={48} className="text-yellow-500 mr-2" />
        <p
          className={`text-xl font-semibold ${
            isDarkMode ? 'text-gray-200' : 'text-gray-700'
          }`}
        >
          {amount} {currency}
        </p>
      </div>

      <div className="mb-4">
        <label
          htmlFor="crypto-select"
          className={`block text-sm font-medium mb-2 ${
            isDarkMode ? 'text-gray-200' : 'text-gray-700'
          }`}
        >
          Preferred Cryptocurrency
        </label>
        <select
          id="crypto-select"
          value={selectedCrypto}
          onChange={(e) => setSelectedCrypto(e.target.value)}
          className={`w-full p-2 border rounded-md focus:outline-none focus:ring-2 ${
            isDarkMode
              ? 'bg-gray-700 border-gray-600 text-gray-200 focus:ring-blue-500'
              : 'bg-white border-gray-300 text-gray-700 focus:ring-blue-500'
          }`}
        >
          {cryptocurrencies.map((crypto) => (
            <option key={crypto.code} value={crypto.code}>
              {crypto.name} ({crypto.code})
            </option>
          ))}
        </select>
      </div>

      <button
        onClick={handleCryptoPayment}
        disabled={isLoading || !selectedCrypto}
        className={`w-full py-3 px-6 font-semibold rounded-full transition duration-300 ease-in-out transform ${
          isLoading || !selectedCrypto
            ? 'bg-gray-400 cursor-not-allowed text-white'
            : 'bg-gradient-to-r from-yellow-400 to-yellow-600 hover:from-yellow-500 hover:to-yellow-700 text-white hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
        }`}
      >
        {isLoading ? (
          <span className="flex items-center justify-center">
            <Loader2 className="animate-spin mr-2" />
            Processing...
          </span>
        ) : (
          'Pay Now'
        )}
      </button>
    </div>
  );
};

export default CryptoPaymentForm;
