// src/utils/caseConverter.ts

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PlainObject = { [key: string]: any };

/**
 * Converts object keys from snake_case to camelCase recursively.
 * @param obj The object to convert.
 * @returns The converted object with camelCase keys.
 */
export const toCamelCase = (obj: unknown): unknown => {
  if (Array.isArray(obj)) {
    return obj.map((v) => toCamelCase(v));
  } else if (obj !== null && typeof obj === 'object') {
    const result: PlainObject = {};
    for (const key of Object.keys(obj)) {
      const camelKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
      result[camelKey] = toCamelCase((obj as PlainObject)[key]);
    }
    return result;
  }
  return obj;
};
