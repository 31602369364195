// src/utils/makeVapiCall.ts

import api from '../api/api';
import { handleError } from './errors';

/**
 * Makes a VAPI call through the proxy with type safety.
 * @param endpoint - The VAPI endpoint to call.
 * @param data - The payload to send in the request.
 * @returns The response data typed as T.
 */
export const makeVapiCall = async <D, T>(endpoint: string, data: D): Promise<T> => {
  try {
    const response = await api.post<{ data: T }>('/proxy-vapi-call', { endpoint, data });
    return response.data.data;
  } catch (error) {
    handleError(error, 'Failed to make VAPI call');
    throw error; // Ensure the function throws an error to maintain the Promise's rejection
  }
};
