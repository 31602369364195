// src/components/BillingPage/PaymentForm.tsx

import React, { useState } from 'react';
import StripePaymentForm from './StripePaymentForm';
import CryptoPaymentForm from './CryptoPaymentForm';
import { useTheme } from '../ThemeContext';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';

const supportedCurrencies = ['USD', 'EUR', 'GBP', 'AUD', 'CAD', 'JPY']; // Extend as needed

const PaymentForm: React.FC = () => {
  const { isDarkMode } = useTheme();
  const [paymentMethod, setPaymentMethod] = useState<'stripe' | 'crypto'>('stripe');
  const [amount, setAmount] = useState<number>(100); // Default amount
  const [currency, setCurrency] = useState<string>('USD'); // Default currency
  const [errors, setErrors] = useState<{ amount?: string; currency?: string }>({});

  const validateInputs = (): boolean => {
    const newErrors: { amount?: string; currency?: string } = {};
    if (amount <= 0) {
      newErrors.amount = 'Amount must be greater than zero.';
    }
    if (!supportedCurrencies.includes(currency)) {
      newErrors.currency = 'Please select a valid currency.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePaymentMethodChange = (method: 'stripe' | 'crypto') => {
    if (validateInputs()) {
      setPaymentMethod(method);
    } else {
      toast.error('Please fix the errors before proceeding.');
    }
  };

  const PaymentButton: React.FC<{
    method: 'stripe' | 'crypto';
    label: string;
  }> = ({ method, label }) => (
    <button
      className={`px-6 py-3 font-semibold rounded-full focus:outline-none transition duration-300 ease-in-out transform ${
        paymentMethod === method
          ? isDarkMode
            ? 'bg-blue-600 text-white scale-105'
            : 'bg-blue-600 text-white scale-105'
          : isDarkMode
          ? 'bg-gray-700 text-gray-300 hover:bg-blue-500 hover:text-white'
          : 'bg-gray-300 text-gray-700 hover:bg-blue-500 hover:text-white'
      }`}
      onClick={() => handlePaymentMethodChange(method)}
      aria-pressed={paymentMethod === method}
    >
      {label}
    </button>
  );

  return (
    <div
      className={`max-w-lg mx-auto mt-10 p-8 ${
        isDarkMode ? 'bg-gray-800' : 'bg-white'
      } shadow-xl rounded-lg transition duration-500 ease-in-out transform hover:shadow-2xl`}
    >
      <h2
        className={`text-3xl font-bold mb-6 text-center ${
          isDarkMode ? 'text-gray-100' : 'text-gray-800'
        }`}
      >
        Select Payment Method
      </h2>

      {/* Payment Details Form */}
      <div className="mb-6">
        {/* Amount Input */}
        <label
          htmlFor="amount"
          className={`block text-sm font-medium mb-2 ${
            isDarkMode ? 'text-gray-200' : 'text-gray-700'
          }`}
        >
          Amount
        </label>
        <input
          type="number"
          id="amount"
          value={amount}
          onChange={(e) => setAmount(Number(e.target.value))}
          min="1"
          className={`w-full p-2 border rounded-md focus:outline-none focus:ring-2 ${
            isDarkMode
              ? 'bg-gray-700 border-gray-600 text-gray-200 focus:ring-blue-500'
              : 'bg-white border-gray-300 text-gray-700 focus:ring-blue-500'
          }`}
        />
        {errors.amount && (
          <p className="text-red-500 text-sm mt-1">{errors.amount}</p>
        )}

        {/* Currency Select */}
        <label
          htmlFor="currency"
          className={`block text-sm font-medium mb-2 mt-4 ${
            isDarkMode ? 'text-gray-200' : 'text-gray-700'
          }`}
        >
          Currency
        </label>
        <select
          id="currency"
          value={currency}
          onChange={(e) => setCurrency(e.target.value)}
          className={`w-full p-2 border rounded-md focus:outline-none focus:ring-2 ${
            isDarkMode
              ? 'bg-gray-700 border-gray-600 text-gray-200 focus:ring-blue-500'
              : 'bg-white border-gray-300 text-gray-700 focus:ring-blue-500'
          }`}
        >
          {supportedCurrencies.map((curr) => (
            <option key={curr} value={curr}>
              {curr}
            </option>
          ))}
        </select>
        {errors.currency && (
          <p className="text-red-500 text-sm mt-1">{errors.currency}</p>
        )}
      </div>

      {/* Payment Method Selection */}
      <div className="flex justify-center mb-8 space-x-4">
        <PaymentButton method="stripe" label="Credit/Debit Card" />
        <PaymentButton method="crypto" label="Cryptocurrency" />
      </div>

      {/* Payment Form Display */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="transition duration-300 ease-in-out"
      >
        {paymentMethod === 'stripe' ? (
          <StripePaymentForm amount={amount} currency={currency} />
        ) : (
          <CryptoPaymentForm amount={amount} currency={currency} />
        )}
      </motion.div>
    </div>
  );
};

export default PaymentForm;
