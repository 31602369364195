// src/components/VoiceSelection/VoiceSelection.tsx

import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Pagination, CircularProgress, Box } from '@mui/material';
import { fetchVoicesFromDB } from '@/api/voice';
import { DBVoice, Voice } from '@/types/VoiceTypes';

interface VoiceSelectionProps {
  userId: string;
  onVoiceSelect: (voiceId: string) => void; // Callback to handle voice selection
  selectedVoice: string;
}

const VoiceSelection: React.FC<VoiceSelectionProps> = ({ userId, onVoiceSelect, selectedVoice }) => {
  const [voices, setVoices] = useState<Voice[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const voicesPerPage = 10;

  useEffect(() => {
    const fetchVoices = async () => {
      setLoading(true);
      try {
        const voicesData: DBVoice[] = await fetchVoicesFromDB();
        // Transform DBVoice[] to Voice[]
        const transformedVoices: Voice[] = voicesData.map((dbVoice) => ({
          voice_name: dbVoice.name,
          voice_id: dbVoice.id,
          type: dbVoice.gender,
          description: dbVoice.description || 'No description available',
        }));
        setVoices(transformedVoices);
        setError(null);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError('An unknown error occurred');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchVoices();
  }, [userId]);

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  // Calculate the voices to display on the current page
  const startIndex = (page - 1) * voicesPerPage;
  const currentVoices = voices.slice(startIndex, startIndex + voicesPerPage);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
        {error && (
          <Typography className="text-red-500" variant="body1">
            {error}
          </Typography>
        )}
        {currentVoices.map((voice) => (
          <Card
            key={voice.voice_id}
            className="shadow-lg rounded-lg"
            variant={selectedVoice === voice.voice_id ? 'outlined' : 'elevation'}
            onClick={() => onVoiceSelect(voice.voice_id)}
            sx={{
              cursor: 'pointer',
              borderColor: selectedVoice === voice.voice_id ? 'primary.main' : 'transparent',
              '&:hover': {
                boxShadow: 6,
              },
            }}
          >
            <CardContent className="p-4">
              <Typography variant="h6" className="font-semibold">
                {voice.voice_name}
              </Typography>
              <Typography variant="subtitle2" className="italic text-gray-500">
                {voice.type.charAt(0).toUpperCase() + voice.type.slice(1)}
              </Typography>
              <Typography variant="body2" className="mt-2 text-gray-600">
                {voice.description}
              </Typography>
              <audio controls className="mt-4 w-full">
                <source src={`/assets/voice-samples/${voice.voice_id}.mp3`} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </CardContent>
          </Card>
        ))}
      </div>
      <div className="flex justify-center mt-4">
        <Pagination
          count={Math.ceil(voices.length / voicesPerPage)}
          page={page}
          onChange={handleChangePage}
          color="primary"
        />
      </div>
    </div>
  );
};

export default VoiceSelection;
