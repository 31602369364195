// src/services/aiRecommendationService.ts

import axios from 'axios';
import QuickLRU from 'quick-lru';

interface SkillRecommendation {
  skill: string;
  category: string;
  scenario: string;
}

const cache = new QuickLRU<string, SkillRecommendation[]>({ maxSize: 100 });

export const getSkillRecommendations = async (
  agentType: string,
  businessContext: string
): Promise<SkillRecommendation[]> => {
  const cacheKey = `${agentType}-${businessContext}`;
  const cachedRecommendations = cache.get(cacheKey);

  if (cachedRecommendations) {
    return cachedRecommendations;
  }

  try {
    const apiKey = import.meta.env.VITE_OPENAI_API_KEY;
    if (!apiKey) {
      throw new Error('OpenAI API key is not set');
    }

    console.log('Using OpenAI API key:', apiKey);

    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4',
        messages: [
          {
            role: 'user',
            content: `Please provide a JSON array containing up to 5 skill recommendations for an AI Voice agent working in ${businessContext} as a ${agentType}. Each object in the array should have three properties: "skill" (string), "category" (string from predefined categories), and "scenario" (string). The response should be a valid JSON array with no additional text or formatting.`,
          },
        ],
        max_tokens: 250,
        temperature: 0,
      },
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
      }
    );

    const messageContent = response.data.choices[0].message.content.trim();

    console.log('Raw response content:', messageContent);

    // Extract JSON array from the response
    const jsonStart = messageContent.indexOf('[');
    const jsonEnd = messageContent.lastIndexOf(']') + 1;
    if (jsonStart !== -1 && jsonEnd !== -1) {
      const jsonString = messageContent.substring(jsonStart, jsonEnd);
      const skills: SkillRecommendation[] = JSON.parse(jsonString);
      cache.set(cacheKey, skills);
      return skills;
    } else {
      throw new Error('Invalid response format or incomplete response');
    }
  } catch (error) {
    console.error('Error fetching skill recommendations:', error);
    return [];
  }
};
