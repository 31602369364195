// src/components/agentprofile/MiniSkillBuilder.tsx

import React, { useState, useEffect } from 'react';
import { Typography, Chip, TextField, Autocomplete, Box } from '@mui/material';
import { getSkillRecommendations } from '../../services/aiRecommendationService';
import { v4 as uuidv4 } from 'uuid';
import { Skill, SkillRecommendation } from '../../types/AgentTypes';

interface MiniSkillBuilderProps {
  assistantId: string;
  skills: Skill[];
  onChange: (skills: Skill[]) => void;
}

const MiniSkillBuilder: React.FC<MiniSkillBuilderProps> = ({
  assistantId,
  skills,
  onChange,
}) => {
  const [skillList, setSkillList] = useState<Skill[]>(skills || []);
  const [recommendations, setRecommendations] = useState<Skill[]>([]);
  const [newSkill, setNewSkill] = useState<Skill | null>(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setSkillList(skills || []);
  }, [skills]);

  useEffect(() => {
    const fetchRecommendations = async () => {
      try {
        const { agentType, businessContext } = await deriveAgentDetails(assistantId);

        const recs: SkillRecommendation[] = await getSkillRecommendations(agentType, businessContext);
        
        const skillRecs: Skill[] = recs.map((rec, index) => ({
          id: `rec-${index}-${uuidv4()}`, // Generate a unique id
          name: rec.skill, // Map 'skill' to 'name'
          category: rec.category,
          scenario: rec.scenario,
        }));
        setRecommendations(skillRecs);
      } catch (error) {
        console.error('Failed to fetch recommendations:', error);
        setRecommendations([]);
      }
    };
    fetchRecommendations();
  }, [assistantId]);

  /**
   * Derives agentType and businessContext based on assistantId.
   * Replace this mock implementation with actual logic as needed.
   */
  const deriveAgentDetails = async (assistantId: string): Promise<{ agentType: string; businessContext: string }> => {
    // Use the variable to avoid ESLint error
    void assistantId;

    // Mock implementation: Replace with actual API calls or logic
    return {
      agentType: 'Support Agent', // Example placeholder
      businessContext: 'Automotive', // Example placeholder
    };
  };

  const handleAddSkill = (skillToAdd: Skill) => {
    if (skillList.length >= 6) {
      setErrorMessage('You can only add up to 6 skills.');
      return;
    }
    // Check for duplicate skill names
    if (skillList.some(skill => skill.name === skillToAdd.name)) {
      setErrorMessage('This skill has already been added.');
      return;
    }
    const updatedSkills = [...skillList, { ...skillToAdd, id: uuidv4() }];
    setSkillList(updatedSkills);
    onChange(updatedSkills);
    setNewSkill(null);
    setErrorMessage('');
  };

  const handleDeleteSkill = (skillId: string) => {
    const updatedSkills = skillList.filter((skill) => skill.id !== skillId);
    setSkillList(updatedSkills);
    onChange(updatedSkills);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Skills
      </Typography>
      
      {/* Display the error message if there is one */}
      {errorMessage && (
        <Typography variant="body2" color="error">
          {errorMessage}
        </Typography>
      )}

      <Autocomplete
        value={newSkill}
        onChange={(_, value) => {
          if (value && typeof value !== 'string') {
            handleAddSkill(value);
          }
        }}
        options={recommendations}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField {...params} label="Add a skill" variant="outlined" />
        )}
        // Optionally, you can add filtering to exclude already added skills
        filterOptions={(options) =>
          options.filter(option => !skillList.some(skill => skill.name === option.name))
        }
      />
      
      <Box mt={2}>
        {skillList.map((skill) => (
          <Chip
            key={skill.id}
            label={skill.name}
            onDelete={() => handleDeleteSkill(skill.id)}
            sx={{ m: 0.5 }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default MiniSkillBuilder;