// src/components/agent_builder/ClientSelection.tsx

import React, { useState, useEffect, useCallback } from 'react';
import {
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  Box,
  Grid,
  CircularProgress,
} from '@mui/material';
import { ClientData, AgentTemplate } from '@/types/AgentTypes';
import { createClient } from '@/api/client';
import TemplateSelection from './TemplateSelection';

interface ClientSelectionProps {
  onSelect: (client: ClientData, template?: AgentTemplate) => void;
  onSearch: (query: string) => Promise<ClientData[]>;
}

const ClientSelection: React.FC<ClientSelectionProps> = ({ onSelect, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<ClientData[]>([]);
  const [newClient, setNewClient] = useState<ClientData>({
    id: null,
    email: '',
    dealershipName: '',
    dealershipWebsiteUrl: '',
    address: '',
    dealershipId: null,
    firstName: '',
    lastName: '',
    country: '',
    state: '',
    zip: '',
    organizationId: null,
  });
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<AgentTemplate | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSearch = useCallback(
    async (query: string) => {
      setLoading(true);
      setError(null);
      try {
        const results = await onSearch(query.trim());
        setSearchResults(results);
      } catch (err) {
        console.error('Error searching clients:', err);
        setError('Failed to search clients. Please try again.');
      } finally {
        setLoading(false);
      }
    },
    [onSearch]
  );

  useEffect(() => {
    if (searchTerm.trim().length > 2) {
      const delayDebounceFn = setTimeout(() => {
        handleSearch(searchTerm);
      }, 300);

      return () => clearTimeout(delayDebounceFn);
    } else {
      setSearchResults([]);
    }
  }, [searchTerm, handleSearch]);

  const handleClientSelect = (client: ClientData) => {
    onSelect(client, selectedTemplate);
  };

  const handleNewClientChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewClient((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreateClient = async () => {
    try {
      const createdClient = await createClient(newClient);
      onSelect(createdClient, selectedTemplate);
    } catch (err) {
      console.error('Error creating client:', err);
      setError('Failed to create client. Please check the input and try again.');
    }
  };

  const handleTemplateSelect = (template: AgentTemplate) => {
    setSelectedTemplate(template);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Select or Create a Client
      </Typography>
      <TextField
        fullWidth
        label="Search Clients"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 2 }}
        disabled={loading}
      />
      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <CircularProgress size={24} />
        </Box>
      )}
      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}
      {searchResults.length > 0 && (
        <Paper elevation={3} sx={{ mb: 2, maxHeight: 200, overflow: 'auto' }}>
          <List>
            {searchResults.map((client) => (
              <ListItem button key={client.id ?? client.email} onClick={() => handleClientSelect(client)}>
                <ListItemText primary={client.dealershipName} secondary={client.email} />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
      <Button
        variant="outlined"
        onClick={() => setIsCreatingNew(!isCreatingNew)}
        sx={{ mb: 2 }}
      >
        {isCreatingNew ? 'Cancel' : 'Create New Client'}
      </Button>
      {isCreatingNew && (
        <Box component={Paper} elevation={3} sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            New Client Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                value={newClient.firstName}
                onChange={handleNewClientChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                value={newClient.lastName}
                onChange={handleNewClientChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={newClient.email}
                onChange={handleNewClientChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Dealership Name"
                name="dealershipName"
                value={newClient.dealershipName}
                onChange={handleNewClientChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Dealership Website URL"
                name="dealershipWebsiteUrl"
                value={newClient.dealershipWebsiteUrl}
                onChange={handleNewClientChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address"
                name="address"
                value={newClient.address}
                onChange={handleNewClientChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Country"
                name="country"
                value={newClient.country}
                onChange={handleNewClientChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="State"
                name="state"
                value={newClient.state}
                onChange={handleNewClientChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="ZIP"
                name="zip"
                value={newClient.zip}
                onChange={handleNewClientChange}
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            onClick={handleCreateClient}
            disabled={!newClient.email || !newClient.dealershipName}
            sx={{ mt: 2 }}
          >
            Create Client
          </Button>
        </Box>
      )}
      <TemplateSelection onSelect={handleTemplateSelect} />
    </Box>
  );
};

export default ClientSelection;