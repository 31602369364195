// src/components/FileList.tsx

import React, { useEffect, useState, useCallback } from 'react';
import { fetchUserFiles, uploadFile, deleteFile } from '@/api/file';
import { fetchAssistantName } from '@/api/assistant';
import { useTheme } from './ThemeContext';
import { FileDetails } from '@/types/AgentTypes';

const FileList: React.FC = () => {
  const { isDarkMode } = useTheme();
  const [files, setFiles] = useState<FileDetails[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [assistantName, setAssistantName] = useState<string>('');

  // useCallback ensures that loadFiles has a stable reference,
  // preventing unnecessary re-renders and satisfying ESLint dependencies
  const loadFiles = useCallback(async () => {
    try {
      setLoading(true);
      const fileList = await fetchUserFiles();
      setFiles(fileList);

      if (fileList.length > 0) {
        const assistantId = fileList[0].assistantId;
        await loadAssistantName(assistantId);
      }
    } catch (err) {
      console.error('Error loading files:', err);
      setError('Failed to load files. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  const loadAssistantName = useCallback(async (assistantId: string) => {
    try {
      const name = await fetchAssistantName(assistantId);
      setAssistantName(name);
    } catch (err) {
      console.error('Failed to load assistant name:', err);
      setAssistantName('Assistant'); // Fallback name
    }
  }, []);

  useEffect(() => {
    loadFiles();
  }, [loadFiles]);

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      try {
        const file = e.target.files[0];
        await uploadFile(file);
        await loadFiles(); // Refresh the file list after upload
      } catch (err) {
        console.error('Error uploading file:', err);
        setError('Failed to upload file. Please try again.');
      }
    }
  };

  const handleFileDelete = async (fileId: string) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this file?');
    if (!confirmDelete) return;

    try {
      await deleteFile(fileId);
      await loadFiles(); // Refresh the file list after deletion
    } catch (err) {
      console.error('Error deleting file:', err);
      setError('Failed to delete file. Please try again.');
    }
  };

  const themeClasses = {
    container: isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-800',
    card: isDarkMode ? 'bg-gray-800' : 'bg-white',
    fileCard: isDarkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-50 hover:bg-gray-100',
    link: isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-800',
    button: isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600',
    deleteButton: isDarkMode ? 'text-red-400 hover:text-red-300' : 'text-red-500 hover:text-red-700',
    errorText: isDarkMode ? 'text-red-400' : 'text-red-500',
  };

  if (loading) {
    return (
      <div className={`flex justify-center items-center h-screen ${themeClasses.container}`}>
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`text-center py-4 ${themeClasses.errorText}`}>
        {error}
      </div>
    );
  }

  return (
    <div className={`max-w-6xl mx-auto p-6 ${themeClasses.container} min-h-screen`}>
      <div className={`${themeClasses.card} shadow-md rounded-lg p-6 mb-6`}>
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold">
            Files for {assistantName || 'Assistant'}
          </h1>
          <label
            htmlFor="file-upload"
            className={`${themeClasses.button} text-white font-semibold py-2 px-4 rounded-full cursor-pointer transition transform hover:scale-105 duration-300 ease-in-out`}
          >
            Upload File
            <input
              id="file-upload"
              type="file"
              className="hidden"
              onChange={handleFileUpload}
              accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.txt" // Specify acceptable file types
            />
          </label>
        </div>
        {files.length === 0 ? (
          <p className="text-center py-8">No files uploaded yet.</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {files.map((file) => (
              <div
                key={file.id}
                className={`${themeClasses.fileCard} rounded-lg p-4 shadow-sm transition duration-300`}
              >
                <div className="flex justify-between items-start mb-2">
                  <a
                    href={file.url} // Using 'url' as per updated FileDetails
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${themeClasses.link} font-semibold truncate max-w-[80%]`}
                  >
                    {file.name}
                  </a>
                  <button
                    onClick={() => handleFileDelete(file.id)}
                    className={`${themeClasses.deleteButton} transition duration-300`}
                    aria-label={`Delete ${file.name}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <p className="text-sm opacity-75">
                  Uploaded: {new Date(file.createdAt).toLocaleDateString()}
                </p>
                <p className="text-sm opacity-75">Type: {file.mimetype}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default FileList;