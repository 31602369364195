// src/api/auth.ts

import api from './api';
import { handleError } from '../utils/errors';
import type {
  AuthStatusResponse,
  Setup2FAResponse,
  FullAuthResponse,
  AuthResponse,
  RegisterResponse,
  ConnectAccountResponse,
  TwoFactorStatusResponse,
} from '@/types/AgentTypes';
import { setToken, setRefreshToken } from '@/utils/jwtUtils';

// Type Guard to check if AuthResponse is FullAuthResponse
const isFullAuthResponse = (response: AuthResponse): response is FullAuthResponse => {
  return (response as FullAuthResponse).token !== undefined;
};

// Register a new user
export const registerUser = async (userData: Record<string, unknown>): Promise<RegisterResponse> => {
  try {
    const response = await api.post<RegisterResponse>('/api/auth/register', userData);
    return response.data;
  } catch (error: unknown) {
    handleError(error, 'Failed to register user');
    throw error;
  }
};

// Login a user
export const loginUser = async (userData: {
  email: string;
  password: string;
  rememberMe?: boolean;
}): Promise<AuthResponse> => {
  try {
    const response = await api.post<AuthResponse>('/api/auth/login', userData);
    const data = response.data;

    if (isFullAuthResponse(data)) {
      // Store tokens using the jwtUtils functions
      setToken(data.token);
      if ('refreshToken' in data && data.refreshToken) {
        setRefreshToken(data.refreshToken);
      }
      return data;
    }

    // It's a TwoFactorAuthResponse
    return data;
  } catch (error: unknown) {
    handleError(error, 'Failed to login user');
    throw error;
  }
};

// Refresh Token Function
export const refreshToken = async (refreshToken: string): Promise<FullAuthResponse> => {
  try {
    const response = await api.post<FullAuthResponse>('/api/auth/refresh-token', { refreshToken });
    const data = response.data;

    // Update tokens
    if (data.token) {
      setToken(data.token);
    }
    if (data.refreshToken) {
      setRefreshToken(data.refreshToken);
    }

    return data;
  } catch (error) {
    handleError(error, 'Failed to refresh token');
    throw error;
  }
};

// Logout user
export const logoutUser = async (): Promise<{ message: string }> => {
  try {
    const response = await api.post<{ message: string }>('/api/auth/logout');
    // Tokens should be removed in AuthContext or jwtUtils
    return response.data;
  } catch (error: unknown) {
    handleError(error, 'Failed to logout user');
    throw error;
  }
};

// Check authentication status
export const checkAuthStatus = async (): Promise<AuthStatusResponse> => {
  try {
    const response = await api.get<AuthStatusResponse>('/api/auth/status');
    return response.data;
  } catch (error: unknown) {
    console.error('Failed to check auth status:', error);
    return { isAuthenticated: false };
  }
};

// Setup Two-Factor Authentication
export const setup2FA = async (): Promise<Setup2FAResponse> => {
  try {
    const response = await api.post<Setup2FAResponse>('/api/auth/setup-2fa');
    return response.data;
  } catch (error: unknown) {
    handleError(error, 'Failed to set up 2FA');
    throw error;
  }
};

// Get Two-Factor Authentication status
export const getTwoFactorStatus = async (): Promise<TwoFactorStatusResponse> => {
  try {
    const response = await api.get<TwoFactorStatusResponse>('/api/auth/2fa-status');
    return response.data;
  } catch (error: unknown) {
    handleError(error, 'Failed to get 2FA status');
    throw error;
  }
};

// Disable Two-Factor Authentication
export const disable2FA = async (): Promise<{ message: string }> => {
  try {
    const response = await api.post<{ message: string }>('/api/auth/disable-2fa');
    return response.data;
  } catch (error: unknown) {
    handleError(error, 'Failed to disable 2FA');
    throw error;
  }
};

// Verify Two-Factor Authentication code
export const verify2FA = async (
  twoFactorCode: string,
  rememberMe?: boolean
): Promise<FullAuthResponse> => {
  try {
    const response = await api.post<FullAuthResponse>('/api/auth/verify-2fa', {
      twoFactorCode,
      rememberMe,
    });
    const data = response.data;

    // Update tokens
    if (data.token) {
      setToken(data.token);
    }
    if (data.refreshToken) {
      setRefreshToken(data.refreshToken);
    }

    return data;
  } catch (error: unknown) {
    handleError(error, 'Failed to verify 2FA token');
    throw error;
  }
};

// Microsoft authentication
export const microsoftLogin = async (accessToken: string): Promise<AuthResponse> => {
  try {
    const response = await api.post<AuthResponse>('/api/auth/microsoft', { accessToken });
    const data = response.data;

    if (isFullAuthResponse(data)) {
      // Update tokens
      setToken(data.token);
      if ('refreshToken' in data && data.refreshToken) {
        setRefreshToken(data.refreshToken);
      }
      return data;
    }

    // Handle other types of responses if necessary
    return data;
  } catch (error: unknown) {
    handleError(error, 'Microsoft authentication error');
    throw error;
  }
};

// Connect Microsoft account
export const connectMicrosoftAccount = async (accessToken: string): Promise<ConnectAccountResponse> => {
  try {
    const response = await api.post<ConnectAccountResponse>('/api/auth/connect-microsoft', { accessToken });
    return response.data;
  } catch (error: unknown) {
    handleError(error, 'Microsoft account connection error');
    throw error;
  }
};

// Check Microsoft connection status
export const checkMicrosoftConnectionStatus = async (): Promise<boolean> => {
  try {
    const response = await api.get<{ isConnected: boolean }>('/api/auth/microsoft-connection-status');
    return response.data.isConnected;
  } catch (error: unknown) {
    handleError(error, 'Failed to check Microsoft connection status');
    return false;
  }
};

// Google authentication
export const googleLogin = async (token: string): Promise<AuthResponse> => {
  try {
    const response = await api.post<AuthResponse>('/api/auth/google', { token });
    const data = response.data;

    if (isFullAuthResponse(data)) {
      // Update tokens
      setToken(data.token);
      if ('refreshToken' in data && data.refreshToken) {
        setRefreshToken(data.refreshToken);
      }
      return data;
    }

    // Handle other types of responses if necessary
    return data;
  } catch (error: unknown) {
    handleError(error, 'Google authentication error');
    throw error;
  }
};

// Connect Google account
export const connectGoogleAccount = async (credential: string): Promise<ConnectAccountResponse> => {
  try {
    const response = await api.post<ConnectAccountResponse>('/api/auth/connect-google', { credential });
    return response.data;
  } catch (error: unknown) {
    handleError(error, 'Google account connection error');
    throw error;
  }
};

// Check Google connection status
export const checkGoogleConnectionStatus = async (): Promise<boolean> => {
  try {
    const response = await api.get<{ isConnected: boolean }>('/api/auth/google-connection-status');
    return response.data.isConnected;
  } catch (error: unknown) {
    handleError(error, 'Failed to check Google connection status');
    return false;
  }
};

// Check admin status
export const checkAdminStatus = async (): Promise<boolean> => {
  try {
    const response = await api.get<{ isAdmin: boolean }>('/api/auth/admin-status');
    return response.data.isAdmin;
  } catch (error: unknown) {
    handleError(error, 'Failed to check admin status');
    return false;
  }
};